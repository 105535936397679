import React from 'react'

const numberWithCommas = x => {
  let parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export default props => {
  let { points, description, color } = props
  points = points ? points : 0
  // formatterer points med tegnsætning, så 1234567890 bliver til 1.234.567.890 (komma decimaler er ikke understøttet p.t.)
  const formattedPoints = numberWithCommas(points) //`${points}`.split('').reverse().join('').match(/.{1,3}/g).join('.').split('').reverse().join('')
  return (
    <React.Fragment>
      <div
        style={{
          padding: '10px',
          height: '35px',
          width: '35px',
          borderRadius: '50px',
          backgroundColor: `${color}`,
          float: 'left',
          marginRight: '15px'
        }}
      >
        <div
          style={{
            height: '15px',
            width: '15px',
            borderRadius: '50px',
            backgroundColor: 'white'
          }}
        >
          &nbsp;
        </div>
      </div>
      <div>
        <p>
          {formattedPoints} point
          <br />
          {description}
        </p>
      </div>
    </React.Fragment>
  )
}
