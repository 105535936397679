import React from 'react'
import Reflux from 'reflux'
import { Row, Col, Button, Form, FormGroup, Label, Input, CustomInput, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { LoginStore } from 'Stores/loginStore'
import { BrandSupportEmail } from 'Modules/SupportEmail.js'
import Proxies from 'Components/proxies.js'

export default class RegisterForm extends Reflux.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      mobilePhoneNumber: '',
      // company: '',
      companyAddress: '',
      companyPostalCode: '',
      companyCity: '',
      companyDepartment: '',
      termsAndConditionsOpen: false,
      termsAndConditionsAccepted: false,
      phoneCountryCode: '',
      validFields: {},
      showErrors: false,
      isFormValid: false,
      kams: [],
      userSupervisorUID: '',
      userSupervisor: '',
      departments: [],
      userUID: ''
    }
    this.stores = [LoginStore]
    this.storeKeys = ['brandData', 'settings']
  }

  componentDidMount() {
    // Proxies.getKamsInBrand({ brandSlug: this.state.settings.brandSlug }).then(response => {
    //   if (response && response.kams && response.kams.length) {
    //     this.setState({ kams: response.kams })
    //   }
    // })
    Proxies.getDepartmentsInBrand({ brandSlug: this.state.settings.brandSlug }).then(response => {
      if (response && response.length) {
        this.setState({ departments: response })
      }
    })
  }

  handleInputChange = e => {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    this.setState({ [e.target.name]: value }, () => {
      this.validateForm()
    })
  }

  handleKamChange = e => {
    const target = e.target
    let kam = this.state.kams.filter(k => k.userName === target.value)
    if (kam.length) {
      this.setState({ userSupervisor: kam[0].userName, userSupervisorUID: kam[0].userUID }, () => {
        this.validateForm()
      })
    } else {
      this.setState({ userSupervisorUID: '', userSupervisor: '' }, () => {
        this.validateForm()
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.isFormValid) {
      const {
        name,
        email,
        mobilePhoneNumber,
        // company,
        companyAddress,
        companyPostalCode,
        companyCity,
        companyDepartment,
        termsAndConditionsAccepted,
        phoneCountryCode,
        // userSupervisorUID,
        // userSupervisor,
        userUID
      } = this.state
      this.props.handleSubmit({
        userName: name,
        userEmail: email.trim(),
        userPhone: `${phoneCountryCode || '+45'}${mobilePhoneNumber}`.trim(),
        // userCompany: company,
        userAddress: companyAddress,
        userZipcode: companyPostalCode,
        userCity: companyCity,
        userDepartment: companyDepartment,
        termsAndConditionsAccepted,
        userUID
        // userSupervisorUID,
        // userSupervisor
      })
    } else {
      this.setState({ showErrors: true })
    }
  }

  handleTermsAndConditionsClick = () => {
    this.setState(state => ({
      termsAndConditionsOpen: !state.termsAndConditionsOpen
    }))
  }

  validateForm = () => {
    const {
      name,
      email,
      mobilePhoneNumber,
      // company,
      companyAddress,
      companyPostalCode,
      companyCity,
      companyDepartment,
      termsAndConditionsAccepted,
      phoneCountryCode,
      // userSupervisor,
      userUID
    } = this.state
    const userNameRegex = /^([^0-9]*)$/
    const phoneNumberRegex = /^([+]?)([0-9]*)$/
    const validFields = {
      name: !!name && userNameRegex.test(name),
      email: !!email,
      mobilePhoneNumber: !!mobilePhoneNumber && phoneNumberRegex.test(mobilePhoneNumber),
      phoneCountryCode: phoneCountryCode ? phoneNumberRegex.test(phoneCountryCode) : true, // empty is also valid will auto add +45 on submit
      // company: !!company,
      companyAddress: !!companyAddress,
      companyPostalCode: !!companyPostalCode,
      companyCity: !!companyCity,
      companyDepartment: !!companyDepartment,
      termsAndConditionsAccepted,
      // userSupervisor: !!userSupervisor,
      userUID: !!userUID
    }
    const isFormValid = Object.keys(validFields).reduce((accumulator, currentKey) => {
      return accumulator && validFields[currentKey]
    }, true)

    this.setState({
      validFields,
      isFormValid
    })
  }

  render() {
    return (
      <div className='register-form__content'>
        <Modal className='register-form__modal' isOpen={this.state.termsAndConditionsOpen} toggle={this.handleTermsAndConditionsClick}>
          <ModalHeader toggle={this.handleTermsAndConditionsClick}>
            {this.state.settings.features.registerUser &&
            this.state.settings.features.registerUser &&
            this.state.settings.features.registerUser.termsHeader
              ? this.state.settings.features.registerUser.termsHeader
              : 'Vilkår og betingelser'}
          </ModalHeader>
          {this.state.settings.features.registerUser &&
          this.state.settings.features.registerUser &&
          this.state.settings.features.registerUser.terms ? (
            <ModalBody>
              <h6
                className='mt-2 mb-2'
                dangerouslySetInnerHTML={{
                  __html: this.state.settings.features.registerUser.terms
                }}
              ></h6>
            </ModalBody>
          ) : (
            <ModalBody>
              <p>
                Jeg giver hermed samtykke til, at MS Branding A/S må behandle mine personoplysninger til brug for administration af min profil i
                kundeklubben.
              </p>
              <p>
                I det omfang jeg eksplicit har accepteret dette, er MS Branding A/S endvidere berettiget til at benytte mine oplysninger i forbindelse
                med fremsendelse af særlige tilbud og events, som vedrører kundeklubben.
              </p>
              <p>
                Du kan til enhver tid tilbagekalde dit samtykke ved at kontakte os på support på <BrandSupportEmail queryParams='?subject=GDPR' />{' '}
                eller på telefon {this.state.brandData && this.state.brandData.contactPhone ? this.state.brandData.contactPhone : '+45 3115 8411'}.
              </p>
              <p>Vælger du at tilbagekalde dit samtykke vil dette eventuelt kunne medføre, at du ikke længere kan anvende kundeklubben.</p>
            </ModalBody>
          )}
        </Modal>
        <h5 className='text-center'>{this.state.settings.features.registerUser && this.state.settings.features.registerUser.header}</h5>
        <div className='mx-auto mb-3 mt-4' style={{ width: '45px', height: '2px', backgroundColor: '#2e2e2e' }} />
        <h6
          className='mt-2 mb-2'
          dangerouslySetInnerHTML={{
            __html: this.state.settings.features.registerUser && this.state.settings.features.registerUser.description
          }}
        ></h6>
        <div className='mx-auto mb-3 mt-4' style={{ width: '45px', height: '2px', backgroundColor: '#2e2e2e' }} />
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Label for='register-form-name'>Navn:</Label>
            <Input
              type='text'
              name='name'
              id='register-form-name'
              placeholder='Navn'
              value={this.state.name}
              onChange={this.handleInputChange}
              invalid={this.state.showErrors && !this.state.validFields.name}
              data-cy='register.name'
            />
          </FormGroup>
          <FormGroup>
            <Label for='register-form-name'>Medarbejdernummer:</Label>
            <Input
              type='text'
              name='userUID'
              id='register-form-userUID'
              placeholder='Medarbejdernummer'
              value={this.state.userUID}
              onChange={this.handleInputChange}
              invalid={this.state.showErrors && !this.state.validFields.userUID}
              data-cy='register.userUID'
            />
          </FormGroup>
          {/* <FormGroup>
            <Label for='register-form-company'>Firmanavn:</Label>
            <Input
              type='text'
              name='company'
              id='register-form-company'
              placeholder='Firmanavn'
              value={this.state.company}
              onChange={this.handleInputChange}
              invalid={this.state.showErrors && !this.state.validFields.company}
              data-cy='register.company'
            />
          </FormGroup> */}
          <FormGroup>
            <Label for='register-form-email'>Email:</Label>
            <Input
              type='text'
              name='email'
              id='register-form-email'
              placeholder='Email'
              value={this.state.email}
              onChange={this.handleInputChange}
              invalid={this.state.showErrors && !this.state.validFields.email}
              data-cy='register.email'
            />
          </FormGroup>
          <Row form>
            <Col xs='12'>
              <Label for='register-form-mobilePhoneNumber'>Mobilnummer:</Label>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Input
                  type='text'
                  name='phoneCountryCode'
                  id='register-form-phoneCountryCode'
                  // placeholder='+45'
                  value={this.state.phoneCountryCode || '+45'}
                  disabled
                  onChange={this.handleInputChange}
                  invalid={this.state.showErrors && !this.state.validFields.phoneCountryCode}
                />
              </FormGroup>
            </Col>
            <Col md={10}>
              <FormGroup>
                <Input
                  type='text'
                  name='mobilePhoneNumber'
                  id='register-form-mobilePhoneNumber'
                  placeholder='Mobilnummer'
                  value={this.state.mobilePhoneNumber}
                  maxlength='8'
                  onChange={this.handleInputChange}
                  invalid={this.state.showErrors && !this.state.validFields.mobilePhoneNumber}
                  data-cy='register.mobilePhoneNumber'
                />
              </FormGroup>
            </Col>
          </Row>
          {/* <FormGroup>
            <Label for='register-form-kam'>KAM:</Label>
            <Input
              type='select'
              name='userSupervisor'
              id='userSupervisor'
              value={this.state.userSupervisor}
              onChange={this.handleKamChange}
              invalid={this.state.showErrors && !this.state.validFields.userSupervisor}
            >
              <option value={null}>Select KAM</option>
              {this.state.kams.length &&
                this.state.kams.map((kam, index) => {
                  return (
                    <option key={index} value={kam.userName}>
                      {kam.userName} ({kam.userUID})
                    </option>
                  )
                })}
            </Input>
          </FormGroup> */}
          {/* <FormGroup>
            <Label for='register-form-companyDepartment'>Afdeling:</Label>
            <Input
              type='text'
              name='companyDepartment'
              id='register-form-companyDepartment'
              placeholder='Afdeling'
              value={this.state.companyDepartment}
              onChange={this.handleInputChange}
              invalid={this.state.showErrors && !this.state.validFields.companyDepartment}
              data-cy='register.companyDepartment'
            />
          </FormGroup> */}
          <FormGroup>
            <Label for='register-form-companyDepartment'>Afdeling:</Label>
            <Input
              type='select'
              name='companyDepartment'
              id='register-form-companyDepartment'
              placeholder='Afdeling'
              value={this.state.companyDepartment}
              onChange={this.handleInputChange}
              invalid={this.state.showErrors && !this.state.validFields.companyDepartment}
              data-cy='register.companyDepartment'
            >
              <option value={null}>Vælg afdeling</option>
              {this.state.departments.length &&
                this.state.departments.map((department, index) => {
                  return (
                    <option key={index} value={department.departmentName}>
                      {department.departmentName}
                    </option>
                  )
                })}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for='register-form-companyAddress'>Adresse:</Label>
            <Input
              type='text'
              name='companyAddress'
              id='register-form-companyAddress'
              placeholder='Adresse'
              value={this.state.companyAddress}
              onChange={this.handleInputChange}
              invalid={this.state.showErrors && !this.state.validFields.companyAddress}
              data-cy='register.companyAddress'
            />
          </FormGroup>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label for='register-form-companyPostalCode'>Postnr:</Label>
                <Input
                  type='text'
                  name='companyPostalCode'
                  id='register-form-companyPostalCode'
                  placeholder='Postnr'
                  value={this.state.companyPostalCode}
                  onChange={this.handleInputChange}
                  invalid={this.state.showErrors && !this.state.validFields.companyPostalCode}
                  data-cy='register.companyPostalCode'
                />
              </FormGroup>
            </Col>
            <Col md={9}>
              <FormGroup>
                <Label for='register-form-companyCity'>By:</Label>
                <Input
                  type='text'
                  name='companyCity'
                  id='register-form-companyCity'
                  placeholder='By'
                  value={this.state.companyCity}
                  onChange={this.handleInputChange}
                  invalid={this.state.showErrors && !this.state.validFields.companyCity}
                  data-cy='register.companyCity'
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Row>
              <Col md={1}>
                <CustomInput
                  className='d-inline-block'
                  type='checkbox'
                  id='register-form-rules-checkbox'
                  name='termsAndConditionsAccepted'
                  checked={this.state.termsAndConditionsAccepted}
                  onChange={this.handleInputChange}
                  invalid={this.state.showErrors && !this.state.validFields.termsAndConditionsAccepted}
                />
              </Col>
              <Col md={11}>
                <Label for='register-form-rules-checkbox' data-cy='register.termsAndConditionsAccepted'>
                  {this.state.settings.features.registerUser &&
                  this.state.settings.features.registerUser &&
                  this.state.settings.features.registerUser.consent
                    ? this.state.settings.features.registerUser.consent
                    : `Jeg giver hermed samtykke til at Tryg må videregive mine oplysninger til brug i Tryg Pointshop (navn, adresse, medarbejdernummer,
                      telefonnummer, e-mail og afdeling) til brug for oprettelse, administration, tildeling af point samt eventuel overførsel af point fra
                      tidligere pointshop`}
                  {/* <button type='button' onClick={this.handleTermsAndConditionsClick} className='register-form__terms-and-conditions ml-1'>
                  vilkår og betingelser.
                </button> */}
                </Label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup row className='justify-content-center'>
            <Button className='standardBtn-black' style={{ maxWidth: 250, fontWeight: 'bold' }} data-cy='register.submit'>
              Indsend
            </Button>
          </FormGroup>
        </Form>
      </div>
    )
  }
}
