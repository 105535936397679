/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { Container, Row, Col } from 'reactstrap'
import { ProfileActions, ProfileStore } from 'Stores/profileStore'

export default class Team extends Component {
  constructor(props) {
    super(props)
    this.stores = [ProfileStore]
    this.storeKeys = ['teamMembers']
  }

  componentDidMount() {
    ProfileActions.getAllSubordinateUsersOfBrand()
  }

  render() {
    const { teamMembers } = this.state
    return (
      <React.Fragment>
        <Container className='container-safe-height'>
          <div>
            <Row className='pb-1 pt-4'>
              <Col xs='1'>
                <p className='font-weight-bold'>ID</p>
              </Col>
              <Col xs='2'>
                <p className='font-weight-bold'>UID</p>
              </Col>
              <Col xs='2'>
                <p className='font-weight-bold'>Navn</p>
              </Col>
              <Col xs='2'>
                <p className='font-weight-bold'>Mobil</p>
              </Col>
              <Col xs='3'>
                <p className='font-weight-bold'>Email</p>
              </Col>
              <Col xs='2'>
                <p className='font-weight-bold'>Point</p>
              </Col>
            </Row>
            {(teamMembers || []).map((user, idx) => (
              <Row className='wg-row' key={idx}>
                <Col xs='1'>{user.UserID || '-'}</Col>
                <Col xs='2'>{user.userUID || '-'}</Col>
                <Col xs='2'>{user.userName || '-'}</Col>
                <Col xs='2'>{user.userPhone || '-'}</Col>
                <Col xs='3'>{user.userEmail || '-'}</Col>
                <Col xs='2'>{user.points || '0'}</Col>
              </Row>
            ))}
          </div>
        </Container>
      </React.Fragment>
    )
  }
}
