import React from 'react'
import { PlainSupportEmail } from 'Modules/SupportEmail.js'

export default function UnknownClubError() {
  return (
    <div className='container-fluid container-padding pt-5'>
      <h5 className='pt-1'>Vi kunne ikke finde den klub du ledte efter!</h5>
      <h6 className='pt-1'>
        Har du oplevet denne fejl flere gange? Kontakt venligst vores <PlainSupportEmail className='link' />.
      </h6>
    </div>
  )
}
