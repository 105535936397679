/* eslint-disable eqeqeq */
import Reflux from 'reflux'
import Proxies from 'Components/proxies'
import _ from 'lodash'
import { ActivityActions } from './activityStore'
import { track } from 'Components/track.js'

const BasketActions = Reflux.createActions([
  'getBasket',
  'incrementBasketItemCount',
  'decrementBaksetItemCount',
  'removeFromBasket',
  'toggleModal',
  { placeOrder: { children: ['completed', 'rejected'] } },
  'deliveryAddressChanged',
  'setAcceptTerms',
  'toggleBasketModalOpen'
])

class BasketStore extends Reflux.Store {
  constructor() {
    super()
    this.listenables = BasketActions

    this.state = {
      basket: [],
      basketCount: 0,
      basketTotal: 0,
      unavailableProducts: [],
      deliveryAddress: {},
      confirmationId: '',
      submitErrorMessage: '',
      placingOrder: false,
      termsAccepted: false,
      showConfirmation: false,
      basketModalOpen: false,
      basketIsLoading: false
    }
  }

  getBasket() {
    this.setState({ basketIsLoading: true })
    Proxies.getBasket().then(responseJSON => {
      responseJSON = responseJSON.map(item => {
        if (item && item.variation) {
          if (item.variation && item.variation.attachments && item.variation.attachments.length) {
            let variationImage = item.product.attachments.filter(attachment => attachment.ImageID === item.variation.attachments[0])
            if (variationImage && variationImage.length && variationImage[0].ImageID === item.variation.attachments[0]) {
              item.variation.attachments = variationImage
            }
          }
        }
        return item
      })
      const newState = Object.assign(this.state, {
        basket: responseJSON,
        basketCount: _.reduce(responseJSON, (acc, val) => acc + val.amount, 0),
        basketTotal: responseJSON
          .map(x => x.amount * (x.product.enableDiscount ? x.product.discountPointComputed : x.product.productPointComputed))
          .reduce((sum, nextPrice) => sum + nextPrice, 0),
        unavailableProducts: responseJSON.filter(x => x.product.productStatus !== 'AVAILABLE'),
        basketIsLoading: false
      })
      this.setState(newState)
    })
  }

  incrementBasketItemCount(ShopID, ProductID, VariationID) {
    let data = {
      ShopID: parseInt(ShopID),
      ProductID: ProductID,
      VariationID: VariationID,
      amount: 1
    }

    if (this.state.basket) {
      let foundInBasket = this.state.basket.find(e => {
        return e.ShopID == ShopID && e.ProductID == ProductID && e.VariationID == VariationID
      })

      if (foundInBasket) {
        foundInBasket.amount = foundInBasket.amount + 1
        data = foundInBasket
      }
    }

    Proxies.addToBasket(data).then(() => {
      this.getBasket()
    })
  }

  decrementBaksetItemCount(ShopID, ProductID, VariationID) {
    let data = {
      ShopID: ShopID,
      ProductID: ProductID,
      VariationID: VariationID
    }

    if (this.state.basket) {
      let foundInBasket = this.state.basket.find(e => {
        return e.ShopID == ShopID && e.ProductID == ProductID && e.VariationID == VariationID
      })

      if (foundInBasket) {
        foundInBasket.amount = foundInBasket.amount - 1
        data = foundInBasket
      }
    }

    if (data.amount === 0) {
      this.removeFromBasket(ShopID, ProductID, VariationID)
    } else {
      Proxies.addToBasket(data).then(() => {
        this.getBasket()
      })
    }
  }

  removeFromBasket(ShopID, ProductID, VariationID) {
    if (this.state.basket) {
      let foundInBasket = this.state.basket.find(e => {
        return e.ShopID == ShopID && e.ProductID == ProductID && e.VariationID == VariationID
      })
      if (foundInBasket) {
        foundInBasket.removed = 1
        Proxies.removeFromBasket(foundInBasket).then(() => {
          this.getBasket()
        })
      }
    }
  }

  removeFromBasketSilent() {
    const { basket } = this.state
    if (!basket) return
    basket.map(x => BasketActions.removeFromBasket(x.ShopID, x.ProductID, x.VariationID))
  }

  placeOrder() {
    this.setState({ placingOrder: true })
    const { basket, basketTotal } = this.state
    const { Dealer_Navn, Dealer_adresse, Dealer_zip, Dealer_by, AddressID } = this.state.deliveryAddress
    let data = Object.assign(
      {},
      {
        basket,
        Dealer_Navn: Dealer_Navn ? Dealer_Navn : ' ',
        Dealer_adresse: Dealer_adresse ? Dealer_adresse : ' ',
        Dealer_zip: Dealer_zip ? Dealer_zip : ' ',
        Dealer_by: Dealer_by ? Dealer_by : ' ',
        ...(AddressID && { AddressID })
      }
    )

    Proxies.placeOrder(data).then(response => {
      if (response.ConfirmationID) {
        this.removeFromBasketSilent()
        ActivityActions.logActivity('Shop', 'Purchase', basketTotal)
        let confirmationId = '#' + response.BrandID + '-' + response.ConfirmationID
        BasketActions.placeOrder.completed(confirmationId)
        track('Conversion', 'Order', 'Completed', basketTotal)
        return this.setState({ confirmationId, placingOrder: false })
      }
      BasketActions.placeOrder.rejected(response.message)
      track('Conversion', 'Order', 'Rejected', basketTotal)
      return this.setState({
        submitErrorMessage: response.error ? response.error.message : response.message,
        placingOrder: false,
        confirmationId: ''
      })
    })
  }

  onPlaceOrderCompleted() {
    this.setState({ showConfirmation: true })
  }

  onPlaceOrderRejected() {
    this.setState({ showConfirmation: true })
  }

  onDeliveryAddressChanged(deliveryAddress) {
    this.setState({ deliveryAddress })
  }

  setAcceptTerms(isAccepted) {
    this.setState({ termsAccepted: isAccepted })
  }

  toggleBasketModalOpen(newAddress) {
    BasketActions.setAcceptTerms(false)
    BasketActions.deliveryAddressChanged(newAddress)
    this.setState({
      basketModalOpen: !this.state.basketModalOpen,
      showConfirmation: false
    })
  }
}

export { BasketActions, BasketStore }
