import { useSelector, useDispatch } from 'react-redux'
import { TrackableFunction } from 'Components/trackableComponents'
import { selectBrands, addBrand, removeBrand, getBrandFilter } from 'Reducers/productsSlice.js'

export default function useBrandFilters() {
  const brands = useSelector(selectBrands)
  const selectedBrands = useSelector(getBrandFilter)
  const dispatch = useDispatch()

  const handleBrandChange = brand => {
    if (selectedBrands.includes(brand)) {
      dispatch(removeBrand(brand))
    } else {
      dispatch(addBrand(brand))
    }

    TrackableFunction({
      activityType: 'filter',
      activityName: 'shop',
      activityArgument: selectedBrands.join('|')
    })
  }

  return [brands, selectedBrands, handleBrandChange]
}
