/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import moment from 'moment'
import 'moment/locale/da'
import DatePicker from 'react-datepicker'
import objectFitImages from 'object-fit-images'
import nl2br from '../../components/nl2br'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Input, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Comments from './comments'
import { CompetitionActions, CompetitionStore } from 'Stores/competitionStore'
import { ProfileActions, ProfileStore } from 'Stores/profileStore'
import { LoginStore } from 'Stores/loginStore'
import Moment from 'react-moment'
import SectionMenu from 'Modules/layout/SectionMenu.js'
import { HofActions, HofStore } from 'Stores/hofStore'
import { isFunction } from 'util'

class Competition extends Component {
  constructor(props) {
    super(props)
    this.stores = [CompetitionStore, HofStore, LoginStore, ProfileStore]
    this.storeKeys = [
      'hofs',
      'userPointsComputed',
      'adversaries',
      'challenge',
      'competitionData',
      'competitionProcess',
      'competitions',
      'currentCompetition',
      'currentCompetitionID',
      'dirtyAdversaries',
      'dirtyCompetition',
      'isAwaitingResponse',
      'isEditMode',
      'settings',
      'wagerPool'
    ]

    this.state = {
      isAwaitingResponse: false,
      isEditMode: false
    }

    // allows the component to get notified whenever the event has happened, not just reacting to the change of state
    // 'preEmit' is a built-in hook emitted before start of the method function call
    // 'completed' and 'rejected' are custom events which requires the target function to call them - think predefined callbacks
    // see the CompetitionActions definitions for inspiration on how to create custom hooks
    CompetitionActions.saveCompetition.preEmit = () => this.setState({ isAwaitingResponse: true })
    CompetitionActions.updateCompetition.preEmit = () => this.setState({ isAwaitingResponse: true })
    CompetitionActions.deleteCompetition.preEmit = () => this.setState({ isAwaitingResponse: true })
    CompetitionActions.saveCompetition.completed.listen(response => this.navigateToViewCompetition(response))
    CompetitionActions.saveCompetition.rejected.listen(response => this.handleCompetitionRejected(response))
    CompetitionActions.deleteCompetition.completed.listen(() => this.navigateToCompetitionIndex())
    CompetitionActions.updateCompetition.completed.listen(response => this.navigateToViewCompetition(response))

    CompetitionActions.getCompetitions.completed.listen(competitions => {
      if (!this.props.match.params.CompetitionID) this.props.history.push(`/competition/${competitions[0].CompetitionID}`)
    })
  }

  componentDidMount() {
    CompetitionActions.getCompetitions()
    HofActions.getHofs()
    this.setCurrentCompetitionId()
    ProfileActions.getWalletTransactions()
    objectFitImages()
  }

  componentDidUpdate() {
    this.setCurrentCompetitionId()
  }

  setCurrentCompetitionId = () => {
    const competitionID = this.props.match.params.CompetitionID || null

    if (competitionID !== this.state.currentCompetitionID) CompetitionActions.competitionIdChanged(competitionID)
  }

  navigateToViewCompetition = CompetitionID => this.closeModal(() => this.props.history.push(`/competition/${CompetitionID}`))
  handleCompetitionRejected = rejectionResponse => this.setState({ rejectionResponse })
  navigateToCompetitionIndex = () => this.closeModal(() => this.props.history.push(`/competition/`))

  closeModal = (next = () => {}) => {
    this.setState({
      showCreateModal: false,
      isAwaitingResponse: false
    })
    isFunction(next) && next()
  }

  isOwnCompetition = () => {
    let rVal = false
    if (this.state.currentCompetitionID) {
      if (this.state.settings.UserID == this.state.currentCompetition.UserID) {
        rVal = true
      }
    }
    return rVal
  }

  canEdit = status => status && status == 'READY'

  getLinks = () => {
    const hofs = (this.state.hofs || []).map(e => ({
      name: e.moduleName,
      url: `/halloffame/${e.HalloffameID}`,
      moduleId: e.HalloffameID,
      sectionName: 'halloffame'
    }))
    const comps = (this.state.competitions || []).map(e => ({
      name: e.moduleName,
      url: `/competition/${e.CompetitionID}`,
      moduleId: e.CompetitionID,
      sectionName: 'competition'
    }))
    return hofs.concat(comps)
  }

  render() {
    const { currentCompetitionID, competitionProcess, dirtyCompetition, currentCompetition, challenge, wagerPool, userPointsComputed } = this.state
    const isCvrCompetition = dirtyCompetition.type.toLowerCase() === 'cvr'
    const currentChallenge = (challenge || []).find(x => x.CompetitionID == currentCompetitionID)
    if (currentChallenge) {
      if (currentChallenge.timeStart) {
        delete currentChallenge.timeStart
      }
      if (currentChallenge.timeEnd) {
        delete currentChallenge.timeEnd
      }
    }

    const translateStatus = status => {
      switch (status) {
        case 'SENT':
          return 'Udfordret'
        case 'ACCEPTED':
          return 'Accepteret'
        case 'DECLINED':
          return 'Afvist'
        default:
          return ''
      }
    }
    return (
      <React.Fragment>
        <SectionMenu links={this.getLinks()} />
        {!!currentCompetition && (
          <Container className='container-safe-height'>
            <ChallengeBox
              {...currentChallenge}
              UserID={this.state.settings.UserID}
              winnerId={currentCompetition.WinnerID}
              winnings={currentCompetition.winnings}
              timeStart={currentCompetition.timeStart}
              timeEnd={currentCompetition.timeEnd}
              wager={currentCompetition.wager}
              userPointsComputed={userPointsComputed}
              isOwnCompetition={this.isOwnCompetition()}
              competitionTimeEnd={currentCompetition.timeEnd}
              competitionStatus={currentCompetition.status}
              isCvrCompetition={isCvrCompetition}
            />
            <Row>
              <Col md='9' className='wg-row'>
                <p className='mb-5'>{nl2br(currentCompetition.moduleDescription)}</p>
                {(competitionProcess || []).map((x, i) => (
                  <Row key={i} className='wg-row'>
                    <Col xs='3' className='align-middle'>
                      {!isCvrCompetition && (
                        <img
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '50px',
                            objectFit: 'cover'
                          }}
                          className='userImage hofImage'
                          src={x.elementImageURL || 'https://static.upgrader.club/img/userthumb.png'}
                          alt={x.elementTitle}
                        />
                      )}
                    </Col>
                    <Col xs='6' className='align-middle pt-3'>
                      {x.elementTitle}
                    </Col>
                    <Col xs='3' className='align-middle pt-3'>
                      {translateStatus(x.status)}
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col md='3'>
                <CompetitionButton userPointsComputed={this.state.userPointsComputed}>Opret konkurrence</CompetitionButton>

                {this.isOwnCompetition() && this.canEdit(currentCompetition.status) && (
                  <CompetitionButton CompetitionID={this.props.match.params.CompetitionID} userPointsComputed={this.state.userPointsComputed}>
                    Rediger konkurrence
                  </CompetitionButton>
                )}

                <CvrInvitationBox {...currentCompetition} wagerPool={wagerPool} />
                {currentCompetitionID && <Comments relation='Competition' id={parseInt(currentCompetitionID)} />}
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    )
  }
}

class CompetitionButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showCreateModal: false
    }
  }

  closeModal = () => {
    this.setState({ showCreateModal: false })
  }

  showModal = () => {
    CompetitionActions.resetDirtyCompetition(this.props.CompetitionID || null)
    CompetitionActions.getAdversaries(this.props.CompetitionID || null)

    this.setState({ showCreateModal: true })
  }

  render() {
    const modal = this.state.showCreateModal && <EditCompetitionModal {...this.props} title={this.props.children} onCloseModal={this.closeModal} />

    return (
      <React.Fragment>
        {modal}
        <Button className='standardBtn-black mb-2' onClick={this.showModal}>
          {this.props.children}
        </Button>
      </React.Fragment>
    )
  }
}

class EditCompetitionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      getHelpModal: false
    }
    this.stores = [CompetitionStore, ProfileStore]
    this.getHelp = this.getHelp.bind(this)
  }

  getHelp() {
    this.setState({
      getHelpModal: !this.state.getHelpModal
    })
  }

  render() {
    const { onCloseModal, title } = this.props
    const {
      isAwaitingResponse,
      dirtyCompetition,
      adversaries,
      dirtyAdversaries,
      competitionProcess,
      currentCompetitionID,
      userPointsComputed
    } = this.state
    const isEditMode = this.props.CompetitionID && true

    const datePickerConfig = {
      inline: false,
      dateFormat: 'DD/MM/YYYY',
      viewMode: 'days',
      locale: 'da'
    }

    const onFieldChange = field => e => {
      CompetitionActions.modifyDirtyCompetition({
        [field]: e.target ? e.target.value : e
      })
    }

    const onStartDateChange = newStartDate => {
      if (!newStartDate) return

      const oldEndDate = dirtyCompetition.timeEnd
      const minStartDate = moment().add(1, 'days')
      const timeStart = newStartDate <= minStartDate ? minStartDate : newStartDate
      const timeEnd = oldEndDate.isAfter(timeStart, 'days') ? oldEndDate : moment(timeStart.toDate()).add(1, 'days')

      CompetitionActions.modifyDirtyCompetition({ timeStart, timeEnd })
    }

    const onEndDateChange = timeEnd => {
      if (!timeEnd) return

      const { timeStart } = dirtyCompetition
      const newEndDate = timeEnd.isAfter(timeStart, 'days') ? timeEnd : moment(timeStart.toDate()).add(1, 'days')
      CompetitionActions.modifyDirtyCompetition({ timeEnd: newEndDate })
    }

    const formIsInvalid = () => {
      const { timeStart, timeEnd, moduleName, moduleDescription, wager } = dirtyCompetition
      let invalidWager = wager === '' || parseInt(wager) < 0 || parseInt(wager) > userPointsComputed
      return (
        isAwaitingResponse || !moduleName || invalidWager || !timeStart || !timeEnd || !moduleDescription || (!dirtyAdversaries.length && !isEditMode)
      )
    }

    const { timeStart, timeEnd, moduleName, wager, moduleDescription } = dirtyCompetition

    const deleteButtonDisabled = () => isAwaitingResponse || dirtyCompetition.status != 'READY'
    const saveAction = isEditMode ? CompetitionActions.updateCompetition : CompetitionActions.saveCompetition

    let getHelpModal = null
    if (this.state.getHelpModal) {
      getHelpModal = (
        <Modal isOpen={true} toggle={this.getHelp}>
          <ModalHeader className='text-center pt-5 pb-4 pr-5 pl-5' toggle={this.getHelp}>
            Har du brug for hjælp? Sådan opretter du en konkurrence!
          </ModalHeader>
          <ModalBody>
            <Row className='pl-5 pr-5'>
              <Col md='4' xs='12'>
                <center>
                  <FontAwesomeIcon icon={'pen'} style={{ fontSize: '50px' }} className={'mb-3'} />
                  <p style={{ lineHeight: '1.7em', fontSize: '14px' }}>
                    Udfyld alle felterne i “Opret konkurrence” formen. Her kan du navngive din konkurrence, uddybe hvad konkurrencen går ud på i
                    beskrivelsen, vælge en sum point I hver især smider i konkurrencepuljen, samt sætte start og slut tidspunkt. Inviter dine
                    kollegaer i menuen til højre.
                  </p>
                </center>
              </Col>
              <Col md='4' xs='12'>
                <center>
                  <FontAwesomeIcon icon={'paper-plane'} style={{ fontSize: '50px' }} className={'mb-3'} />
                  <p style={{ lineHeight: '1.7em', fontSize: '14px' }}>
                    Inden konkurrencen er igang, vil dine kollegaer modtage en invitation på deres profil. De af dine kollegaer der har accepteret
                    invitationen inden startdatoen vil konkurrere mod dig om puljen. Glemte du at invitere en kollega? Du kan altid redigere din
                    konkurrence inden den starter.
                  </p>
                </center>
              </Col>
              <Col md='4' xs='12'>
                <center>
                  <FontAwesomeIcon icon={'trophy'} style={{ fontSize: '50px' }} className={'mb-3'} />
                  <p style={{ lineHeight: '1.7em', fontSize: '14px' }}>
                    Når konkurrencen er slut, bliver vinderen automatisk kontaktet og puljen overføres til vinderens konto. Point investeret i
                    konkurrencens sum vil være reserveret på deltagernes konti i perioden.
                  </p>
                </center>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )
    }

    return (
      <Modal isOpen={true} toggle={onCloseModal}>
        <ModalHeader toggle={onCloseModal}>{title}</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <h6>Du vælger selv perioden, hvori din konkurrence skal være aktiv.</h6>
                <p>
                  Vinderen bliver den af dine udvalgte kollegaer, som sælger flest pointgivende produkter i den valgte periode. Når to eller flere
                  personer har samme antal point optjent i konkurrenceperioden, vil systemet udvælge en tilfældig vinder.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs='8'>
                <Row>
                  <Col className='mb-4'>
                    <p className='font-weight-bold'>Titel</p>
                    <Input type='text' onChange={onFieldChange('moduleName')} value={moduleName} placeholder='Overskriften for din konkurrence' />
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <p className='font-weight-bold' style={{ marginBottom: '0px' }}>
                      Dato
                    </p>
                    <p>
                      Vælg den dato du vil starte/slutte konkurrencen. Vær opmærksom på, at vinderen først offenliggøres kl. 12.00 dagen efter
                      konkurrencen slutter.
                    </p>
                    <Row>
                      <Col xs='6' className='mb-3'>
                        <DatePicker
                          className='form-control'
                          disabled={isEditMode}
                          {...datePickerConfig}
                          selected={moment(timeStart)}
                          onChange={onStartDateChange}
                        />
                      </Col>
                      <Col xs='6' className='mb-3'>
                        <DatePicker
                          className='form-control'
                          disabled={isEditMode}
                          {...datePickerConfig}
                          selected={moment(timeEnd)}
                          onChange={onEndDateChange}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-4'>
                    <p className='font-weight-bold' style={{ marginBottom: '0px' }}>
                      Indsats
                    </p>
                    <p>
                      Angiv den pointsats hver deltager skal lægge i puljen for at deltage. Pointene reserveres på deltagernes konti ved accept af
                      deltagelse i konkurrencen og tildeles vinderen ved konkurrencens afslutning.
                    </p>
                    <Input
                      type='number'
                      disabled={isEditMode}
                      onChange={onFieldChange('wager')}
                      value={wager}
                      placeholder={`F.eks. 150 (du har ${userPointsComputed} point)`}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-4'>
                    <p className='font-weight-bold' style={{ marginBottom: '0px' }}>
                      Beskrivelse
                    </p>
                    <Input type='textarea' onChange={onFieldChange('moduleDescription')} value={moduleDescription} />
                  </Col>
                </Row>
                <Row>
                  <Col xs='6' className='mb-4'>
                    <Button className='standardBtn-black' onClick={onCloseModal}>
                      Annuller
                    </Button>
                  </Col>
                  <Col xs='6' className='mb-4'>
                    <Button
                      className='standardBtn-black'
                      disabled={formIsInvalid()}
                      onClick={() => {
                        saveAction(dirtyCompetition)
                        onCloseModal()
                      }}
                    >
                      {isAwaitingResponse ? 'Vent...' : isEditMode ? 'Gem ændringer' : 'Opret konkurrence'}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Vær opmærksom på, at når du klikker ”opret” kan datoer samt point ikke ændres.</p>
                    <p>
                      Hvis dette skal ændres skal konkurrencen slettes og oprettes på ny. For at slette skal du klikke på ”rediger konkurrence” og
                      vælge knappen ”slet konkurrence”.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs='4'>
                <div className='gray-bg pt-3 pb-3 pl-3 pr-3'>
                  <PickAdversaries adversaries={adversaries} dirtyAdversaries={dirtyAdversaries} competitionProcess={competitionProcess} />
                </div>
                <Button className='standardBtn-black mt-2' onClick={this.getHelp}>
                  Har du brug for hjælp?
                </Button>
                {getHelpModal}
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          {isEditMode && (
            <Button
              className='standardBtn-red'
              disabled={deleteButtonDisabled()}
              onClick={() => {
                if (confirm('Vil du slette denne konkurrence?\nDenne handling kan ikke fortrydes')) {
                  CompetitionActions.deleteCompetition(currentCompetitionID)
                }
              }}
            >
              Slet konkurrence
            </Button>
          )}
        </ModalFooter>
      </Modal>
    )
  }
}

const CvrInvitationBox = props => {
  const { wagerPool, timeStart, timeEnd } = props
  return (
    <React.Fragment>
      <Row className='text-center mt-4 mb-4'>
        <Col>
          <center>
            <p>
              <Moment format='DD/MM/YYYY'>{timeStart}</Moment> - <Moment format='DD/MM/YYYY'>{timeEnd}</Moment>
            </p>
            <div
              className='gray-bg hideOnIe'
              style={{
                borderRadius: '100px',
                height: '150px',
                width: '150px',
                position: 'relative'
              }}
            >
              <img
                style={{
                  width: '75px',
                  height: '75px',
                  position: 'absolute',
                  marginLeft: '-25%',
                  marginTop: '25%'
                }}
                src='https://i.imgur.com/WWSnDCW.png'
                className='p-15'
              />
            </div>
            <h6 className='mt-2'>{wagerPool} point</h6>
          </center>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const ChallengeBox = ({
  ChallengeID,
  CompetitionID,
  timeStart,
  status,
  competitionTimeEnd,
  wager,
  isCvrCompetition,
  isOwnCompetition,
  competitionStatus,
  userPointsComputed,
  winnerId,
  winnings,
  UserID
}) => {
  if (!ChallengeID || !CompetitionID || isCvrCompetition) return null

  const challengeHasStarted = moment(timeStart).isBefore() || competitionStatus == 'STARTED'
  let statusTranslated = ''
  switch (status) {
    case 'SENT':
      statusTranslated = 'Udfordret'
      break
    case 'ACCEPTED':
      statusTranslated = 'Accepteret'
      break
    case 'DECLINED':
      statusTranslated = 'Afvist'
      break
    default:
      statusTranslated = ''
  }
  if (competitionStatus == 'CANCELED') {
    return (
      <Row>
        <Col className='text-center gray-bg pt-4 pb-4 pl-5 pr-5 mb-4'>
          <h4>Denne konkurrence er annulleret</h4>
          <h5>Konkurrencen er blevet annulleret, da der ikke var nogle deltagere tilmeldt.</h5>
          <h5>
            Konkurrencen fjernes fra Konkurrencer d.{' '}
            {moment(competitionTimeEnd)
              .add(1, 'month')
              .format('DD/MM/YYYY')}
          </h5>
        </Col>
      </Row>
    )
  }
  if (competitionStatus == 'ENDED') {
    return (
      <Row>
        <Col className='text-center gray-bg pt-4 pb-4 pl-5 pr-5 mb-4'>
          <h4>Denne konkurrence er afsluttet</h4>
          <h5>
            Konkurrencen fjernes fra Konkurrencer d.{' '}
            {moment(competitionTimeEnd)
              .add(1, 'month')
              .format('DD/MM/YYYY')}
          </h5>
          {['SENT', 'DECLINED'].indexOf(status) > -1 && (
            <p>
              <i>Du valgte ikke at deltage i denne konkurrence.</i>
            </p>
          )}
          {['ACCEPTED'].indexOf(status) > -1 && UserID === winnerId && (
            <p>
              <i>Du vandt konkurrencen og præmiesummen på {winnings} point.</i>
            </p>
          )}
          {['ACCEPTED'].indexOf(status) > -1 && UserID !== winnerId && (
            <p>
              <i>Du deltog i konkurrencen, men vandt ikke denne gang.</i>
            </p>
          )}
        </Col>
      </Row>
    )
  }
  if (isOwnCompetition) {
    return null
  }

  const acceptButtonText = () => {
    if (status === 'ACCEPTED') return 'Accepteret'
    if (challengeHasStarted) return 'Startet'
    if (wager > userPointsComputed) return `Kræver minimum ${wager} point`
    return 'Acceptér'
  }

  return (
    <React.Fragment>
      <Row>
        <Col className='text-center'>
          <div className='gray-bg pt-4 pb-4 pl-5 pr-5 mb-4'>
            <h5>Du er blevet udfordret til at deltage i denne konkurrence!</h5>
            <p style={{ marginBottom: '5px' }}>Indsatsen for at deltage er {wager} point. Optjen flest point i perioden og løb afsted med puljen.</p>
            <p>Vær opmærksom på, at når du accepterer at deltage i konkurrencen, reserveres indsatsen på din konto, indtil konkurrencen afsluttes.</p>
            {!isCvrCompetition && !challengeHasStarted && !isOwnCompetition && (
              <Row>
                <Col md={{ size: 8, offset: 2 }} className='mb-3'>
                  <Row>
                    <Col>
                      <Button
                        className='standardBtn-red'
                        disabled={status === 'DECLINED' || challengeHasStarted}
                        onClick={() =>
                          CompetitionActions.rejectChallenge({
                            ChallengeID,
                            CompetitionID
                          })
                        }
                      >
                        Afvis
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className='standardBtn-black'
                        disabled={status === 'ACCEPTED' || challengeHasStarted || wager > userPointsComputed}
                        onClick={() =>
                          CompetitionActions.acceptChallenge({
                            ChallengeID,
                            CompetitionID
                          })
                        }
                      >
                        {acceptButtonText()}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            {competitionStatus == 'STARTED' ? (
              <Row>
                <Col md={{ size: 8, offset: 2 }} className='mb-3'>
                  <h3>Konkurrencen er startet</h3>
                  <p>
                    Konkurrencens slutter d. {moment(competitionTimeEnd).format('DD/MM/YYYY')} - Din status er : {statusTranslated}
                  </p>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col className='text-center'>
                  <p>
                    Du kan ændre dit svar indtil konkurrencens start d. {moment(timeStart).format('DD/MM/YYYY')} - Din nuværende status er :{' '}
                    {statusTranslated}
                  </p>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const PickAdversaries = ({ adversaries, dirtyAdversaries }) => {
  const toggleAdversary = e => {
    const adversary = adversaries.find(x => x.UserID === parseInt(e.target.value))
    if (adversary) return CompetitionActions.toggleAdversaries(adversary.UserID)
  }

  const toggleMarkAll = e => {
    if (e.target.checked) return CompetitionActions.toggleAdversaries(adversaries.filter(x => x.status == null).map(x => x.UserID))

    CompetitionActions.toggleAdversaries([])
  }

  const accepted = (adversaries || []).filter(x => x.status === 'ACCEPTED')
  const sent = (adversaries || []).filter(x => x.status === 'SENT')
  const declined = (adversaries || []).filter(x => x.status === 'DECLINED')
  const rest = (adversaries || []).filter(x => x.status == null)
  const grouped = []
    .concat(accepted)
    .concat(sent)
    .concat(rest)
    .concat(declined)

  const consolidated = grouped.map((x, i) => {
    switch (x.status) {
      case 'ACCEPTED':
        return (
          <React.Fragment key={i}>
            <input type='checkbox' disabled /> <span style={{ fontSize: '13px' }}>{x.userName}</span>{' '}
            <div className='challengedBadge' style={{ backgroundColor: '#4baf61' }}>
              Accepteret
            </div>
            <br />
          </React.Fragment>
        )
      case 'SENT':
        return (
          <React.Fragment key={i}>
            <input type='checkbox' disabled /> <span style={{ fontSize: '13px' }}>{x.userName}</span>{' '}
            <div className='challengedBadge' style={{ backgroundColor: '#ffd507' }}>
              Inviteret
            </div>
            <br />
          </React.Fragment>
        )
      case 'DECLINED':
        return (
          <React.Fragment key={i}>
            <input type='checkbox' disabled /> <span style={{ fontSize: '13px' }}>{x.userName}</span>{' '}
            <div className='challengedBadge' style={{ backgroundColor: '#d13d3d' }}>
              Afvist
            </div>
            <br />
          </React.Fragment>
        )

      default:
        return (
          <React.Fragment key={i}>
            <input key={i} type='checkbox' checked={dirtyAdversaries.includes(x.UserID)} value={x.UserID} onChange={toggleAdversary} />
            <span style={{ fontSize: '13px' }}> {x.userName}</span>
            <br />
          </React.Fragment>
        )
    }
  })

  return (
    <Row>
      <Col>
        <p className='font-weight-bold' style={{ marginBottom: '0px' }}>
          Udfordringer
        </p>
        <input type='checkbox' style={{ color: 'rgba(0, 0, 0, 0.5)' }} onChange={toggleMarkAll} />
        <span style={{ fontSize: '13px' }}> Markér alle</span>
        <br />
        {consolidated}
        <br />
      </Col>
    </Row>
  )
}

export { Competition, CompetitionButton }
