import React, { useState } from 'react'
import useBrandFilters from 'Modules/shop/shopPage/useBrandFilters.js'
import { Row, Button, Collapse, Card, CardBody, CustomInput } from 'reactstrap'

export default function BrandFilters() {
  const [isOpen, setOpen] = useState(false)
  const [brands, selectedBrands, handleBrandChange] = useBrandFilters()

  const toggleIsOpen = () => {
    setOpen(!isOpen)
  }

  return (
    <Row>
      <div className='sidebar-header mt-4 mb-2'>Brands</div>
      <Button color='secondary' className='standardBtn-black' onClick={toggleIsOpen} style={{ marginBottom: '1rem', width: '100%' }}>
        Vælg brands
      </Button>
      <Collapse style={{ width: '100%' }} isOpen={isOpen}>
        <Card style={{ maxHeight: '175px' }}>
          <CardBody style={{ maxHeight: '175px', overflowY: 'auto' }}>
            {brands.map(brand => (
              <div key={brand}>
                <CustomInput
                  id={brand}
                  checked={selectedBrands.includes(brand)}
                  onChange={() => handleBrandChange(brand)}
                  type='checkbox'
                  label={brand}
                />
              </div>
            ))}
          </CardBody>
        </Card>
      </Collapse>
    </Row>
  )
}
