import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'reactstrap'
import ShoppingCartItemView from 'Modules/shop/basket/ShoppingCartItemView.js'
import ShoppingCartConfirmation from 'Modules/shop/basket/ShoppingCartConfirmation.js'

export default function ShoppingCart(props) {
  return (
    <Modal isOpen toggle={props.toggleAction}>
      {props.showConfirmation ? (
        <ShoppingCartConfirmation toggle={props.toggleAction} orderId={props.orderId} error={props.errorMessage} />
      ) : (
        <ShoppingCartItemView
          containsUnavailableProducts={props.containsUnavailableProducts}
          submitButtonDisabled={props.submitButtonDisabled}
          basket={props.basket}
          basketTotal={props.basketTotal}
          termsAccepted={props.termsAccepted}
          addresses={props.addresses}
          hasInsufficientPoints={props.hasInsufficientPoints}
          handleOrderClick={props.handleOrderClick}
          onAddressChange={props.onAddressChange}
          toggle={props.toggleAction}
          placingOrder={props.placingOrder}
          TermsConditionsURL={props.TermsConditionsURL}
          userMissingFields={props.userMissingFields}
        />
      )}
    </Modal>
  )
}

ShoppingCart.propTypes = {
  toggleAction: PropTypes.func,
  showConfirmation: PropTypes.bool,
  orderId: PropTypes.string,
  errorMessage: PropTypes.string,
  containsUnavailableProducts: PropTypes.bool,
  submitButtonDisabled: PropTypes.bool,
  basket: PropTypes.array,
  basketTotal: PropTypes.number,
  termsAccepted: PropTypes.bool,
  addresses: PropTypes.array,
  hasInsufficientPoints: PropTypes.bool,
  handleOrderClick: PropTypes.func,
  onAddressChange: PropTypes.func,
  placingOrder: PropTypes.bool,
  TermsConditionsURL: PropTypes.string
}
