import React from 'react'
import { Component } from 'reflux'
import Menu from 'Modules/layout/menu'
import ChristmasHeader from './christmasHeader'
import { LoginStore } from 'Stores/loginStore'

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }
  render() {
    return (
      <React.Fragment>
        <React.Fragment>
          <header>
            <Menu location={this.props.location} />
          </header>
          {/* !!---- feature element basics -----!!!
							<div className="feature-top-wrapper">
								<div className="feature-overlay"/>
								<div className="container-fluid container-padding pt-5 pb-5">
									<Row>
										<Col sm="2" md="4" xs="12" className="text-center pt-4">
											<h6>Din saldo</h6>
											<h6><b>54.241 point</b></h6>
										</Col>
										<Col sm="10" md="8">
											<div className="d-none d-sm-block">
												<h6>Featured brand: DoucheBags</h6>
												<p className="sectionMenu" style={{margin:'0px'}}>We want people to be able to travel however they want, whilst bringing whatever they need. We develop travel gear for the long journeys as well as the short ones – for the passionate people who travel the world in search of adventure.</p>
											</div>
										</Col>
									</Row>
								</div>
							</div>
						*/}
        </React.Fragment>
        {this.state.settings.christmasShop && !this.state.settings.disableDefaultChristmasHeader && <ChristmasHeader />}
      </React.Fragment>
    )
  }
}
