import React from 'react'
import Reflux from 'reflux'
import { Container, Row, Col } from 'reactstrap'
import GoudaClaimPointsForm from 'Modules/claim-points/GoudaClaimPointsForm'
import { GoudaClaimPointsStore } from 'Stores/goudaClaimPointsStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GoudaClaimPointsSuccess from 'Modules/claim-points/GoudaClaimPointsSuccess'
import GoudaClaimPointsError from 'Modules/claim-points/GoudaClaimPointsError'

export default class GoudaClaimPoints extends Reflux.Component {
  constructor(props) {
    super(props)
    this.store = GoudaClaimPointsStore
    this.storeKeys = ['loading', 'success', 'error']
  }

  render() {
    let body
    if (this.state.loading) {
      body = <FontAwesomeIcon icon='spinner' size='4x' spin style={{ display: 'block', margin: 'auto' }} />
    } else if (this.state.success) {
      body = <GoudaClaimPointsSuccess />
    } else if (this.state.error) {
      body = <GoudaClaimPointsError />
    } else {
      body = <GoudaClaimPointsForm />
    }

    return (
      <Container className='container-safe-height'>
        <Row className='justify-content-center'>
          <Col md={10} lg={8}>
            <h2 className='mb-5 text-center'>Indberet forsikringssalg</h2>
            {body}
          </Col>
        </Row>
      </Container>
    )
  }
}
