import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectCategories, setCategory, getCategoryFilter } from 'Reducers/productsSlice.js'
import { TrackableFunction } from 'Components/trackableComponents'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useHistory } from 'react-router-dom'

export default function CategoryFilters() {
  const categories = useSelector(selectCategories)
  const selectedCategory = useSelector(getCategoryFilter)
  const dispatch = useDispatch()
  const history = useHistory()

  const setSelectedCategory = (category = '') => {
    const search = category !== '' ? '?c=' + encodeURI(category) : ''
    dispatch(setCategory(category))
    history.push({ search })
    TrackableFunction({
      activityType: 'filter',
      activityName: 'shop',
      activityArgument: category
    })
  }

  return (
    <UncontrolledDropdown style={{ width: '100%' }}>
      <DropdownToggle direction='down' style={{ marginBottom: '1rem', width: '100%' }} className='standardBtn-black'>
        Kategori
      </DropdownToggle>
      <DropdownMenu style={{ width: '100%' }}>
        <>
          <div onClick={() => setSelectedCategory()}>
            <DropdownItem className='font-weight-bold'>Ryd kategori</DropdownItem>
          </div>
          {categories.map(category => {
            const isCurrentCategoryActive = category === selectedCategory

            return (
              <DropdownItem
                key={category}
                href='#'
                active={isCurrentCategoryActive}
                onClick={e => {
                  e.preventDefault()
                  setSelectedCategory(category)
                }}
              >
                {category}
              </DropdownItem>
            )
          })}
        </>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
