import React from 'react'
import Reflux from 'reflux'
import Header from 'Modules/layout/header'
import FeatureElement from 'Modules/layout/feature-element/FeatureElement'
import { Button, Col, Container, Row } from 'reactstrap'
import { animateScroll as scroll } from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoginStore, LoginActions } from 'Stores/loginStore'
import styled from 'styled-components'
import CPRModal from 'Modules/layout/CPRModal'

const StyledFooter = styled.div`
  @media print {
    display: none;
  }
`

const StyledGoToTop = styled.div`
  @media print {
    display: none;
  }
`

export default class Layout extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings', 'showCPRModal', 'CPRNumber']
  }
  componentDidMount() {
    LoginActions.getUserCPR()
  }
  render() {
    return (
      <React.Fragment>
        <Header location={this.props.location} />
        <FeatureElement history={this.props.history} />
        <div className='fakeFeature' style={{ display: 'none' }}></div>
        {this.props.children}
        <div />
        {this.state.showCPRModal && this.state.settings && this.state.settings.features && this.state.settings.features.LaunchCPRPopupAfterLogin && (
          <CPRModal history={this.props.history} CPRNumber={this.state.CPRNumber} />
        )}
        <StyledFooter className='page-footer footer-color font-small mt-5 mt-5'>
          {this.state.settings && this.state.settings.christmasShop && (
            <Container className='text-left'>
              <Row className='pt-5 pb-4'>
                <Col sm='4'>
                  <h4 className='christmasFont'>Kontakt os</h4>
                  <p>
                    MS Branding A/S
                    <br />
                    Ellekær 6
                    <br />
                    DK - 2730 Herlev
                    <br />
                    Tlf: +45 7020 1764
                    <br />
                    E-mail: info@msbranding.dk
                  </p>
                </Col>
                <Col sm='8' className='text-center'>
                  <h4 className='christmasFont'>Oplever du problemer? Kontakt support</h4>
                  <center>
                    <Button
                      className='standardBtn-black pt-2 pb-2 mt-3 supportBtn'
                      href={'mailto:' + 'fv@msbranding.dk'}
                      style={{ height: 'auto', maxWidth: '100px' }}
                    >
                      Support
                    </Button>
                  </center>
                </Col>
              </Row>
            </Container>
          )}
          <div className='footer-copyright text-center pt-3 pb-3'>
            <Container fluid>&copy; {new Date().getFullYear()} Copyright: MSBranding.dk</Container>
          </div>
        </StyledFooter>

        <StyledGoToTop className='fixed-bottom' style={{ pointerEvents: 'none' }}>
          <div className='float-right'>
            <a className='pr-5 mb-5' onClick={scroll.scrollToTop} style={{ pointerEvents: 'auto' }}>
              <div
                style={{
                  position: 'relative',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  marginRight: '50px',
                  height: '50px',
                  width: '50px',
                  borderRadius: '50px'
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    top: '48%',
                    left: '49%',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  <FontAwesomeIcon icon='chevron-up' style={{ fontSize: '15px' }} {...{ cursor: 'pointer' }} />
                </span>
              </div>
            </a>
            <div style={{ height: '50px', width: '50px' }} />
          </div>
        </StyledGoToTop>
      </React.Fragment>
    )
  }
}
