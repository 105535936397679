import React from 'react'

const LoadingSection = props => {
  if (props.target) return <div>{props.children}</div>

  return (
    <section className='main-container'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 text-center'>
            <h3 className='loader'>Loading...</h3>
          </div>
        </div>
      </div>
    </section>
  )
}

const LoadingRow = props => {
  if (props.target) return props.children

  return (
    <div className='row'>
      <div className='col-sm-12 text-center'>
        <h1 className='loader'>Loading...</h1>
      </div>
    </div>
  )
}

const LoadingColumn = props => {
  if (props.target) return <div>{props.children}</div>

  return (
    <div className='col-sm-12 text-center'>
      <h1 className='loader'>Loading...</h1>
    </div>
  )
}

const LoadingContent = ({ target, children }) => {
  if (target) return <React.Fragment>{children}</React.Fragment>

  return <h1 className='loader'>Loading...</h1>
}

export { LoadingSection, LoadingRow, LoadingColumn, LoadingContent }
