import { createSlice, createSelector } from '@reduxjs/toolkit'
import { fetchShopsRequest, fetchShopsSuccess, fetchShopsFailure, SHOP_STATUS } from 'Reducers/sharedActions/shopActions.js'

const slice = createSlice({
  name: 'shops',
  initialState: {
    status: null,
    shops: []
  },
  reducers: {},
  extraReducers: {
    [fetchShopsRequest]: state => {
      state.status = SHOP_STATUS.LOADING_DATA
    },
    [fetchShopsSuccess]: (state, action) => {
      state.shops = action.payload
      state.status = SHOP_STATUS.LOADING_DATA_SUCCESS
    },
    [fetchShopsFailure]: state => {
      state.status = SHOP_STATUS.LOADING_DATA_FAILURE
    }
  }
})

// Export selectors
export const getStatus = state => state.shops.status
export const getShops = state => state.shops.shops

export const selectShop = createSelector([getShops, (state, shopId) => shopId], (shops, activeShopId) => {
  return shops.find(shop => shop.ShopID === parseInt(activeShopId, 10) || shop.ShopID === activeShopId)
})

export default slice.reducer
