import React from 'react'
import Reflux from 'reflux'
import { LoginStore } from 'Stores/loginStore'
import GoudaClaimPoints from './GoudaClaimPoints'
import ClaimPointsForm from './ClaimPointsForm'
const GOUDA = 68
export default class ClaimPoints extends Reflux.Component {
  constructor(props) {
    super(props)
    this.store = LoginStore
    this.storeKeys = ['settings']
  }

  render() {
    return this.state.settings && this.state.settings.BrandID === GOUDA ? <GoudaClaimPoints /> : <ClaimPointsForm />
  }
}
