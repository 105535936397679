import React from 'react'
import moment from 'moment'
import Reflux from 'reflux'
const shajs = require('sha.js')
import Proxies from 'Components/proxies'
const { activity } = Proxies

const createAndStoreInstanceKey = (seed = '') => {
  let random = shajs('sha256')
    .update(Math.random())
    .digest('hex')
  const baseCalc = seed + 'WEB' + random + Date.now()
  let instanceKey = shajs('sha256')
    .update(baseCalc)
    .digest('hex')
  sessionStorage.instanceKey = instanceKey

  return instanceKey
}

const logActivity = (activityType, activityName, activityArgumentID, activityArgument = '', data = {}) => {
  let instanceKey = sessionStorage.instanceKey

  if (
    !moment(sessionStorage.lastActivity)
      .add(20, 'minutes')
      .isAfter()
  ) {
    instanceKey = createAndStoreInstanceKey(localStorage.session)
  }

  data = Object.assign(data, { instanceKey: instanceKey })

  if (Reflux.GlobalState.MenuStore && Reflux.GlobalState.MenuStore.profile) {
    const { BrandID, UserID } = Reflux.GlobalState.MenuStore.profile
    data = Object.assign(data, { UserID, BrandID })
  }
  sessionStorage.lastActivity = moment().format()
  activity(activityType, activityName, activityArgumentID, activityArgument, data)
}

const TrackableFunction = ({ activityType, activityName, activityArgumentID = null, activityArgument = null }, action = () => {}) => {
  logActivity(activityType || 'click', activityName, activityArgumentID, activityArgument)
  action()
}

const TrackableClick = props => {
  const trackingClick = childClick => e => {
    logActivity('click', props.activityName, props.activityArgumentID, props.activityArgument)
    childClick && childClick(e)
  }

  return React.cloneElement(props.children, {
    onClick: trackingClick(props.children.props.onClick)
  })
}

export { TrackableClick, TrackableFunction }
