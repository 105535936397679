import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getSearchTermFilter, setSearchTerm } from 'Reducers/productsSlice.js'
import { TrackableFunction } from 'Components/trackableComponents'
import _ from 'lodash'

const debouncedTrackableFunction = _.debounce(
  term =>
    TrackableFunction({
      activityType: 'filter',
      activityName: 'search',
      activityArgument: term
    }),
  2000
)

export default function ProductSearch() {
  const searchTerm = useSelector(getSearchTermFilter)
  const dispatch = useDispatch()

  return (
    <div className='searchForm'>
      <FontAwesomeIcon icon='search' className='searchBarIcon' />
      <input
        type='text'
        className='searchBar'
        placeholder='Indtast søgeord...'
        value={searchTerm}
        onChange={e => {
          const term = e.target.value
          dispatch(setSearchTerm(term))
          debouncedTrackableFunction(term)
        }}
      />
    </div>
  )
}
