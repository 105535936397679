import React from 'react'
import Reflux from 'reflux'
import { Row, Col } from 'reactstrap'
import TopMenu from 'Modules/shop/shopPage/topMenu/TopMenu'
import ProductList from 'Modules/shop/shopPage/ProductList.js'
import { LoginStore } from 'Stores/loginStore'
import _ from 'lodash'

export default class RefluxWrap extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }

  render() {
    return <TwelveColLayout {...this.props} settings={this.state.settings} />
  }
}

function TwelveColLayout({ settings, props }) {
  return (
    <>
      {!_.get(settings, 'features.shop.disableTopMenu') && (
        <Row>
          <Col>
            <TopMenu {...props} />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <ProductList {...props} />
        </Col>
      </Row>
    </>
  )
}
