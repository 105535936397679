import React from 'react'
import Reflux from 'reflux'
import { connect } from 'react-redux'
import { LoginStore } from 'Stores/loginStore'
import { getStatus as getProductStatus, PRODUCTS_STATUS } from 'Reducers/productsSlice.js'
import ThreeByNineLayout from 'Modules/shop/shopPage/layouts/ThreeByNineLayout'
import TwelveColLayout from 'Modules/shop/shopPage/layouts/TwelveColLayout'
import _ from 'lodash'

class ShopPage extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }

  render() {
    let result

    if (this.props.productStatus === PRODUCTS_STATUS.CATALOG_EMPTY) {
      return <h6 className='text-center'>Der er desværre ingen produkter i butikken.</h6>
    }

    switch (_.get(this.state.settings, 'features.shop.layout')) {
      case '12col':
        result = <TwelveColLayout props={this.props} />
        break

      default:
        result = <ThreeByNineLayout props={this.props} />
    }

    return result
  }
}

const mapStateToProps = state => ({
  productStatus: getProductStatus(state)
})

export default connect(mapStateToProps, null)(ShopPage)
