import React from 'react'
import Reflux from 'reflux'
import { LoginStore } from 'Stores/loginStore'
import SectionMenu from 'Modules/layout/SectionMenu.js'
import isIE from 'Components/isIE'
import _ from 'lodash'

export default class RefluxWrap extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }

  render() {
    return <ProfileMenu {...this.props} settings={this.state.settings} />
  }
}

function ProfileMenu({ settings }) {
  const getLinks = () => {
    let rVal = []
    if (!isIE() && !_.get(settings.features, 'isSeasonShop')) {
      rVal.push({
        name: 'Dashboard',
        url: '/profile',
        sectionName: 'profile'
      })
    }
    if (_.get(settings, 'features.Inbox')) {
      rVal.push({
        name: 'Indbakke',
        url: '/profile/inbox',
        sectionName: 'profile'
      })
    }
    rVal.push({
      name: 'Ordrer',
      url: '/profile/orders',
      sectionName: 'profile'
    })
    if (!_.get(settings.features, 'isSeasonShop')) {
      rVal.push({
        name: 'Point',
        url: '/profile/points',
        sectionName: 'profile'
      })
    }
    rVal.push({
      name: 'Indstillinger',
      url: '/profile/settings',
      sectionName: 'profile'
    })
    if (_.get(settings, 'features.userCampaignDashboard')) {
      rVal.push({
        name: 'Kampagner',
        url: '/profile/campaigns',
        sectionName: 'profile'
      })
    }
    rVal.push({
      name: 'Log ud',
      url: '/logout',
      sectionName: 'profile',
      onClick: confirmLogout
    })
    return rVal
  }

  return <SectionMenu links={getLinks()} />
}

const confirmLogout = e => {
  if (!confirm('Er du sikker på at du vil logge ud?')) e.preventDefault()
}
