import { createAction } from '@reduxjs/toolkit'
import { fetchShops } from 'Reducers/sharedActions/shopActions.js'

// Like actions
export const setLikedProductStatus = createAction('setLikedProductStatus')
export const likeProductSuccess = payload => (dispatch, getState) => {
  dispatch(setLikedProductStatus(payload))
  const { shops } = getState().shops
  const wishlistShop = shops.find(shop => shop.ShopID === 'wishlist')
  if (!wishlistShop) {
    dispatch(fetchShops(true))
  }
}

export const setUnLikeProductStatus = createAction('setUnLikeProductStatus')
export const unLikeProductSuccess = payload => (dispatch, getState) => {
  dispatch(setUnLikeProductStatus(payload))
  const { products } = getState().products
  const hasLikedProduct = products.find(product => product.likedReactionId !== null)
  if (!hasLikedProduct) {
    dispatch(fetchShops(true))
  }
}
