import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import Proxies from 'Components/proxies'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ClaimPointsForm() {
  const [products, setProducts] = useState([])
  const [claimDate, setClaimDate] = useState(moment().format('YYYY-MM-DD'))
  const [reference, setReference] = useState('')
  const [CustomerProductID, setCustomerProductID] = useState('')
  const [status, setStatus] = useState('FORM')
  const [customerName, setCustomerName] = useState('')
  const [financeSum, setFinanceSum] = useState()

  useEffect(() => {
    Proxies.customerProducts().then(responseJSON => {
      if (responseJSON) {
        setProducts(responseJSON)
      }
    })
  }, [])

  function resetform() {
    setClaimDate(moment().format('YYYY-MM-DD'))
    setReference('')
    setCustomerProductID('')
    setCustomerName('')
    setFinanceSum()
  }

  function handleSubmit(e) {
    e.preventDefault()
    Proxies.customerSalesClaim({
      CustomerProductID: parseInt(CustomerProductID),
      claimDate,
      reference,
      customerName,
      financeSum
    }).then(responseJSON => {
      if (responseJSON && responseJSON.CustomerSalesClaimID) {
        setStatus('SUCCESS')
      } else {
        setStatus('ERROR')
      }
      resetform()
    })
  }

  function isFormValid() {
    return reference.length && claimDate && CustomerProductID.length
  }

  return (
    <Container className='container-safe-height'>
      <Row className='justify-content-center'>
        <Col md={10} lg={8}>
          <h2 className='mb-5 mt-5 text-center'>Indberet salg</h2>
          {status === 'ERROR' && (
            <>
              <h4 className='mb-3 text-center'>Der skete desværre en ukendt fejl</h4>
              <FontAwesomeIcon icon='exclamation-triangle' color='#ff3838' size='5x' style={{ display: 'block', margin: 'auto' }} />
              <Button
                className='standardBtn-black mt-4'
                style={{
                  maxWidth: 250,
                  display: 'block',
                  margin: 'auto',
                  fontWeight: 'bold'
                }}
                onClick={() => setStatus('FORM')}
              >
                Prøv igen
              </Button>
            </>
          )}
          {status === 'SUCCESS' && (
            <>
              <h4 className='mb-3 text-center'>Tak for indberetning af dit salg</h4>
              <FontAwesomeIcon icon='check-circle' color='green' size='5x' style={{ display: 'block', margin: 'auto' }} />
              <Button
                className='standardBtn-black mt-4'
                style={{
                  maxWidth: 250,
                  display: 'block',
                  margin: 'auto',
                  fontWeight: 'bold'
                }}
                onClick={() => setStatus('FORM')}
              >
                Indberet ny
              </Button>
            </>
          )}
          {status === 'FORM' && (
            <Form onSubmit={handleSubmit}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for='claimDate'>Dato for salg:</Label>
                    <Input
                      type='date'
                      name='claimDate'
                      id='claimDate'
                      placeholder='Dato'
                      value={claimDate}
                      onChange={e => setClaimDate(moment(e.target.value).format('YYYY-MM-DD'))}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for='CustomerProductID'>Finansiering:</Label>
                    <Input
                      type='select'
                      name='CustomerProductID'
                      id='CustomerProductID'
                      value={CustomerProductID}
                      onChange={e => setCustomerProductID(e.target.value)}
                    >
                      <option value='' disabled>
                        Vælg finansiering
                      </option>
                      {products.map(pr => {
                        return (
                          <option key={pr.CustomerProductID} value={pr.CustomerProductID}>
                            {pr.moduleName}
                          </option>
                        )
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for='claimDate'>Kundenavn:</Label>
                    <Input
                      type='text'
                      name='customerName'
                      id='customerName'
                      placeholder='Kundenavn'
                      value={customerName}
                      onChange={e => setCustomerName(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for='CustomerProductID'>Finansieringsbeløb:</Label>
                    <Input
                      type='number'
                      name='financeSum'
                      id='financeSum'
                      value={financeSum}
                      onChange={e => {
                        let value = e.target.value.toString()
                        value = value.replaceAll('.', '')
                        setFinanceSum(value)
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for='reference'>Kontraktnummer:</Label>
                <Input
                  type='text'
                  name='reference'
                  id='reference'
                  placeholder='Kontraktnummer'
                  value={reference}
                  onChange={e => setReference(e.target.value)}
                  autoComplete='off'
                />
              </FormGroup>
              <FormGroup row className='justify-content-center'>
                <Button disabled={!isFormValid()} className='standardBtn-black' style={{ maxWidth: 250, fontWeight: 'bold' }}>
                  Indsend
                </Button>
              </FormGroup>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  )
}
