/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import URL from 'url-parse'
import Linkify from 'linkifyjs/react'
import { Container } from 'reactstrap'
import { BlogStore, BlogActions } from 'Stores/blogStore'
import nl2br from 'Components/nl2br'

let maxLength = 1000

export default class ChristmasBlog extends Component {
  constructor(props) {
    super(props)
    this.store = BlogStore
  }

  componentDidMount() {
    if (this.props.match.params.BlogID && this.props.match.params.BlogID !== this.state.currentBlogID) {
      BlogActions.getBlogElements(this.props.match.params.BlogID)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.BlogID && this.props.match.params.BlogID !== prevProps.match.params.BlogID) {
      BlogActions.getBlogElements(this.props.match.params.BlogID)
    }
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          {this.state.currentBlog &&
            this.state.currentBlog.map((e, i) => {
              return (
                <div className='blog mainPost mt-4' key={i}>
                  <BlogItem item={e} />
                </div>
              )
            })}
        </Container>
      </React.Fragment>
    )
  }
}

class BlogItem extends Component {
  constructor(props) {
    super(props)
    let limit = true
    if (typeof props.limit != 'undefined') {
      limit = props.limit
    }
    this.state = {
      limit: limit
    }
  }
  togglePost() {
    this.setState({ limit: !this.state.limit })
  }
  render() {
    if (!this.props.item) {
      return null
    }
    let e = this.props.item
    return (
      <React.Fragment>
        <Attachments attachments={e.attachments} />
        <h2 className='mainPost blogTitle'>{e.elementTitle}</h2>
        <p className='content'>
          {e.elementContent.length > maxLength && this.state.limit ? (
            nl2br(e.elementContent.substr(0, maxLength - 3) + '...')
          ) : (
            <Linkify>{nl2br(e.elementContent)}</Linkify>
          )}
          {e.elementContent.length > maxLength && (
            <a className='readMore' onClick={this.togglePost.bind(this)}>
              {' '}
              Se {this.state.limit ? 'mere' : 'mindre'}
            </a>
          )}
        </p>
        <p className='text-right date'>{e.timeInsert}</p>
      </React.Fragment>
    )
  }
}

const Attachments = props => {
  if (props.attachments && props.attachments.length > 0) {
    if (props.attachments.length == 1) {
      return <RenderAttachment attachment={props.attachments[0]} />
    }
    return props.attachments.map((e, i) => {
      return <RenderAttachment attachment={e} key={i} />
    })
  }
  return null
}

class RenderAttachment extends Component {
  constructor(props) {
    super(props)
  }
  ytVidId(url) {
    let p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    return url.match(p) ? RegExp.$1 : false
  }
  render() {
    if (this.props.attachment) {
      let attachment = this.props.attachment
      if (attachment.ImageID) {
        let image = ''
        if (attachment.imageOptimizedSourceSmall) {
          image = attachment.imageOptimizedSourceSmall
        } else if (attachment.imageOptimizedSourceMedium) {
          image = attachment.imageOptimizedSourceMedium
        } else if (attachment.imageOptimizedSource) {
          image = attachment.imageOptimizedSource
        } else {
          image = attachment.imageOriginSource
        }
        return <img style={{ maxWidth: '100%' }} src={image} />
      }
      if (attachment.LinkID) {
        let parsed = new URL(attachment.linkRef)
        let videoID = null
        switch (parsed.hostname) {
          case 'youtu.be':
          case 'youtube.com':
          case 'youtube-nocookie.com':
          case 'm.youtube.com':
          case 'www.youtube.com':
            videoID = this.ytVidId(attachment.linkRef)
            return (
              <div className='embed-responsive embed-responsive-16by9' key={attachment.LinkID}>
                <iframe className='embed-responsive-item' src={'//www.youtube.com/embed/' + videoID + '?feature=oembed'} allowFullScreen />
              </div>
            )
          case 'vimeo.com':
            videoID = parsed.pathname.split('/')
            videoID = videoID[videoID.length - 1]
            return (
              <div className='embed-responsive embed-responsive-16by9' key={attachment.LinkID}>
                <iframe className='embed-responsive-item' src={'//player.vimeo.com/video/' + videoID} allowFullScreen />
              </div>
            )
          default:
            break
        }
      }
    }
    return null
  }
}

/*
<pre key={i}>{JSON.stringify(e, null, '\t')}</pre>

if(i<=1) {
	return (

	)
}
*/
