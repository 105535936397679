import React from 'react'
import Proxies from 'Components/proxies.js'

export default class SSO extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    let data = this.QueryStringToJSON(this.props.location.search)
    Proxies.ssoValidate(data).then(responseJson => {
      if (responseJson && responseJson.brandSlug) {
        let sessionBrandSlug = responseJson.brandSlug
        let currentBrandSlug = this.getBrandSlug()
        if (sessionBrandSlug.toLowerCase() === currentBrandSlug.toLowerCase()) {
          sessionStorage.clear()
          localStorage.setItem('session', data.token)
          location.href = '/' // Success
        } else {
          let renderPath = this.props.location.pathname + this.props.location.search
          if (window.location.hostname.includes('localhost')) {
            window.location.replace('http://' + sessionBrandSlug + '.localhost:3000' + renderPath)
          } else {
            window.location.replace('https://' + sessionBrandSlug + '.' + this.getDomainName() + renderPath)
          }
        }
      } else {
        location.href = '/' // Fallback redirect
      }
    })
  }

  getBrandSlug() {
    let urlBase = window.location.hostname.split('.')[0].toLowerCase()
    if (urlBase.includes('-')) {
      let theseOptions = urlBase.split('-')
      urlBase = theseOptions.shift()
    }
    return urlBase
  }

  getDomainName() {
    let urlBase = window.location.hostname.split('.')
    if (urlBase.length > 2) {
      urlBase.slice(0, 1)
    }
    return urlBase.join('.')
  }

  QueryStringToJSON(data = location.search) {
    let pairs = data.slice(1).split('&')

    let result = {}
    pairs.forEach(function(pair) {
      pair = pair.split('=')
      result[pair[0]] = decodeURIComponent(pair[1] || '')
    })
    return JSON.parse(JSON.stringify(result))
  }

  render() {
    return null
  }
}
