import React, { useState } from 'react'
import Reflux from 'reflux'
import ProductModal from 'Modules/shop/productPage/ProductModal.js'
import { TrackableClick } from 'Components/trackableComponents'
import { BasketStore } from 'Stores/basketStore'
import { basketHasRoomForMoreProducts } from 'Modules/shop/shared/util.js'
import { Row, Col, Button } from 'reactstrap'
import { selectShop } from 'Reducers/shopsSlice.js'
import { useSelector, useDispatch } from 'react-redux'
import { LoginStore } from 'Stores/loginStore'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { selectProduct } from 'Reducers/basketSlice.js'

export default class RefluxWrap extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [BasketStore, LoginStore]
    this.storeKeys = ['basket', 'basketIsLoading', 'settings']
  }

  render() {
    return (
      <SelectProductButton {...this.props} basket={this.state.basket} basketIsLoading={this.state.basketIsLoading} settings={this.state.settings} />
    )
  }
}

function SelectProductButton({ basket, basketIsLoading, settings, selectedVariation, currentProduct, isUserSeasonOrderExists }) {
  if (_.get(settings, 'features.shop.disableAddProduct')) return null

  const [isProductModalOpen, setIsProductModalopen] = useState(false)
  const { ShopID } = useParams()
  const activeShop = useSelector(state => selectShop(state, ShopID))
  const dispatch = useDispatch()
  const christmasGiftSelectionModeEnabled = _.get(settings, 'features.shop.enableSelectChristmasGift')
  const primaryButtonText = christmasGiftSelectionModeEnabled ? 'Vælg denne julegave' : 'Læg i kurv'

  const disableAddToBasket =
    (_.get(settings, 'features.productOutOfStock') && currentProduct.isOutOfStock) ||
    (_.get(settings, 'features.isSeasonShop') && isUserSeasonOrderExists) ||
    basketIsLoading ||
    !selectedVariation ||
    (!!_.get(selectedVariation, 'variationStockable') && _.get(selectedVariation, 'variationStock') <= 0) ||
    (!!_.get(selectedVariation, 'allowPreorder') && _.get(selectedVariation, 'preorderStock') === 0) ||
    !basketHasRoomForMoreProducts(basket, activeShop)
  const addToBasketButtonText =
    _.get(settings, 'features.isSeasonShop') && isUserSeasonOrderExists
      ? 'Du kan ikke vælge flere'
      : basketHasRoomForMoreProducts(basket, activeShop)
      ? primaryButtonText
      : 'Du kan ikke vælge flere'

  let addToBasketShopId = ShopID
  if (isNaN(ShopID)) {
    if (currentProduct.reactions && currentProduct.reactions.length > 0) {
      let reactions = currentProduct.reactions[0]
      let reactionData = JSON.parse(reactions.reactionData)
      addToBasketShopId = reactionData.ShopID
    }
  }

  return (
    <Row className='mb-3'>
      <Col>
        <div className='add-product'>
          <TrackableClick activityName='addToBasket'>
            <Button
              disabled={disableAddToBasket}
              onClick={() => {
                dispatch(
                  selectProduct({
                    shopId: addToBasketShopId,
                    productId: currentProduct.productId,
                    variationID: selectedVariation.VariationID,
                    enableSelectChristmasGift: christmasGiftSelectionModeEnabled
                  })
                )
                setIsProductModalopen(!isProductModalOpen)
              }}
              className='add-product-btn'
              id='add-christmas-product-button'
              data-cy='shop.addProductButton'
            >
              {basketIsLoading ? <FontAwesomeIcon icon='spinner' spin /> : addToBasketButtonText}
            </Button>
          </TrackableClick>
          {isProductModalOpen && (
            <ProductModal
              image={
                selectedVariation.attachments && selectedVariation.attachments.length ? selectedVariation.attachments[0] : currentProduct.images[0]
              }
              toggle={() => {
                setIsProductModalopen(!isProductModalOpen)
              }}
              productName={currentProduct.title}
              variationTitle={selectedVariation.variationTitle}
            />
          )}
        </div>
      </Col>
    </Row>
  )
}
