import React from 'react'
import Reflux from 'reflux'
import { Container, Col, Row } from 'reactstrap'
import { LoginStore } from 'Stores/loginStore'
import { FeatureStore, FeatureActions } from 'Stores/featureStore'

export default class ShopFeature extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [FeatureStore, LoginStore]
    this.storeKeys = ['shop', 'settings']
  }
  componentDidMount() {
    FeatureActions.getShop()
  }
  render() {
    if (!Object.keys(this.state.shop).length) {
      return null
    }
    let { totalWallet } = this.state.shop
    let { shopFeatureTitle, shopFeatureText } = this.state.settings
    return (
      <div className='santander-feature'>
        <div className='feature-overlay' />
        <div className='pt-5 pb-5'>
          <Container>
            <Col md='12'>
              <Row>
                <Col md='4' className='text-center'>
                  <table style={{ minHeight: '100%', minWidth: '100%' }}>
                    <tbody>
                      <tr>
                        <td valign='middle'>
                          <center>
                            <h6>SALDO</h6>
                            <h4>{String(totalWallet).replace(/(.)(?=(\d{3})+$)/g, '$1.')} point</h4>
                          </center>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md='8'>
                  <h1 className='featureTitle'>{shopFeatureTitle}</h1>
                  <p>{shopFeatureText}</p>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </div>
    )
  }
}
