import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'
import { GoudaClaimPointsActions } from 'Stores/goudaClaimPointsStore'

export default function GoudaClaimPointsError() {
  return (
    <>
      <h4 className='mb-3 text-center'>Der skete desværre en ukendt fejl</h4>
      <FontAwesomeIcon icon='exclamation-triangle' color='#ff3838' size='5x' style={{ display: 'block', margin: 'auto' }} />
      <Button
        className='standardBtn-black mt-4'
        style={{
          maxWidth: 250,
          display: 'block',
          margin: 'auto',
          fontWeight: 'bold'
        }}
        onClick={GoudaClaimPointsActions.resetForm}
      >
        Prøv igen
      </Button>
    </>
  )
}
