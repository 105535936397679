import { createSlice, createSelector } from '@reduxjs/toolkit'
import keyMirror from 'keymirror'
import Proxies from 'Components/proxies'
import { setLikedProductStatus, setUnLikeProductStatus, likeProductSuccess, unLikeProductSuccess } from 'Reducers/sharedActions/likeActions.js'
const stringSortCompareFunction = Intl.Collator('da', { numeric: true }).compare
import _ from 'lodash'
export const PRODUCTS_STATUS = keyMirror({
  LOADING_DATA: null,
  LOADING_DATA_SUCCESS: null,
  LOADING_DATA_FAILURE: null,
  CATALOG_EMPTY: null
})

const slice = createSlice({
  name: 'products',
  initialState: {
    status: null,
    activeFilters: {
      searchTerm: '',
      category: '',
      brands: []
    },
    sortSettings: {
      sortingFieldName: null,
      sortingDirection: null
    },
    products: []
  },
  reducers: {
    fetchProductsRequest: state => {
      state.status = PRODUCTS_STATUS.LOADING_DATA
    },
    fetchProductsSuccess: (state, action) => {
      state.products = action.payload
      state.status = PRODUCTS_STATUS.LOADING_DATA_SUCCESS
    },
    fetchProductsFailure: state => {
      state.status = PRODUCTS_STATUS.LOADING_DATA_FAILURE
    },
    fetchProductsCatalogEmpty: state => {
      state.status = PRODUCTS_STATUS.CATALOG_EMPTY
    },
    setSearchTerm: (state, action) => {
      state.activeFilters.searchTerm = action.payload
    },
    setCategory: (state, action) => {
      state.activeFilters.category = action.payload
    },
    addBrand: (state, action) => {
      state.activeFilters.brands.push(action.payload)
    },
    removeBrand: (state, action) => {
      state.activeFilters.brands = state.activeFilters.brands.filter(brand => brand !== action.payload)
    },
    setSorting: (state, action) => {
      state.sortSettings = {
        sortingFieldName: action.payload.sortingFieldName,
        sortingDirection: action.payload.sortingDirection
      }
    }
  },
  extraReducers: {
    [setLikedProductStatus]: (state, action) => {
      state.products.find(product => product.productId === action.payload.productId).likedReactionId = action.payload.reactionId
    },
    [setUnLikeProductStatus]: (state, action) => {
      state.products.find(product => product.productId === action.payload.productId).likedReactionId = null
    }
  }
})

// Private simple actions
const { fetchProductsRequest, fetchProductsSuccess, fetchProductsFailure, fetchProductsCatalogEmpty } = slice.actions

// Public simple actions
export const { setSearchTerm, setCategory, addBrand, removeBrand, setSorting } = slice.actions

// Export async actions
export const fetchProducts = shopID => (dispatch, getState) => {
  const { status } = getState().products
  if (status !== PRODUCTS_STATUS.LOADING_DATA) {
    dispatch(fetchProductsRequest())
    Proxies.shopElements(shopID).then(responseJSON => {
      if (responseJSON.error) {
        dispatch(fetchProductsFailure())
      } else if (!responseJSON.length) {
        dispatch(fetchProductsCatalogEmpty())
      } else {
        const productsWithAttachments = responseJSON.filter(line => {
          return line.attachments
        })

        const mappedProducts = productsWithAttachments.map(productWithAttacments => {
          const product = {
            productId: productWithAttacments.ProductID,
            title: productWithAttacments.elementTitle,
            description: productWithAttacments.elementContent,
            pricePoints: productWithAttacments.productPointComputed,
            poolOrder: productWithAttacments.poolOrder,
            brand: productWithAttacments.manufacturer,
            brandId: productWithAttacments.BrandID,
            images: [],
            likedReactionId: null,
            categoryName: '',
            flag: productWithAttacments.ProductCatalog && productWithAttacments.ProductCatalog.enableDiscount ? 'SALE' : productWithAttacments.flag,
            productStatus: productWithAttacments.productStatus,
            reactions: productWithAttacments.reactions,
            tags: [],
            enableDiscount: productWithAttacments.enableDiscount,
            discountPrice: productWithAttacments.discountPointComputed,
            categories: [],
            isOutOfStock: productWithAttacments.variations.reduce(
              (sum, variation) => sum && variation.variationStockable && variation.variationStock <= 0,
              true
            ),
            stockAvailableDate: productWithAttacments.stockAvailableDate,
            productStockLimit: productWithAttacments.productStockLimit
          }

          const wishlistReaction = (productWithAttacments.reactions || []).filter(x => x.reactionType.toLowerCase() === 'wishlist').pop()
          if (wishlistReaction) {
            product.likedReactionId = wishlistReaction.ReactionID
          }

          const productAttacments = productWithAttacments.attachments || []
          product.images = productAttacments.map(x => {
            const image = { imageName: x.imageName }
            if (x.imageOptimizedSourceSmall) {
              image.imageSmall = x.imageOptimizedSourceSmall
            }
            if (x.imageOptimizedSourceMedium) {
              image.imageMedium = x.imageOptimizedSourceMedium
            }
            if (x.imageOptimizedSource) {
              image.imageLarge = x.imageOptimizedSource
            }
            image.imageSrc = x.imageOriginSource
            return image
          })

          const productVariations = productWithAttacments.variations || []

          product.variations = productVariations.map(variation => {
            if (variation.attachments && variation.attachments.length) {
              let activeIndex = 0
              let variationImage = productWithAttacments.attachments.filter((attachment, index) => {
                if (attachment.ImageID === variation.attachments[0]) {
                  activeIndex = index
                  return attachment
                }
              })
              if (variationImage && variationImage.length && variationImage[0].ImageID === variation.attachments[0]) {
                variationImage = variationImage[0]
                let image = { imageName: variationImage.imageName }
                if (variationImage.imageOptimizedSourceSmall) {
                  image.imageSmall = variationImage.imageOptimizedSourceSmall
                }
                if (variationImage.imageOptimizedSourceMedium) {
                  image.imageMedium = variationImage.imageOptimizedSourceMedium
                }
                if (variationImage.imageOptimizedSource) {
                  image.imageLarge = variationImage.imageOptimizedSource
                }
                image.ImageID = variationImage.ImageID
                image.imageSrc = variationImage.imageOriginSource
                image.activeIndex = activeIndex
                variation.attachments = [image]
              }
            }
            return variation
          })

          const productCategories = productWithAttacments.categories || []
          productCategories.forEach(x => {
            const categoryName = x.categoryName.trim()

            switch (x.categoryTaxonomy.toLowerCase()) {
              // case 'manufacturer':
              //   product.brand = categoryName
              //   break
              case 'product':
                product.categoryName = categoryName
                product.categories.push({ categoryName: categoryName, imageURL: x.imageURL })
                break
              case 'tag':
                product.tags.push(categoryName)
                break
            }
          })

          return product
        })

        dispatch(fetchProductsSuccess(mappedProducts))
      }
    })
  }
}

export const likeProduct = (productId, UserID, BrandID, ShopID, tag) => dispatch => {
  Proxies.reaction('product', productId, 'wishlist', {
    UserID,
    BrandID,
    reactionData: ShopID ? JSON.stringify({ ShopID }) : JSON.stringify({ tag }),
    description: 'Fra web2'
  }).then(responseJSON => {
    if (!responseJSON.error) {
      dispatch(likeProductSuccess({ productId, reactionId: responseJSON.ReactionID }))
    }
  })
}

export const unLikeProduct = (productId, UserID, BrandID, likedReactionId) => dispatch => {
  Proxies.reactionRemove('product', productId, 'wishlist', likedReactionId, {
    removed: 1,
    UserID,
    BrandID,
    description: 'Fra web2'
  }).then(responseJSON => {
    if (!responseJSON.error) {
      dispatch(unLikeProductSuccess({ productId }))
    }
  })
}

// Export selectors
export const getStatus = state => state.products.status
export const getSearchTermFilter = state => state.products.activeFilters.searchTerm
export const getCategoryFilter = state => state.products.activeFilters.category
export const getBrandFilter = state => state.products.activeFilters.brands
export const getSortingSettings = state => state.products.sortSettings
export const getProducts = state => state.products.products
export const getActiveFilters = state => state.products.activeFilters

export const selectCurrentProduct = createSelector([(state, productId) => productId, getProducts], (productId, products) => {
  return products.find(product => product.productId === productId)
})

const selectSortedProducts = createSelector([getProducts, getSortingSettings], (products, settings) => {
  if (settings.sortingFieldName && settings.sortingDirection) {
    const sortingCopy = [...products]
    sortingCopy.sort((a, b) => {
      return stringSortCompareFunction(a[settings.sortingFieldName], b[settings.sortingFieldName])
    })
    if (settings.sortingDirection === 'desc') {
      sortingCopy.reverse()
    }
    return sortingCopy
  } else {
    return products
  }
})

export const selectCategories = createSelector([getProducts], products => {
  const categories = products.reduce((accumulatedCategories, product) => {
    const productCategory = product.categoryName
    if (productCategory && !accumulatedCategories.includes(productCategory)) {
      accumulatedCategories.push(productCategory)
    }
    return accumulatedCategories
  }, [])
  return categories.sort(stringSortCompareFunction)
})

export const selectBrands = createSelector([getProducts], products => {
  const brands = products.reduce((accumulatedBrands, product) => {
    const productBrand = product.brand
    if (!!productBrand && !accumulatedBrands.includes(productBrand)) {
      accumulatedBrands.push(productBrand)
    }
    return accumulatedBrands
  }, [])
  return brands.sort(stringSortCompareFunction)
})

export const getProductCategories = createSelector([getProducts], products => {
  const categoires = products.reduce((accumulatedCategories, product) => {
    const productCategory = product.categories
    if (productCategory && !!productCategory.length) {
      accumulatedCategories = accumulatedCategories.concat(productCategory)
    }
    return accumulatedCategories
  }, [])
  return _.uniqBy(categoires, 'categoryName')
})

export const selectFilteredProducts = createSelector(
  [selectSortedProducts, getSearchTermFilter, getCategoryFilter, getBrandFilter, (state, props) => props.ShopID],
  (products, searchTerm, selectedCategory, selectedBrands, activeShopId) => {
    return products.filter(product => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase()
      let matchesSearchTerm = true
      if (lowerCaseSearchTerm) {
        matchesSearchTerm =
          product.title.toLowerCase().includes(lowerCaseSearchTerm) ||
          product.description.toLowerCase().includes(lowerCaseSearchTerm) ||
          (product.brand && product.brand.toLowerCase().includes(lowerCaseSearchTerm))
      }

      let matchesCategory = true
      if (selectedCategory) {
        matchesCategory = product.categoryName === selectedCategory
      }

      let matchesBrandsFilter = true
      if (selectedBrands.length) {
        matchesBrandsFilter = selectedBrands.includes(product.brand)
      }

      let includeUnlikedProduct = true
      if (activeShopId === 'wishlist') {
        includeUnlikedProduct = product.likedReactionId !== null
      }

      return matchesSearchTerm && matchesCategory && matchesBrandsFilter && includeUnlikedProduct
    })
  }
)

export default slice.reducer
