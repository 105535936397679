/* eslint-disable eqeqeq */
import moment from 'moment'
import Reflux from 'reflux'
let thisVersion = 'ceta' //"20180829"
const versionRegExp = /^(alpha|beta|live|[0-9]{8})$/i
import _ from 'lodash'

/* API DATA */

const generateUrlOptions = () => {
  let urlBase = window.location.hostname.split('.')[0].toLowerCase()
  let options = ['api']
  let webVersion = 'LIVE'
  let hasVersion = false
  if (urlBase.indexOf('-') != -1) {
    let theseOptions = urlBase.split('-')
    theseOptions.shift()
    theseOptions.forEach(option => {
      if (webVersion === 'LIVE' && versionRegExp.test(option)) {
        webVersion = option
      } else if (webVersion !== 'LIVE' && versionRegExp.test(option)) {
        options.push(option)
        hasVersion = true
      } else {
        options.push(option)
      }
    })
  }
  if (!hasVersion) {
    options.push(thisVersion)
  }
  return options
}

const urlOptions = generateUrlOptions()

const cleanAPIData = data => {
  /* eslint-disable-next-line */
  if (data.hasOwnProperty('status') && data.hasOwnProperty('package')) {
    data = data.package
  } else if (Object.keys(data) == 1 && !Array.isArray(data)) {
    data = data[Object.keys(data)[0]]
  }
  return data
}

/* API 2 */

const generateAPI2URL = () => {
  if (process.env.API_TARGET) return process.env.API_TARGET

  // if (process.env.ENV_TARGET === 'local') {
  //   return 'http://localhost:9090/api/'
  // } else if (process.env.ENV_TARGET === 'development') {
  //   return 'http://dev.upgraderapi.dk/api/'
  // }
  return 'https://' + urlOptions.join('-') + '.upgraderapi.dk/'
}

const API2URL = generateAPI2URL()

const _fetch = (route, method, body, isQuery = false) => {
  if (!route) throw new Error('Route is undefined')
  let fullRoute = API2URL + route
  let query = ''
  let opts = {
    method,
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
  }
  if (localStorage.getItem('session')) {
    if (body) {
      body.access_token = localStorage.getItem('session')
    } else {
      body = { access_token: localStorage.getItem('session') }
    }
    query = { access_token: localStorage.getItem('session') }
    opts.headers.access_token = localStorage.getItem('session')
  }
  let qs = require('qs')
  if (isQuery && body) {
    query = qs.stringify(body)
    body = undefined
  } else {
    query = qs.stringify(query)
  }
  fullRoute = fullRoute + '?' + query
  if (body) {
    Object.assign(opts, { body: JSON.stringify(body) })
  }
  return fetch(fullRoute, opts)
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        return response.json().then(responseJSON => {
          const errorMessage = _.get(responseJSON, 'error.message')
          if (
            errorMessage === 'invalid session' ||
            errorMessage === 'Access denied' ||
            errorMessage === 'SESSION_INVALID' ||
            errorMessage === 'INVALID_SESSION' ||
            errorMessage === 'Access Denied' ||
            errorMessage === 'Missing session' ||
            errorMessage === 'SESSION_UNKNOWN' ||
            errorMessage === 'INVALID_TOKEN' ||
            errorMessage === 'Invalid Access Token'
          ) {
            // Try to make sure users with invalid sessions are booted out,
            // checking multiple messages due to inconsistent API implementation
            location.href = '/logout'
          }
          if (errorMessage) responseJSON.error.message = translateErrorMessage(errorMessage)
          return responseJSON
        })
      }
    })
    .then(responseJSON => {
      return cleanAPIData(responseJSON)
    })
}

function translateErrorMessage(errorMessage) {
  const { translations } = Reflux.GlobalState.LoginStore.settings
  const hasTranslations = !!_.get(translations, 'length')

  if (hasTranslations) {
    const match = translations.filter(trans => trans.translationKey === '_' + errorMessage + '_' || trans.translationKey === errorMessage)
    if (match.length) {
      return match[0].translationValue
    }
  }
  return errorMessage
}

const GET = (route, query) => {
  return _fetch(route, 'GET', query, true)
}
const POST = (route, body) => {
  return _fetch(route, 'POST', body)
}
const PUT = (route, body) => {
  return _fetch(route, 'PUT', body)
}
const DELETE = (route, query) => {
  return _fetch(route, 'DELETE', query, true)
}

/* API 1 */
const baseURL = generateAPI2URL()

const _getJSON = (action, JSONpackage = {}, offSite = false, useActivity = false, isErrorReport = false, useFormData = false) => {
  let url = baseURL + action
  let body = null
  if (useFormData) {
    body = new FormData()
    Object.keys(JSONpackage).forEach(key => {
      if (Array.isArray(JSONpackage[key])) {
        let arr = JSONpackage[key]
        arr.forEach(value => {
          body.append(key + '[]', value)
        })
      } else {
        body.append(key, JSONpackage[key])
      }
    })
    if (!isErrorReport) {
      localStorage.getItem('session') ? body.append('token', localStorage.getItem('session')) : {}
      body.append('magickey', 'Pretty Please')
      body.append('JacobErGud', 'false')
    }
  } else {
    if (!isErrorReport) {
      localStorage.getItem('session') ? (JSONpackage.token = localStorage.getItem('session')) : {}
      JSONpackage.magickey = 'Pretty Please'
      JSONpackage.JacobErGud = 'false'
    }
  }
  let activity = {
    method: 'POST',
    body: body ? body : JSON.stringify(JSONpackage)
  }
  if (offSite) {
    url = action
    if (useActivity == false) {
      activity = null
    }
  }
  return fetch(url, activity)
    .then(response => {
      return response.text()
    })
    .then(responseText => {
      try {
        let responseJSON = JSON.parse(responseText)
        if (responseJSON.status < 0) {
          if (offSite) {
            return responseJSON
          } else {
            if (!isErrorReport) {
              _postErrorReport(url, JSONpackage, responseText)
            }
            return false
          }
        } else if (responseJSON.status == 0 && responseJSON.privacy > 0) {
          alert(responseJSON.package.message ? responseJSON.package.message : responseJSON.message)
          return cleanAPIData(responseJSON)
        } else {
          return cleanAPIData(responseJSON)
        }
      } catch (e) {
        if (!isErrorReport) {
          _postErrorReport(url, JSONpackage, responseText)
        }
        return false
      }
    })
}

const _postErrorReport = () => {
  //if(!__DEV__) {
  //	_getJSON("https://api.upgrader.club/"+'system/log', {url:url,data:dataPackage,response:response}, true, true, true)
  //}
}

/* DATA */

const wrapFilter = (filter = null) => {
  if (filter) {
    filter = { where: filter }
    filter = JSON.stringify(filter)
    filter = { filter: filter }
  }
  return filter
}

let Proxies = { GET, POST, PUT, DELETE, wrapFilter, _getJSON }

// session stuff //
;(() => {
  // Asking for a key providing a phone number
  const sessionGrant = phoneNumber => {
    let BrandID = localStorage.getItem('BrandID') ? localStorage.getItem('BrandID') : 8
    if (!isNaN(BrandID)) {
      BrandID = parseInt(BrandID, 10)
    }
    return POST('sessions/grant', { BrandID, userPhone: phoneNumber })
  }
  Object.assign(Proxies, { sessionGrant })

  // Upon providing the key a session is created
  const sessionAccess = (phoneNumber, token) => {
    let BrandID = localStorage.getItem('BrandID') ? localStorage.getItem('BrandID') : 8
    if (!isNaN(BrandID)) {
      BrandID = parseInt(BrandID, 10)
    }
    let dato = new Date()
    if (
      !Date()
        .toString()
        .match(/\((.*)\)/)
    ) {
      dato = moment(dato.toISOString()).format('ZZ')
    } else {
      dato = Date()
        .toString()
        .match(/\((.*)\)/)
        .pop()
    }
    return POST('sessions/access', {
      BrandID,
      userPhone: phoneNumber,
      accessKey: token,
      deviceType: 'WEB2',
      deviceAgentName: 'reactJS',
      deviceAgentVersion: '1.0.0',
      deviceTimezone: dato,
      deviceLanguage: window.navigator.language,
      deviceToken: ''
    })
  }
  Object.assign(Proxies, { sessionAccess })

  // Signing out
  const sessionClear = () => {
    return POST('sessions/clear')
  }
  Object.assign(Proxies, { sessionClear })

  // Signing out
  const sessionValidate = () => {
    return GET('sessions/validate')
  }
  Object.assign(Proxies, { sessionValidate })

  const verifyAccessTerms = token => {
    return POST('profile/accept', { token: token })
  }
  Object.assign(Proxies, { verifyAccessTerms })

  const ssoValidate = (JSONpackage = {}) => {
    return GET('sessions/validate', JSONpackage, false, false, true)
  }
  Object.assign(Proxies, { ssoValidate })

  const loadConsent = (filter = null) => {
    if (filter) {
      filter = wrapFilter(filter)
    }
    return GET('consents', filter)
  }
  Object.assign(Proxies, { loadConsent })

  const saveConsent = data => {
    return POST('consents', data)
  }
  Object.assign(Proxies, { saveConsent })
})()

// hall of fame stuff //
;(() => {
  Object.assign(Proxies, {
    halloffamePool: () => GET('halloffame/pool'),
    halloffameElements: HalloffameID => GET('halloffame/elements', { HalloffameID }),
    halloffameRemove: HalloffameID => POST('/halloffame/remove', { HalloffameID })
  })
})()

// competition stuff //
;(() => {
  Object.assign(Proxies, {
    competitionMine: () => GET('competitions/me'),
    competitionFetch: CompetitionID => GET('competitions/' + CompetitionID),
    competitionCreate: data => POST('competitions/replace', data),
    competitionUpdate: data => POST('competitions/replace', data),
    competitionRemove: CompetitionID => POST('competitions/remove', { CompetitionID }),
    competitionProcess: CompetitionID => GET('competitions/process', { CompetitionID: CompetitionID }),
    getChallenges: (filter = null) => GET('challenges', filter ? wrapFilter(filter) : filter),
    competitionAdversaries: CompetitionID => GET('competitions/adversaries', { CompetitionID: CompetitionID }),
    challengeMe: CompetitionID => GET('challenges/me', { CompetitionID }),
    challengeAccept: ChallengeID => POST('challenges/accept', { ChallengeID }),
    challengeReject: ChallengeID => POST('challenges/decline', { ChallengeID }),
    challengeRemove: data => PUT('challenges', Object.assign(data, { removed: 1 })),
    challengeCreate: (CompetitionID, TargetUserID) =>
      POST('competitions/challenge', {
        CompetitionID,
        TargetUserID: parseInt(TargetUserID)
      }),
    postComment: data => POST('comments', data),
    getComments: (filter = null) => GET('comments', filter ? wrapFilter(filter) : filter)
  })
})()

/* CPR */
;(() => {
  const getUserExistsCPR = () => {
    return GET('datavaults/existsCPR')
  }
  Object.assign(Proxies, { getUserExistsCPR, saveCPR: data => POST('datavaults/createCPR', data) })
})()

/* menu */
;(() => {
  const menuPool = () => {
    return GET('menu')
  }
  Object.assign(Proxies, { menuPool })
})()

/* announcement */
;(() => {
  const announcements = () => {
    return GET('announcements/web-pool')
  }
  Object.assign(Proxies, { announcements })
})()

/* Shop related*/
;(() => {
  const shopPool = () => {
    return GET('shops')
  }
  Object.assign(Proxies, { shopPool })

  const shopElements = ShopID => {
    return GET('shops/' + ShopID)
  }
  Object.assign(Proxies, { shopElements })

  const getBasket = () => {
    return GET('baskets')
  }
  Object.assign(Proxies, { getBasket })

  const addToBasket = data => {
    return PUT('baskets', data)
  }
  Object.assign(Proxies, { addToBasket })

  const removeFromBasket = data => {
    return PUT('baskets', data)
  }
  Object.assign(Proxies, { removeFromBasket })

  Object.assign(Proxies, { placeOrder: data => POST('shops/submit', data) })
})()

/* blog related */
;(() => {
  const blogPool = () => {
    return GET('blogs')
  }
  Object.assign(Proxies, { blogPool })

  const blogElements = BlogID => {
    return GET('blogs/' + BlogID)
  }
  Object.assign(Proxies, { blogElements })
})()

/* tag related */
;(() => {
  const getTagCategories = () => {
    return GET('products/tag-categories')
  }
  Object.assign(Proxies, { getTagCategories })
})()

/* profile related */
;(() => {
  Object.assign(Proxies, {
    profileData: () => GET('profile/data'),
    profileUpdate: data => POST('profile/update', data),
    profileOrders: () => GET('sessions/orders'),
    deleteOrder: data => POST('orders/userDeleteOrder', data),
    profileCampaigns: type => GET('Campaigns/list', { type }),
    getUserCampaignDetails: CampaignID => GET('Campaigns/fetchUserCampaignInfo', { CampaignID }),
    profilePoints: () => GET('user/points'),
    walletTransactions: () => GET('sessions/wallet'),
    sendTaxEmail: data => POST('profile/tax', data),
    notifications: () => GET('communications/me'),
    timeline: () => GET('user/timeline'),
    repayLoan: data => POST('wallets/repay', data),
    sendPoints: data => POST('wallets/send', data),
    goudaClaimPoints: data => POST('wallets/claim-points', data),
    customerSalesClaim: data => POST('CustomerSalesClaims/replace', data),
    customerProducts: () => GET('CustomerSalesRewards/reward-products'),
    uploadImage: data => POST('image/upload', data),
    registerUser: data => POST('user/register', data),
    userSeasonOrders: ShopID => GET('orders/exists-season-order', { ShopID }),
    transferBudgetPoints: data => POST('wallets//sendBudgetPoints', data),
    checkGroupAccess: data => GET('groups/access', data),
    getAllSubordinateUsersOfBrand: data => GET('supervisors/getAllSubordinateUsersOfBrand', data),
    getBudgetPointsBalance: () => GET('wallets/getBudgetPointsBalance'),
    getKamsInBrand: data => GET('supervisors/kamsInBrand', data),
    getDepartmentsInBrand: data => GET('departments/brandDepartments', data)
  })
})()

/* Feature elements */
;(() => {
  Object.assign(Proxies, {
    profileFeature: () => GET('feature/profile'),
    blogFeature: () => GET('feature/blog'),
    shopFeature: () => GET('feature/shop'),
    hofFeature: () => GET('feature/hof')
  })
})()
;(() => {})()

/* odd stuffs */
;(() => {
  const activity = (activityType, activityName, activityArgumentID, activityArgument = '', data = {}) => {
    /* eslint-disable-next-line */
    if (!data.hasOwnProperty('instanceKey')) {
      Object.assign(data, { instanceKey: sessionStorage.instanceKey })
    }
    Object.assign(data, {
      activityType: activityType,
      activityName: activityName,
      activityArgumentID: activityArgumentID,
      activityArgument: activityArgument,
      activityController: 'WEB2'
    })
    return POST('activity', data)
  }
  Object.assign(Proxies, { activity })
})()

/* reactions */
;(() => {
  const reaction = (reactionTargetType, ReactionTargetID, reactionType, data = {}) => {
    Proxies.activity(reactionType, reactionTargetType, ReactionTargetID)
    Object.assign(data, {
      reactionTargetType: reactionTargetType,
      ReactionTargetID: ReactionTargetID,
      reactionType: reactionType
    })
    return POST('reactions', data)
  }
  Object.assign(Proxies, { reaction })

  const reactionRemove = (reactionTargetType, ReactionTargetID, reactionType, ReactionID, data = {}) => {
    Proxies.activity('un' + reactionType, reactionTargetType, ReactionTargetID)
    Object.assign(
      data,
      {
        reactionTargetType: reactionTargetType,
        ReactionTargetID: ReactionTargetID,
        reactionType: reactionType,
        ReactionID: ReactionID
      },
      { removed: 1 }
    )
    return PUT('reactions/' + ReactionID, data)
  }
  Object.assign(Proxies, { reactionRemove })
})()

/* brand info */
;(() => {
  const setupBrand = () => {
    return GET('setup/brands')
  }
  Object.assign(Proxies, { setupBrand })
})()

// Post data to the API
const postData = (endpoint = false, data = {}, useFormData = false, test = false) => {
  if (!endpoint) {
    return false
  }
  if (test) {
    return _getJSON('https://util.upgrader.club/' + endpoint, data, true, true, true, useFormData).then(responseJSON => {
      return responseJSON
    })
  }
  return _getJSON(endpoint, data, false, false, false, useFormData)
}
Object.assign(Proxies, { postData })

// Get Proxies data (only JSON)
const apiProxy = url => {
  if (!url) {
    return false
  }
  return _getJSON(url, {}, true)
}
Object.assign(Proxies, { apiProxy })

const platformSetup = data => {
  return GET('platform/setup', data)
}
Object.assign(Proxies, { platformSetup })

export default Proxies
