import React from 'react'
import { useSelector } from 'react-redux'
import { Row } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { selectFilteredProducts } from 'Reducers/productsSlice.js'
import Product from 'Modules/shop/shared/Product.js'
import ProductSort from 'Modules/shop/shopPage/ProductSort.js'
import { PersonalShopperBanner } from 'Modules/shop/shopPage/PersonalShopper.js'
import Reflux from 'reflux'
import { LoginStore } from 'Stores/loginStore'
import _ from 'lodash'
import { MenuStore } from 'Stores/menuStore'

export default class RefluxWrap extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore, MenuStore]
    this.storeKeys = ['settings', 'tagFilterValue']
  }

  render() {
    return <ProductList props={this.props} tagFilterValue={this.state.tagFilterValue} settings={this.state.settings} />
  }
}

function ProductList({ settings, tagFilterValue, props }) {
  const { ShopID } = useParams()
  let products = useSelector(state => {
    return selectFilteredProducts(state, { ShopID })
  })
  if (tagFilterValue && settings && settings.features && settings.features.enableCategoryTag) {
    products = products.filter(product => product.tags.includes(tagFilterValue))
  }

  return (
    <>
      {!_.get(settings, 'features.shop.disableProductSorting') && <ProductSort />}
      <Row className='mx-2'>
        {products.map((product, i) => (
          <Product {...props} key={i} product={product} shopId={ShopID} />
        ))}
      </Row>
      <Row>
        <PersonalShopperBanner />
      </Row>
    </>
  )
}
