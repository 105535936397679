import Reflux from 'reflux'
import Proxies from 'Components/proxies'

const HofActions = Reflux.createActions(['getHofs', 'getHofElements'])

class HofStore extends Reflux.Store {
  constructor() {
    super()
    this.listenables = HofActions
    this.state = {
      hofs: [],
      currentHofId: null,
      currentHof: null,
      isLoading: false
    }
  }

  getHofs() {
    this.setState(() => ({ hofs: [], isLoading: true }))
    Proxies.halloffamePool().then(responseJSON => {
      if (!Array.isArray(responseJSON)) {
        if (responseJSON.exception) {
          responseJSON = []
        } else {
          responseJSON = [responseJSON]
        }
      }
      let responsePackage = responseJSON.sort((a, b) => {
        return a['poolOrder'] - b['poolOrder']
      })
      let hofs = [].concat(responsePackage || [])

      this.setState({ hofs, isLoading: false })
    })
  }

  getHofElements(HalloffameID) {
    if (!HalloffameID) return

    this.setState({
      currentHofId: HalloffameID,
      currentHof: null,
      isLoading: true
    })
    Proxies.halloffameElements(HalloffameID).then(responseJSON => {
      this.setState({ currentHof: responseJSON, isLoading: false })
    })
  }
}

export { HofActions, HofStore }
