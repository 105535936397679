import React from 'react'
import Reflux from 'reflux'
import _ from 'lodash'
import { LoginStore } from 'Stores/loginStore'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

const StyledWrapper = styled.div`
  background-size: cover;
  background-image: ${props => (props.backgroundImage ? `url(${props.backgroundImage})` : '')};
  color: ${props => props.textColor || 'white'};

  @media print {
    display: none;
  }
`

const StyledBackgroundColor = styled.div`
  background-color: ${props => props.backgroundColor || ''};
  min-height: 370px;
`

const StyledTextContainer = styled.p`
  font-size: 14px;
`

const StyledMaxWidthCol = styled(Col)`
  max-width: 300px;
`

const StyledAspectRatioImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`

const StyledAspectRatioImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default class TextAndRightImageShopFeature extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }

  render() {
    const shopId = _.get(this.props, 'match.params.id')
    const featureConfig = _.get(this.state, `settings.features.textAndRightImageShopFeature.${shopId}`)
    if (!featureConfig) return null

    return (
      <StyledWrapper backgroundImage={featureConfig.backgroundImage} textColor={featureConfig.textColor}>
        <StyledBackgroundColor backgroundColor={featureConfig.backgroundColor}>
          <Container className='py-4 py-md-5'>
            <Row className='justify-content-center justify-content-md-between'>
              <Col md='6' lg='7' xl='8' className='mb-4 mb-md-0'>
                <StyledTextContainer>
                  <span dangerouslySetInnerHTML={{ __html: featureConfig.text }}></span>
                </StyledTextContainer>
              </Col>
              <StyledMaxWidthCol md='5' lg='4' className='text-center'>
                {!!featureConfig.image && (
                  <StyledAspectRatioImageWrapper>
                    <StyledAspectRatioImage src={featureConfig.image} />
                  </StyledAspectRatioImageWrapper>
                )}
              </StyledMaxWidthCol>
            </Row>
          </Container>
        </StyledBackgroundColor>
      </StyledWrapper>
    )
  }
}
