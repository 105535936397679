/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import Moment from 'react-moment'
import strip_tags from 'Components/striptags'
import { Container, Row, Col, Button, Input, Label, Alert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OutsideClickListener from 'Components/OutsideClickListener'
import { ProfileActions, ProfileStore } from 'Stores/profileStore'
import { LoginStore } from 'Stores/loginStore'
import PointsExpirationSpinner from './PointsExpirationSpinner'
import _ from 'lodash'
import ProfileMenu from 'Modules/profile/ProfileMenu.js'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Select from 'react-select'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment'

export default class Points extends Component {
  constructor(props) {
    super(props)
    this.stores = [ProfileStore, LoginStore]
    this.storeKeys = ['walletTransactions', 'pointsSummary', 'isOpenSendPointsModal', 'settings', 'sendPointsGroupAccess']
  }

  componentDidMount() {
    ProfileActions.getPoints()
    ProfileActions.getWalletTransactions()
    ProfileActions.checkGroupAccess(this.state.settings && this.state.settings.features ? this.state.settings.features.sendBudgetPointsGroup : null)
    ProfileActions.getBudgetPointsBalance()
    ProfileActions.getAllSubordinateUsersOfBrand()
  }

  render() {
    const { walletTransactions, settings } = this.state
    const { running_out, running_out_3m, running_out_6m, running_out_beyond_6m } = this.state.pointsSummary
    const canSendPoints = !!(
      this.state.settings &&
      this.state.settings.features &&
      this.state.settings.features.sendBudgetPoints &&
      this.state.sendPointsGroupAccess
    )
    const saveExcel = () => {
      if (walletTransactions.length) {
        const workbook = new Excel.Workbook()
        const workSheetName = 'Point data'
        try {
          const { brandPointRatio } = settings
          const worksheet = workbook.addWorksheet(workSheetName)

          const columns = canSendPoints
            ? [
                { header: 'Note', key: 'walletNote', width: 50 },
                { header: 'Point', key: 'walletAmountPoints', width: 10 },
                { header: 'DKK', key: 'walletAmountPointsDKK', width: 15 },
                // { header: 'Sum', key: 'currentWalletSum', width: 10 },
                // { header: 'Sum_DKK', key: 'currentWalletSumDKK', width: 15 },
                { header: 'Dato', key: 'timeInsert', width: 20 },
                { header: 'Navn', key: 'receiverName', width: 20 },
                { header: 'Email', key: 'receiverEmail', width: 20 },
                { header: 'UID', key: 'receiverUID', width: 15 }
              ]
            : [
                { header: 'Note', key: 'walletNote', width: 50 },
                { header: 'Point', key: 'walletAmountPoints', width: 10 },
                { header: 'DKK', key: 'walletAmountPointsDKK', width: 15 },
                // { header: 'Sum', key: 'currentWalletSum', width: 10 },
                // { header: 'Sum_DKK', key: 'currentWalletSumDKK', width: 15 },
                { header: 'Dato', key: 'timeInsert', width: 20 }
              ]

          const data = canSendPoints
            ? walletTransactions.map(x => {
                return {
                  walletNote: x.walletNote,
                  walletAmountPoints: x.walletAmountPoints,
                  walletAmountPointsDKK: (x.walletAmountPoints / brandPointRatio).toFixed(2),
                  // currentWalletSum: x.currentWalletSum,
                  // currentWalletSumDKK: x.currentWalletSum * brandPointRatio,
                  timeInsert: moment(x.timeInsert).format('DD/MM/YYYY'),
                  receiverName: x.receiverName,
                  receiverEmail: x.receiverEmail,
                  receiverUID: x.receiverUID
                }
              })
            : walletTransactions.map(x => {
                return {
                  walletNote: x.walletNote,
                  walletAmountPoints: x.walletAmountPoints,
                  walletAmountPointsDKK: (x.walletAmountPoints / brandPointRatio).toFixed(2),
                  // currentWalletSum: x.currentWalletSum,
                  // currentWalletSumDKK: x.currentWalletSum * brandPointRatio,
                  timeInsert: moment(x.timeInsert).format('DD/MM/YYYY')
                }
              })

          worksheet.columns = columns
          worksheet.getRow(1).font = { bold: true }
          // worksheet.columns.forEach(column => {
          // column.width = column.header.length + 5;
          // column.alignment = { horizontal: 'center' };
          // });
          data.forEach(singleData => {
            worksheet.addRow(singleData)
          })
          workbook.xlsx.writeBuffer().then(res => {
            saveAs(new Blob([res]), `Point_data.xlsx`)
          })
        } catch (error) {
          // console.error('<<<ERRROR>>>', error);
        } finally {
          workbook.removeWorksheet(workSheetName)
        }
      }
    }
    return (
      <React.Fragment>
        <ProfileMenu />
        <Container className='container-safe-height'>
          <div>
            <div className='clearfix'>
              <div className='float-right SendAndLoanPoints'>
                <SendAndLoanPoints />
              </div>
            </div>
            {canSendPoints && (
              <>
                <Row style={{ marginBottom: '10px' }}>
                  <div className='col-xs-12 col-md-6 col-lg-10'></div>
                  <div className='col-xs-12 col-md-6 col-lg-2'>
                    <Button className='float-right  standardBtn-black onboard-sendpoints' onClick={ProfileActions.toggleSendPointsModal}>
                      Send point
                    </Button>
                  </div>
                </Row>
                {this.state.isOpenSendPointsModal && <SendPointsModal isOpenModal={this.state.isOpenSendPointsModal} />}
              </>
            )}
            {!(this.state && this.state.settings && this.state.settings.features && this.state.settings.features.hidePointsExpiry) && (
              <Row>
                <div className='col-xs-12 col-md-6 col-lg-3'>
                  <PointsExpirationSpinner color='#4f9e5e' description='Udløber om mere end 6 måneder' points={running_out_beyond_6m} />
                </div>
                <div className='col-xs-12 col-md-6 col-lg-3'>
                  <PointsExpirationSpinner color='#efe07b' description='Udløber om 6 måneder' points={running_out_6m} />
                </div>
                <div className='col-xs-12 col-md-6 col-lg-3'>
                  <PointsExpirationSpinner color='#d13d3d' description='Udløber om 3 måneder' points={running_out_3m} />
                </div>
                <div className='col-xs-12 col-md-6 col-lg-3'>
                  <PointsExpirationSpinner color='#070707' description='Udløber i denne måned' points={running_out} />
                </div>
              </Row>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '2rem 0' }}>
              <Button style={{ maxWidth: 300 }} className='standardBtn-black btn btn-secondary' onClick={saveExcel}>
                Eksportér din point oversigt til Excel
              </Button>
            </div>
            {canSendPoints ? (
              <>
                <Row className='pb-1 pt-4'>
                  <Col xs='3'>
                    <p className='font-weight-bold'>Note</p>
                  </Col>
                  <Col xs='1'>
                    <p className='font-weight-bold'>Point</p>
                  </Col>
                  <Col xs='1'>
                    <p className='font-weight-bold'>Sum</p>
                  </Col>
                  <Col xs='1'>
                    <p className='font-weight-bold'>Dato</p>
                  </Col>
                  <Col xs='2'>
                    <p className='font-weight-bold'>Navn</p>
                  </Col>
                  <Col xs='2'>
                    <p className='font-weight-bold'>Email</p>
                  </Col>
                  <Col xs='2'>
                    <p className='font-weight-bold'>UID</p>
                  </Col>
                </Row>
                {(walletTransactions || []).map((x, i) => (
                  <Row className='wg-row' key={i}>
                    <Col xs='3'>{x.walletNote}</Col>
                    <Col xs='1'>{x.walletAmountPoints}</Col>
                    <Col xs='1'>{x.currentWalletSum}</Col>
                    <Col xs='1'>
                      <Moment format='DD/MM/YYYY'>{x.timeUpdate}</Moment>
                    </Col>

                    <Col xs='2'>{x.receiverName}</Col>
                    <Col xs='2'>{x.receiverEmail}</Col>
                    <Col xs='2'>{x.receiverUID}</Col>
                  </Row>
                ))}
              </>
            ) : (
              <>
                <Row className='pb-1 pt-4'>
                  <Col xs='5'>
                    <p className='font-weight-bold'>Note</p>
                  </Col>
                  <Col xs='2'>
                    <p className='font-weight-bold'>Point</p>
                  </Col>
                  <Col xs='2'>
                    <p className='font-weight-bold'>Sum</p>
                  </Col>
                  <Col xs='3'>
                    <p className='font-weight-bold text-right'>Dato</p>
                  </Col>
                </Row>
                {(walletTransactions || []).map((x, i) => (
                  <Row className='wg-row' key={i}>
                    <Col xs='5'>{x.walletNote}</Col>
                    <Col xs='2'>{x.walletAmountPoints}</Col>
                    <Col xs='2'>{x.currentWalletSum}</Col>
                    <Col xs='3' className='text-right'>
                      <Moment format='DD/MM/YYYY'>{x.timeUpdate}</Moment>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

class SendAndLoanPoints extends Component {
  constructor(props) {
    super(props)
    this.stores = [ProfileStore, LoginStore]
    this.storeKeys = ['sendPoints', 'settings']

    this.defaultState = {
      show: false,
      isAwaitingResponse: false,
      sendPointsResponse: null,
      emailOrPhone: '',
      amountOfPoints: '',
      isLoan: false
    }
    this.state = this.defaultState

    ProfileActions.sendPoints.preEmit = () => this.setState({ isAwaitingResponse: true })
    ProfileActions.sendPoints.rejected.listen(sendPointsResponse => this.setState({ isAwaitingResponse: false, sendPointsResponse }))
    ProfileActions.sendPoints.completed.listen(() => this.setState(this.defaultState))
    this.errorMessage = null
  }

  shouldDisableButton = () => {
    const isValidForm = () => {
      const { emailOrPhone, amountOfPoints } = this.state
      const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const phoneReg = /^[\d +]{8,13}$/
      const acceptableAmount = amountOfPoints !== '' && parseInt(amountOfPoints) > 0
      return acceptableAmount && (phoneReg.test(String(emailOrPhone).toLowerCase()) || emailReg.test(String(emailOrPhone).toLowerCase()))
    }
    return this.state.isAwaitingResponse || this.state.sendPointsResponse !== null || !isValidForm()
  }

  toggle = () => {
    this.setState(state => ({ show: !state.show }))
  }

  closePopup = () => {
    this.setState({
      show: false,
      isAwaitingResponse: false,
      sendPointsResponse: null,
      emailOrPhone: '',
      amountOfPoints: 0,
      isLoan: false
    })
  }

  handleEmailOrPhoneChange = e => {
    this.setState({
      emailOrPhone: strip_tags(e.target.value),
      sendPointsResponse: null
    })
  }

  handlePointChange = e => {
    let { valueAsNumber, validity } = e.target

    if (!validity.valid) return

    this.setState(() => ({
      amountOfPoints: valueAsNumber,
      sendPointsResponse: null
    }))
  }

  handleConfirmClick = () => {
    const { isLoan, emailOrPhone, amountOfPoints } = this.state

    ProfileActions.sendPoints(emailOrPhone, amountOfPoints, isLoan)
    this.setState(() => ({ isAwaitingResponse: true }))
  }

  render() {
    if (!(this.state.settings && this.state.settings.features && this.state.settings.features.SendPoints)) {
      return null
    }
    const { sendPointsResponse, show, isLoan, emailOrPhone, amountOfPoints, isAwaitingResponse } = this.state
    const classes = {
      display: show ? 'block' : 'none',
      position: 'absolute',
      backgroundColor: 'white',
      border: 'solid 0px gray',
      zIndex: 10,
      minWidth: '400px'
    }

    const showMissingUserAlert = () => {
      if (sendPointsResponse && sendPointsResponse.error && sendPointsResponse.error.message) {
        this.errorMessage = sendPointsResponse.error.message
        return true
      } else {
        return false
      }
    }
    const showSuccessAlert = () => sendPointsResponse && !sendPointsResponse.error

    return (
      <OutsideClickListener onOutsideClick={() => this.closePopup()} onInsideClick={_.noop}>
        <div className='btn-group dropdown open'>
          <Button className='standardBtn-black dropdown-toggle onboard-sendpoints' onClick={this.toggle}>
            Send/lån point
          </Button>

          <ul className='dropdown-menu dropdown-menu-right dropdown-animation shadow-2 mt-1' style={classes}>
            <li className='pt-3 pb-2 pr-3 pl-3'>
              <InfoToast />
              <p className='font-weight-bold'>Send eller udlån point</p>
              <div className='checkbox'>
                <input
                  id='checkbox_1'
                  type='checkbox'
                  style={{ marginLeft: '0px' }}
                  onChange={() => this.setState(state => ({ isLoan: !state.isLoan }))}
                  checked={isLoan}
                />
                <Label for='checkbox_1'>
                  <p>Send point som lån</p>
                </Label>
              </div>
              <p>
                Sender du point som et lån, vil pointene blive ført tilbage til din konto så snart der er dækning på modtagerens konto. Såfremt at du
                ikke vælger <strong>Send som lån</strong>, anses dette som en gave.
              </p>

              <Alert color='danger' isOpen={showMissingUserAlert()}>
                <div className='clearfix'>
                  <div className='float-left'>{this.errorMessage}</div>
                  <div className='float-right'>
                    <a className='font-weight-bold' onClick={() => this.setState({ sendPointsResponse: null })}>
                      Luk <FontAwesomeIcon icon='times' />
                    </a>
                  </div>
                </div>
              </Alert>
              <Alert isOpen={showSuccessAlert()}>
                Overførslen er gennemført{' '}
                <Button
                  onClick={() => {
                    this.closePopup()
                    ProfileActions.getPoints()
                    ProfileActions.getWalletTransactions()
                  }}
                >
                  Luk
                </Button>
              </Alert>

              {(showMissingUserAlert() || sendPointsResponse == null) && (
                <React.Fragment>
                  <p className='font-weight-bold' style={{ margin: '0px' }}>
                    Modtagers navn eller e-mail:
                  </p>
                  <Input
                    className='standard-input mb-2'
                    type='text'
                    placeholder='e-mail eller navn'
                    value={emailOrPhone}
                    onChange={this.handleEmailOrPhoneChange}
                  />
                  <p className='font-weight-bold' style={{ margin: '0px' }}>
                    Antal point:
                  </p>
                  <Input
                    className='standard-input mb-3'
                    type='number'
                    min='1'
                    pattern='[0-9]*'
                    placeholder='f.eks. 1523'
                    value={amountOfPoints}
                    onChange={this.handlePointChange}
                  />

                  <Button className='standardBtn-black' disabled={this.shouldDisableButton()} onClick={this.handleConfirmClick}>
                    {isAwaitingResponse ? 'Sender...' : 'Godkend'}
                  </Button>
                </React.Fragment>
              )}
            </li>
          </ul>
        </div>
      </OutsideClickListener>
    )
  }
}

class InfoToast extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      show: !this.state.show
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.show && (
          <div className='alert alert-secondary alert-dismissible fade show' role='alert'>
            <p style={{ margin: '0px' }}>
              {this.props.message
                ? this.props.message
                : 'Brug formularen til at sende eller låne point til en kollega. Marker &quot;Send point som lån&quot; for at din kollega skal betale' +
                  'pointne tilbage.'}
            </p>
            <button type='button' className='close' data-dismiss='alert' aria-label='Close' onClick={this.toggle}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        )}
      </React.Fragment>
    )
  }
}

class SendPointsModal extends Component {
  constructor(props) {
    super(props)
    this.stores = [ProfileStore, LoginStore]
    this.storeKeys = ['sendPoints', 'settings', 'isOpenSendPointsModal', 'budgetPointsBalance', 'teamMembers']

    this.defaultState = {
      userEmail: '',
      amount: 0,
      walletNote: '',
      isAwaitingResponse: false,
      sendPointsResponse: false,
      walletNoteError: false
    }
    this.state = this.defaultState

    ProfileActions.transferPoints.preEmit = () => this.setState({ isAwaitingResponse: true })
    ProfileActions.transferPoints.rejected.listen(sendPointsResponse => this.setState({ isAwaitingResponse: false, sendPointsResponse }))
    ProfileActions.transferPoints.completed.listen(() => this.setState(this.defaultState))
    this.errorMessage = null
  }

  componentDidMount() {
    this.closePopup()
  }

  shouldDisableButton = () => {
    const isValidForm = () => {
      const { userEmail, amount, walletNote } = this.state
      const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const phoneReg = /^[\d +]{8,13}$/
      const acceptableAmount = amount !== '' && parseInt(amount) > 0 && amount <= this.state.budgetPointsBalance
      return walletNote && acceptableAmount && (phoneReg.test(String(userEmail).toLowerCase()) || emailReg.test(String(userEmail).toLowerCase()))
    }
    return this.state.isAwaitingResponse || this.state.sendPointsResponse !== null || !isValidForm()
  }

  closePopup = () => {
    this.setState({
      isAwaitingResponse: false,
      sendPointsResponse: null,
      userEmail: '',
      amount: 0,
      walletNote: ''
    })
  }

  handleEmailOrPhoneChange = e => {
    let email = e.userEmail
    this.setState({
      selectedUserForSendPoints: e,
      userEmail: email,
      sendPointsResponse: null
    })
  }

  handleWalletNote = e => {
    this.setState({
      walletNote: strip_tags(e.target.value),
      sendPointsResponse: null
    })
  }

  handlePointChange = e => {
    let { valueAsNumber, validity } = e.target

    if (!validity.valid) return

    this.setState(() => ({
      amount: valueAsNumber,
      sendPointsResponse: null
    }))
  }

  handleConfirmClick = () => {
    const { userEmail, amount, walletNote } = this.state
    if (!walletNote) {
      this.setState({ walletNoteError: true })
      return
    }
    ProfileActions.transferPoints(userEmail, amount, walletNote)
    this.setState(() => ({ isAwaitingResponse: true }))
  }

  render() {
    const { sendPointsResponse, walletNote, amount, isAwaitingResponse } = this.state

    const showMissingUserAlert = () => {
      if (sendPointsResponse && sendPointsResponse.error && sendPointsResponse.error.message) {
        this.errorMessage = sendPointsResponse.error.message
        return true
      } else {
        return false
      }
    }
    const showSuccessAlert = () => sendPointsResponse && !sendPointsResponse.error
    return (
      <Modal isOpen={this.state.isOpenSendPointsModal}>
        <ModalHeader toggle={ProfileActions.toggleSendPointsModal}>Send Point</ModalHeader>
        <ModalBody>
          <>
            <InfoToast message={'Udfyld formularen nedenfor for at sende point'} />
            <Alert color='danger' isOpen={showMissingUserAlert()}>
              <div className='clearfix'>
                <div className='float-left'>{this.errorMessage}</div>
                <div className='float-right'>
                  <a className='font-weight-bold' onClick={() => this.setState({ sendPointsResponse: null })}>
                    <FontAwesomeIcon icon='times' style={{ cursor: 'pointer' }} />
                  </a>
                </div>
              </div>
            </Alert>
            <Alert isOpen={showSuccessAlert()}>
              Overførslen er gennemført{' '}
              <Button
                onClick={() => {
                  this.closePopup()
                  ProfileActions.getPoints()
                  ProfileActions.getWalletTransactions()
                }}
              >
                <FontAwesomeIcon icon='times' style={{ cursor: 'pointer' }} />
              </Button>
            </Alert>
            <p style={{ margin: '0px', marginBottom: '8px', fontSize: '16px' }}>Nuværende saldo på budget: {this.state.budgetPointsBalance}</p>
            <p className='font-weight-bold' style={{ margin: '0px' }}>
              Modtagers navn eller e-mail:
            </p>
            <Select
              value={this.state.selectedUserForSendPoints}
              onChange={this.handleEmailOrPhoneChange}
              options={this.state.teamMembers}
              placeholder='Vælg user'
            />
            <p className='font-weight-bold' style={{ marginTop: '16px', marginBottom: '0px' }}>
              Antal point:
            </p>
            <Input
              className='standard-input mb-3'
              type='number'
              min='1'
              pattern='[0-9]*'
              placeholder='f.eks. 1523'
              value={amount}
              max={this.state.budgetPointsBalance}
              onChange={this.handlePointChange}
            />
            <p className='font-weight-bold' style={{ margin: '0px' }}>
              Note:
            </p>
            <Input required className='standard-input mb-3' type='text' value={walletNote} onChange={this.handleWalletNote} />
            <Alert color='danger' isOpen={this.state.walletNoteError}>
              <div className='clearfix'>
                <div className='float-left'>Please Enter Wallet Note</div>
                <div className='float-right'>
                  <a className='font-weight-bold' onClick={() => this.setState({ walletNoteError: false })}>
                    <FontAwesomeIcon style={{ cursor: 'pointer' }} icon='times' />
                  </a>
                </div>
              </div>
            </Alert>
          </>
        </ModalBody>
        <ModalFooter>
          <Button style={{ backgroundColor: '#111111', borderRadius: '5px' }} disabled={this.shouldDisableButton()} onClick={this.handleConfirmClick}>
            {isAwaitingResponse ? 'Sender...' : 'Godkend'}
          </Button>
          <Button color='secondary' onClick={ProfileActions.toggleSendPointsModal}>
            Luk
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
