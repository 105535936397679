import React from 'react'
import { Col, Row } from 'reactstrap'
import ProductSearch from 'Modules/shop/shopPage/ProductSearch.js'
import CategoryFilters from 'Modules/shop/shopPage/topMenu/CategoryFilters.js'
import BrandFilters from 'Modules/shop/shopPage/topMenu/BrandFilters.js'

export default function TopMenu() {
  return (
    <Row>
      <Col xs='12' sm='12' md='4'>
        <Row className='mr-1 ml-1 mt-2'>
          <Col>
            <CategoryFilters />
          </Col>
        </Row>
      </Col>
      <Col xs='12' sm='12' md='4'>
        <Row className='mr-1 ml-1 mt-2'>
          <Col>
            <BrandFilters />
          </Col>
        </Row>
      </Col>
      <Col xs='12' sm='12' md='4'>
        <Row className='mr-1 ml-1'>
          <Col>
            <ProductSearch />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
