import Reflux from 'reflux'
import Proxies from 'Components/proxies.js'

const CommentActions = Reflux.createActions(['getComments', 'sendComment', 'updateComment', 'addEmoji', 'toggleSmileyPicker'])

class CommentStore extends Reflux.Store {
  constructor() {
    super()
    this.listenables = CommentActions
    this.state = {
      commentTrack: [],
      comment: '',
      showSmileyPicker: false
    }
  }
  getComments(props) {
    Proxies.getComments({
      commentRelationType: props.relation,
      CommentRelationID: props.id,
      removed: 0
    }).then(responseJSON => {
      let nextState = {
        commentTrack: [],
        comment: ''
      }
      if (responseJSON) {
        nextState.commentTrack = responseJSON
      }
      this.setState(nextState)
    })
  }

  toggleSmileyPicker = () => {
    this.setState(state => ({ showSmileyPicker: !state.showSmileyPicker }))
  }

  updateComment(e) {
    this.setState({ comment: e.target.value })
  }
  addEmoji(e) {
    let nativeEmoji = e.native
    let currentComment = this.state.comment
    currentComment = currentComment + nativeEmoji
    this.setState({ comment: currentComment })
  }
  sendComment(props) {
    if (!this.state.comment) {
      return false
    }
    Proxies.postComment({
      commentRelationType: props.relation,
      CommentRelationID: props.id,
      commentMessage: this.state.comment
    }).then(() => {
      this.getComments(props)
    })
  }
}

export { CommentActions, CommentStore }
