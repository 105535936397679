import { createSlice } from '@reduxjs/toolkit'
import { BasketActions } from 'Stores/basketStore'
import { ShopActions } from 'Stores/shopStore'

const slice = createSlice({
  name: 'basket',
  initialState: {},
  reducers: {}
})

export const selectProduct = payload => () => {
  if (payload.enableSelectChristmasGift) {
    ShopActions.christmasSelect(payload.variationID)
  } else {
    BasketActions.incrementBasketItemCount(payload.shopId, payload.productId, payload.variationID)
  }
}

export default slice.reducer
