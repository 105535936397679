import React from 'react'
import { Button } from 'reactstrap'
import { BrandSupportEmail } from 'Modules/SupportEmail.js'

export default class DropdownPage extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      dropdownOpen: false
    }
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }
  render() {
    return (
      <div className='container-fluid container-padding pt-5'>
        <h5 className='pt-1'>Vi kunne ikke finde siden du ledte efter!</h5>
        <h6 className='pt-1'>
          Har du oplevet denne fejl flere gange? Kontakt venligst vores <BrandSupportEmail className='link' />
        </h6>
        <Button
          onClick={() => {
            this.props.history.go(-1)
          }}
          color='secondary'
          className='standardBtn-black mt-3'
          style={{ maxWidth: '200px' }}
        >
          Gå tilbage
        </Button>
      </div>
    )
  }
}
