import React from 'react'
import Reflux from 'reflux'
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ProfileStore } from 'Stores/profileStore'
import { BasketActions } from 'Stores/basketStore'
import ChristmasProductModal from 'Modules/shop/productPage/ChristmasProductModal.js'
import { LoginStore } from 'Stores/loginStore'
import _ from 'lodash'

const StyledModalHeader = styled(ModalHeader)`
  .modal-title {
    width: 100%;
  }
`

const StyledImg = styled.img`
  max-height: 250px;
`

const StyledContinueButton = styled(Button)`
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 400;
  background-color: #2e2e2e !important;
  width: 100%;
  padding: 15px;
  box-shadow: 0px 0px 0px 0px;
  border: 0px solid black;
  color: white !important;
  margin: 0;
`

export default class ProductModal extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [ProfileStore, LoginStore]
    this.storeKeys = ['profileData', 'settings']
  }

  handleGoToBasket = () => {
    this.props.toggle()
    BasketActions.toggleBasketModalOpen(this.state.profileData.user.addresses[0])
  }

  render() {
    let imageToDisplay = ''
    let imageAlt = ''
    if (this.props.image.imageSmall) {
      imageToDisplay = this.props.image.imageSmall
    } else if (this.props.image.imageMedium) {
      imageToDisplay = this.props.image.imageMedium
    } else if (this.props.image.imageLarge) {
      imageToDisplay = this.props.image.imageLarge
    } else {
      imageToDisplay = this.props.image.imageSrc
    }
    imageAlt = this.props.image.imageName

    if (_.get(this.state.settings, 'features.shop.enableSelectChristmasGift')) {
      return (
        <ChristmasProductModal
          imageToDisplay={imageToDisplay}
          imageAlt={imageAlt}
          toggle={this.props.toggle}
          variationTitle={this.props.variationTitle}
        />
      )
    } else {
      return (
        <Modal isOpen toggle={this.props.toggle}>
          <StyledModalHeader className='text-center pt-5 pb-4 pr-5 pl-5 product-modal' toggle={this.props.toggle}>
            Tilføjet til kurv!
          </StyledModalHeader>
          <ModalBody>
            <Row className='pl-5 pr-5'>
              <Col className='text-center'>
                <StyledImg src={imageToDisplay} alt={imageAlt} className='mb-3' />
                <p className='mb-5'>
                  Du har nu lagt {this.props.productName} {!!this.props.variationTitle && <b>({this.props.variationTitle})</b>} i din kurv.
                </p>
              </Col>
            </Row>
            <Row>
              {!_.get(this.state.settings, 'features.isSeasonShop') && (
                <Col xs='12' sm='6' className='text-right mb-3 mb-sm-0'>
                  <StyledContinueButton type='button' onClick={this.props.toggle}>
                    Forsæt med at handle
                  </StyledContinueButton>
                </Col>
              )}
              <Col xs='12' sm='6' style={_.get(this.state.settings, 'features.isSeasonShop') && { margin: 'auto' }}>
                <Button type='button' className='standardBtn-black' onClick={this.handleGoToBasket} data-cy='productModal.goToShop'>
                  Gå til indkøbs kurv
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )
    }
  }
}

ProductModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
  variationTitle: PropTypes.string.isRequired
}
