import React from 'react'
import Reflux from 'reflux'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TrackableClick } from 'Components/trackableComponents'
import { LoginStore } from 'Stores/loginStore'
import { BasketStore, BasketActions } from 'Stores/basketStore'
import { ProfileStore, ProfileActions } from 'Stores/profileStore'
import ShoppingCart from 'Modules/shop/basket/ShoppingCart.js'
import { fetchShops } from 'Reducers/sharedActions/shopActions.js'
import _ from 'lodash'

class Basket extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [BasketStore, ProfileStore, LoginStore]
    this.storeKeys = [
      'basket',
      'basketCount',
      'basketTotal',
      'profileData',
      'unavailableProducts',
      'settings',
      'confirmationId',
      'submitErrorMessage',
      'placingOrder',
      'termsAccepted',
      'showConfirmation',
      'basketModalOpen',
      'userMissingFields',
      'isExitsUserSeasonOrder'
    ]
    this.state = {
      refreshShops: false
    }
    this.placeOrderUnsubscribe = BasketActions.placeOrder.completed.listen(() => {
      this.setState({ refreshShops: true })
      if (_.get(this.state.settings, 'features.isSeasonShop')) {
        ProfileActions.getUserSeasonOrders(this.props.ShopID)
      }
    })
  }

  componentDidMount() {
    const validatePhoneNumberExistance = _.get(this.state.settings, 'features.validatePhoneNumberExistance')
    ProfileActions.checkProfile(validatePhoneNumberExistance)
  }

  componentWillUnmount() {
    this.placeOrderUnsubscribe()
  }

  toggleModal = () => {
    BasketActions.toggleBasketModalOpen(this.state.profileData.user.addresses[0])
    if (this.state.refreshShops) {
      this.props.fetchShops(true)
      this.setState({ refreshShops: false })
    }
  }

  handleOrderClick() {
    BasketActions.placeOrder()
  }

  handleAddressChange = addressIndex => {
    BasketActions.deliveryAddressChanged(this.state.profileData.user.addresses[addressIndex])
  }

  render() {
    if (!this.state.basket || !this.state.profileData) {
      return null
    }
    const termsAccepted = _.get(this.state.settings, 'features.isSeasonShop') ? false : !this.state.termsAccepted
    const containsUnavailableProducts = this.state.unavailableProducts.length > 0
    const hasInsufficientPoints = _.get(this.state.settings, 'features.isSeasonShop')
      ? false
      : this.state.profileData.user.userPointsComputed < this.state.basketTotal
    const containsNoProducts = this.state.basket.length === 0
    const isUserMissingFields = !_.get(this.state.settings, 'features.isSeasonShop') && this.state.userMissingFields.length > 0
    const submitButtonDisabled =
      isUserMissingFields || termsAccepted || containsUnavailableProducts || hasInsufficientPoints || containsNoProducts || this.state.placingOrder
    const toggleAction = () => {
      this.toggleModal()
    }
    let basketModal = null

    if (this.state.basketModalOpen) {
      basketModal = (
        <ShoppingCart
          toggleAction={toggleAction}
          showConfirmation={this.state.showConfirmation}
          orderId={this.state.confirmationId}
          errorMessage={this.state.submitErrorMessage}
          containsUnavailableProducts={containsUnavailableProducts}
          submitButtonDisabled={submitButtonDisabled}
          basket={this.state.basket}
          basketTotal={this.state.basketTotal}
          termsAccepted={this.state.termsAccepted}
          addresses={this.state.profileData.user.addresses}
          hasInsufficientPoints={hasInsufficientPoints}
          handleOrderClick={this.handleOrderClick}
          onAddressChange={this.handleAddressChange}
          placingOrder={this.state.placingOrder}
          TermsConditionsURL={
            this.state.settings && this.state.settings.features && this.state.settings.features.TermsConditionsURL
              ? this.state.settings.features.TermsConditionsURL
              : null
          }
          userMissingFields={this.state.userMissingFields}
        />
      )
    }

    return (
      <React.Fragment>
        <TrackableClick activityName='openBasket'>
          <div className='basket-wrapper' onClick={this.toggleModal} style={{ pointerEvents: 'auto' }}>
            <FontAwesomeIcon icon='shopping-basket' />
            <div className='basketCount'>
              <span>{this.state.basketCount}</span>
            </div>
          </div>
        </TrackableClick>
        {basketModal}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = { fetchShops }

export default connect(null, mapDispatchToProps)(Basket)
