import React from 'react'
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap'
import { selectCurrentProduct } from 'Reducers/productsSlice.js'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Reflux from 'reflux'
import { LoginStore } from 'Stores/loginStore'
import styled from 'styled-components'

const StyledButton = styled.button`
  background: transparent;
  border: none;
  color: #2e2e2e;

  &:hover {
    color: #0056b3;
  }
`

export default class RefluxWrap extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }

  render() {
    return <ChristmasProductModal {...this.props} settings={this.state.settings} />
  }
}

function ChristmasProductModal({ imageToDisplay, imageAlt, toggle, variationTitle, settings }) {
  const { ProductID } = useParams()
  const currentProduct = useSelector(state => {
    return selectCurrentProduct(state, parseInt(ProductID, 10))
  })

  return (
    <Modal isOpen toggle={toggle}>
      <ModalHeader className='text-center pt-5 pb-4 pr-5 pl-5 product-modal' toggle={toggle}>
        <span className='modalHeader christmasProductModalHeader'>Du har valgt din julegave</span>
      </ModalHeader>
      <ModalBody>
        <Row className='pl-5 pr-5 product-modal text-center'>
          <center>
            <p className='text-center'>
              Vi har modtaget din bestilling af{' '}
              <b>
                {currentProduct.title} {variationTitle ? `(${variationTitle})` : ''}.
              </b>
            </p>
            <Row className='my-4 justify-content-center'>
              <Col md='8'>
                <div className='selectedItem_container shadow-1'>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <img src={imageToDisplay} alt={imageAlt} className='selectedItem_image' />
                        </td>
                        <td>
                          <h5>{currentProduct.title}</h5>
                          <p>{currentProduct.description.substr(0, 30) + '...'}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
            <p className='text-center changeGiftNotification'>
              <span className='christmasProductText'>
                Vi håber at du bliver glad for din julegave. Husk at du kan ændre valget af dine julegave frem til{' '}
              </span>
              <b>{settings.christmasEndDate}</b>.
            </p>
            <p className='pb-3 text-center' id='christmasReceiptTitle'>
              <span className='christmasProductReceiptText'>Kvittering på dit endelige julegavevalg vil blive sendt til </span>
              <b id='christmasProductReceiptEmail'>{settings.userEmail}</b>{' '}
              <span className='christmasProductReceiptText'>så snart shoppen er lukket.</span>
            </p>
            <p className='pb-3 text-center'>
              <StyledButton onClick={toggle} id='backToChristamsShop'>
                Gå tilbage til juleshoppen
              </StyledButton>
            </p>
          </center>
        </Row>
      </ModalBody>
    </Modal>
  )
}
