import React from 'react'
import PropTypes from 'prop-types'

export default class OutsideClickListener extends React.Component {
  constructor(props) {
    super(props)
    this.node = null
    this.captureMode = { capture: true }
    this.handleClick = this.handleClick.bind(this)
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, this.captureMode)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, this.captureMode)
  }

  handleClick(e) {
    const { onInsideClick, onOutsideClick } = this.props
    return this.node.contains(e.target) ? onInsideClick && onInsideClick() : onOutsideClick && onOutsideClick()
  }

  render() {
    return React.cloneElement(this.props.children, {
      ref: node => (this.node = node)
    })
  }
}

OutsideClickListener.propTypes = {
  onInsideClick: PropTypes.func,
  onOutsideClick: PropTypes.func
}
