/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import URL from 'url-parse'
import Moment from 'moment'
import Linkify from 'linkifyjs/react'
import Masonry from 'react-masonry-component'
import { Button, Col, Container, UncontrolledCarousel, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TrackableClick } from 'Components/trackableComponents'
import { BlogStore, BlogActions } from 'Stores/blogStore'
import nl2br from 'Components/nl2br'
import { LoginStore } from 'Stores/loginStore'

let maxLength = 125

export default class MasonryBlog extends Component {
  constructor(props) {
    super(props)
    this.stores = [BlogStore, LoginStore]
  }

  componentDidMount() {
    if (this.props.match.params.BlogID && this.props.match.params.BlogID !== this.state.currentBlogID) {
      BlogActions.getBlogElements(this.props.match.params.BlogID)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.BlogID && this.props.match.params.BlogID !== prevProps.match.params.BlogID) {
      BlogActions.getBlogElements(this.props.match.params.BlogID)
    }
  }

  render() {
    let { blogs, currentBlog, currentBlogEmpty, loading, currentBlogID } = this.state
    let blog = (blogs || []) && blogs.find(x => x.BlogID === parseInt(currentBlogID))

    if (loading)
      return (
        <Col xs='12' className='mb-4'>
          <center>
            <FontAwesomeIcon icon='circle-notch' spin />
          </center>
        </Col>
      )

    const container = children => (
      <React.Fragment>
        {blog && (
          <Container>
            <Col xs='12'>
              <p className='pl-3'>{blog.moduleDescription}</p>
            </Col>
          </Container>
        )}
        <Container className='container-safe-height'>
          <Col xs='12'>
            <Masonry>{children}</Masonry>
          </Col>
        </Container>
      </React.Fragment>
    )

    if (currentBlogEmpty)
      return container(
        <Col xs='12' className='mb-4 text-center'>
          <h5>Der er ingen poster på denne blog</h5>
        </Col>
      )

    return container(
      currentBlog.map((e, i) => (
        <Col xs='12' md='6' className='mb-5' key={i}>
          <BlogItem settings={this.state.settings} item={e} />
        </Col>
      ))
    )
  }
}

class BlogItem extends Component {
  constructor(props) {
    super(props)
    let limit = true
    if (typeof props.limit != 'undefined') {
      limit = props.limit
    }
    this.state = {
      limit: limit,
      modal: false
    }
    this.toggle = this.toggle.bind(this)
  }
  togglePost() {
    this.setState({ limit: !this.state.limit })
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }
  render() {
    if (!this.props.item) {
      return null
    }

    let e = this.props.item

    Moment.locale('en')
    let attachments = filterAttachments(e.attachments)
    let blogModal = null
    if (this.state.modal == true) {
      blogModal = <BlogModal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} post={e} />
    }
    return (
      <React.Fragment>
        <div className='gray-bg' data-cy='masonryBlog.item'>
          <Attachments attachments={[attachments[0]]} />
          <div className='pl-4 pr-4 pt-3 pb-4'>
            <h5 className='title pt-3'>{e.elementTitle}</h5>
            <p className='dateStamp' style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
              <FontAwesomeIcon icon='calendar-alt' /> {Moment(e.timeStart).format('DD/MM/YYYY')}
            </p>
            {this.props.settings && this.props.settings.features && this.props.settings.features.fullText ? (
              <p>
                <Linkify>{nl2br(e.elementContent)}</Linkify>
              </p>
            ) : (
              <p className='content word-break'>
                {e.elementContent.length > maxLength && this.state.limit ? (
                  nl2br(e.elementContent.substr(0, maxLength - 3) + '...')
                ) : (
                  <Linkify>{nl2br(e.elementContent)}</Linkify>
                )}
                <TrackableClick activityName='post' activityArgumentID={e.PostID}>
                  <a className='readMore font-weight-bold' style={{ fontWeight: '600' }} onClick={this.toggle}>
                    Læs mere
                  </a>
                </TrackableClick>
              </p>
            )}
          </div>
        </div>
        {blogModal}
      </React.Fragment>
    )
  }
}

const BlogModal = inProps => {
  let { isOpen, toggle, post, ...props } = inProps
  let e = post
  let attachments = filterAttachments(post.attachments)
  let carousel = null
  if (attachments.length > 0) {
    if (attachments[0] && 'LinkID' in attachments[0]) {
      carousel = <Attachments className='attachment-post' attachments={[attachments[0]]} />
    } else {
      let carouselItems = (attachments || [])
        .map((x, i) => ({
          key: i,
          src: x.imageOptimizedSource,
          caption: '',
          altText: x.imageName
        }))
        .filter(e => {
          return e.src
        })
      carousel = <UncontrolledCarousel items={carouselItems} pause={false} controls={carouselItems.length > 1} />
    }
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} className={props.className}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody className='padding-off'>
        {carousel}
        <div className='pl-5 pr-5 pt-5 pb-5'>
          <div>
            <h2 className='pb-1'>{e.elementTitle}</h2>
            <p className='dateStamp'>
              <FontAwesomeIcon icon='calendar-alt' /> {Moment(e.timeStart).format('DD/MM/YYYY')}
            </p>
          </div>
          <Linkify options={{ nl2br: true }}>
            <p>{e.elementContent}</p>
          </Linkify>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className='standardBtn-black' onClick={toggle}>
          Luk
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const filterAttachments = attachments => {
  if (!attachments || !Array.isArray(attachments) || attachments.length == 0) {
    return []
  }
  return attachments
    .map(att => {
      if (!att) {
        return false
      }
      if (att.ImageID) {
        return att
      }
      if (att.LinkID) {
        let parsed = new URL(att.linkRef)
        if (['youtu.be', 'youtube.com', 'youtube-nocookie.com', 'm.youtube.com', 'www.youtube.com', 'vimeo.com'].indexOf(parsed.hostname) !== -1) {
          return att
        }
      }
    })
    .filter(att => {
      return att
    })
}

const Attachments = inProps => {
  let { attachments, ...props } = inProps
  attachments = filterAttachments(attachments)
  if (attachments && attachments.length > 0) {
    if (attachments.length == 1) {
      return <RenderAttachment attachment={attachments[0]} {...props} />
    }
    return attachments.map((e, i) => {
      return <RenderAttachment {...props} attachment={e} key={i} />
    })
  }
  return null
}

class RenderAttachment extends Component {
  constructor(props) {
    super(props)
  }
  ytVidId(url) {
    let p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    return url.match(p) ? RegExp.$1 : false
  }
  render() {
    let { attachment, className, ...props } = this.props
    if (attachment) {
      if (attachment.ImageID) {
        let image = ''
        /*if(attachment.imageOptimizedSourceSmall) {
					image = attachment.imageOptimizedSourceSmall
				} else */ if (attachment.imageOptimizedSourceMedium) {
          image = attachment.imageOptimizedSourceMedium
        } else if (attachment.imageOptimizedSource) {
          image = attachment.imageOptimizedSource
        } else {
          image = attachment.imageOriginSource
        }

        {
          /*

					if(i >= 0) {
						return (
							<React.Fragment>
								<img src={image}/>
							</React.Fragment>
						)
					}

					*/
        }
        if (!className) {
          className = ''
        }
        return (
          <React.Fragment>
            <img className={className} style={{ width: '100%' }} src={image} {...props} />
          </React.Fragment>
        )
      }
      if (attachment.LinkID) {
        let parsed = new URL(attachment.linkRef)
        let videoID = null
        className += ' player embed-responsive embed-responsive-16by9'
        switch (parsed.hostname) {
          case 'youtu.be':
          case 'youtube.com':
          case 'youtube-nocookie.com':
          case 'm.youtube.com':
          case 'www.youtube.com':
            videoID = this.ytVidId(attachment.linkRef)
            return (
              <div className={className} key={attachment.LinkID} {...props}>
                <iframe className='embed-responsive-item' src={'//www.youtube.com/embed/' + videoID + '?feature=oembed'} allowFullScreen />
              </div>
            )
          case 'vimeo.com':
            videoID = parsed.pathname.split('/')
            videoID = videoID[videoID.length - 1]
            return (
              <div className={className} key={attachment.LinkID} {...props}>
                <iframe className='embed-responsive-item' src={'//player.vimeo.com/video/' + videoID} allowFullScreen />
              </div>
            )
          default:
            break
        }
      }
    }
    return null
  }
}
