/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import Reflux from 'reflux'
import Proxies from 'Components/proxies'
import Cookies from 'js-cookie'

const MenuActions = Reflux.createActions([
  'getMenu',
  'getTagCategories',
  'applyFilters',
  'resetFilter',
  'getAnnouncements',
  'readAnnouncements',
  'closeAnnouncements',
  'checkTeamMenuAccess'
])

class MenuStore extends Reflux.Store {
  constructor() {
    super()
    this.listenables = MenuActions
    this.state = { menu: null, tags: [], tagFilterValue: null, activeMenu: null, announcements: [], teamMenuAccess: false }
  }
  getMenu() {
    Proxies.menuPool().then(responseJSON => {
      if (responseJSON) {
        if (responseJSON.length > 1) {
          responseJSON = responseJSON.sort((a, b) => {
            return a['poolOrder'] - b['poolOrder']
          })
        }
        responseJSON.map(menuItem => {
          if (menuItem.pool && Array.isArray(menuItem.pool)) {
            menuItem.pool = menuItem.pool.sort((a, b) => {
              return a['poolOrder'] - b['poolOrder']
            })
          }
          return menuItem
        })
        if (!this.state.teamMenuAccess) {
          responseJSON = responseJSON.filter(menu => menu.poolName.toLowerCase() !== 'team' && menu.menuTitle.toLowerCase() !== 'team')
        }
        this.setState({ menu: responseJSON })
      }
    })
  }
  getTagCategories() {
    Proxies.getTagCategories().then(responseJSON => {
      if (responseJSON && !responseJSON.error) {
        this.setState({ tags: responseJSON })
      }
    })
  }
  applyFilters(settings, value) {
    if (settings && settings.features && settings.features.enableCategoryTag) {
      this.setState({ tagFilterValue: value, activeMenu: value })
    }
  }

  resetFilter(settings, menu) {
    if (settings && settings.features && settings.features.enableCategoryTag) {
      this.setState({ tagFilterValue: null, activeMenu: menu })
    }
  }

  getAnnouncements() {
    Proxies.announcements().then(responseJSON => {
      if (responseJSON && !responseJSON.error) {
        responseJSON = responseJSON.map(item => {
          item.isOpen = item.isActive ? true : false
          item = this.readAnnouncements(item)
          return item
        })
        this.setState({ announcements: responseJSON })
      }
    })
  }

  readAnnouncements = announcement => {
    let alertString = Cookies.get(`${announcement.AnnouncementID}-${Reflux.GlobalState.LoginStore.settings.UserID}`)
    if (alertString) {
      announcement.isOpen = false
    }
    return announcement
  }

  closeAnnouncements = index => {
    let announcements = this.state.announcements
    let announcement = announcements[index]
    Cookies.set(`${announcement.AnnouncementID}-${Reflux.GlobalState.LoginStore.settings.UserID}`, JSON.stringify(announcement))
    announcement.isOpen = false
    this.setState({ announcements: announcements })
  }

  checkTeamMenuAccess(groups = []) {
    if (!groups || !groups.length) return false
    Proxies.checkGroupAccess({ groupNames: groups })
      .then(res => this.setState({ teamMenuAccess: res }))
      .catch(error => console.error(error))
  }
}

export { MenuActions, MenuStore }
