/* eslint-disable eqeqeq */
import Reflux from 'reflux'
import Proxies from 'Components/proxies'
import { ActivityActions } from './activityStore'

const LoginActions = Reflux.createActions([
  'authenticationRequested',
  'phoneNumberUpdated',
  'clearLoginError',
  'tokenUpdated',
  { tokenValidationRequested: { children: ['accepted', 'rejected'] } },
  'clearTokenError',
  'validate',
  { signOut: { children: ['completed'] } },
  'termsAccepted',
  'acceptGDPR',
  { setupWeb: { children: ['completed'] } },
  'getUserCPR',
  'saveCPRNumber',
  'updateCPRNumber',
  'closeCPRModal',
  'updateConsent'
])

class LoginStore extends Reflux.Store {
  constructor() {
    super()
    this.listenables = LoginActions
    let sessionKey = localStorage.getItem('session') ? localStorage.getItem('session') : null
    this.clearState = {
      phoneNumber: '',
      token: '',
      showPhoneNumberInput: true,
      sessionKey: null,
      sessionVerified: false,
      BrandID: null,
      brandData: null,
      loginErrorNoUser: false,
      loginErrorUniqueUser: false,
      tokenError: false,
      brandAccessTermsVerified: true,
      brandAccessTermsURL: null,
      settings: {},
      validatorHasRun: false,
      showVerifyTerms: false,
      showGDPR: false,
      unknownClientId: false,
      showCPRModal: false,
      CPRNumber: null,
      cprValidationFailed: false,
      consent: false
    }
    let startState = Object.assign({}, this.clearState, {
      sessionKey: sessionKey
    })
    this.state = startState
    this.isRunningSetup = false
    this.setupWeb()
  }
  getBrandSlug() {
    if (window.location.hostname == 'localhost') {
      return 'upgradertest'
    }
    let urlBase = window.location.hostname.split('.')[0].toLowerCase()
    if (urlBase.indexOf('-') != -1) {
      let theseOptions = urlBase.split('-')
      urlBase = theseOptions.shift()
    }
    return urlBase
  }
  phoneNumberUpdated(phoneNumber) {
    this.setState({ phoneNumber, loginErrorNoUser: false, loginErrorUniqueUser: false })
  }

  authenticationRequested() {
    Proxies.sessionGrant(this.state.phoneNumber).then(data => {
      if (!data || (data && data.error)) {
        return this.setState({
          showPhoneNumberInput: true,
          loginErrorNoUser: data.error.message === 'NO_USERS' || data.error.message === 'USER_NOT_FOUND' ? true : false,
          loginErrorUniqueUser: data.error.message === 'NOT_UNIQUE_USER' ? true : false
        })
      }

      this.setState({
        showPhoneNumberInput: false,
        loginErrorNoUser: false,
        loginErrorUniqueUser: false
      })
    })
  }

  clearLoginError() {
    this.setState({ loginErrorNoUser: false, loginErrorUniqueUser: false })
  }

  tokenUpdated(token) {
    if (token.length > 6) return

    this.setState({ token: token, tokenError: false })
    if (token.length === 6) {
      this.tokenValidationRequested()
    }
  }

  tokenValidationRequested() {
    Proxies.sessionAccess(this.state.phoneNumber, this.state.token).then(responseJSON => {
      if (responseJSON) {
        ActivityActions.logActivity('User', 'Login', responseJSON.UserID, '', {
          UserID: responseJSON.UserID,
          BrandID: responseJSON.BrandID
        })

        if (responseJSON.error || !responseJSON.UserID) {
          this.setState({
            tokenError: true
          })
          return LoginActions.tokenValidationRequested.rejected()
        }

        Proxies.loadConsent({
          UserID: responseJSON.UserID,
          BrandID: responseJSON.BrandID,
          ConsentType: 'GDPR',
          removed: 0
        }).then(consentData => {
          let termsAccepted = Boolean(parseInt(responseJSON.user.userAccessTerms))
          let nextState = {
            sessionKey: responseJSON.token,
            sessionVerified: false,
            brandAccessTermsVerified: false,
            brandAccessTermsURL: null,
            gdpr: false,
            showGDPR: false,
            showVerifyTerms: false,
            consentData: {
              UserID: responseJSON.UserID,
              BrandID: responseJSON.BrandID
            }
          }
          if (consentData && consentData.length > 0) {
            nextState.gdpr = true
          }
          sessionStorage.setItem('user', JSON.stringify(responseJSON))
          if (responseJSON.brand.brandAccessTermsURL) {
            nextState.brandAccessTermsURL = responseJSON.brand.brandAccessTermsURL
            nextState.brandAccessTermsVerified = termsAccepted
            nextState.showVerifyTerms = !termsAccepted

            if (termsAccepted && nextState.gdpr) {
              localStorage.setItem('session', responseJSON.token)
              nextState.sessionToken = responseJSON.token
              nextState.sessionVerified = true
            }
          } else {
            if (nextState.gdpr) {
              localStorage.setItem('session', responseJSON.token)
              nextState.sessionVerified = true
            }
            nextState.brandAccessTermsVerified = true
          }
          nextState.showGDPR = !nextState.gdpr
          this.setState(nextState)
          this.setupWeb()
          LoginActions.tokenValidationRequested.accepted()
        })
      } else {
        this.setState({ tokenError: true })
        LoginActions.tokenValidationRequested.rejected()
      }
    })
  }

  clearTokenError() {
    this.setState({ tokenError: false })
  }

  signOut() {
    return Proxies.sessionClear().finally(() => {
      localStorage.clear()
      sessionStorage.clear()
      LoginActions.signOut.completed()
    })
  }

  validate() {
    if (this.state.sessionVerified) {
      return true
    }
    if (localStorage.getItem('session')) {
      Proxies.sessionValidate().then(
        responseJson => {
          if (responseJson && responseJson.error && responseJson.error.message == 'SESSION_INVALID') {
            LoginActions.signOut()
            return false
          }
          if (responseJson) {
            let nextState = {
              sessionVerified: true,
              brandAccessTermsVerified: false,
              brandAccessTermsURL: null,
              validatorHasRun: true
            }
            sessionStorage.setItem('user', JSON.stringify(responseJson))
            if (responseJson.brand && responseJson.brand.brandAccessTermsURL) {
              nextState.brandAccessTermsURL = responseJson.brand.brandAccessTermsURL
              nextState.brandAccessTermsVerified = Boolean(parseInt(responseJson.user.userAccessTerms))
            } else {
              nextState.brandAccessTermsVerified = true
            }
            this.setState(nextState)
            return true
          }
          LoginActions.signOut()
        },
        () => {
          LoginActions.signOut()
        }
      )
    }
    this.setState({ validatorHasRun: true })
    return false
  }

  termsAccepted() {
    Proxies.verifyAccessTerms(this.state.sessionKey).then(responseJSON => {
      if (responseJSON) {
        let nextState = {
          brandAccessTermsVerified: true,
          sessionVerified: false,
          showVerifyTerms: false,
          showGDPR: true
        }
        if (this.state.gdpr) {
          nextState.sessionVerified = true
          nextState.showGDPR = false
          localStorage.setItem('session', this.state.sessionKey)
          this.setupWeb()
        }
        this.setState(nextState)
      }
    })
  }
  acceptGDPR() {
    Proxies.saveConsent(Object.assign({}, this.state.consentData, { ConsentType: 'GDPR' })).then(() => {
      let nextState = {
        gdpr: true,
        sessionVerified: false,
        showVerifyTerms: true,
        showGDPR: false
      }
      if (this.state.brandAccessTermsVerified) {
        nextState.sessionVerified = true
        nextState.showVerifyTerms = false
        localStorage.setItem('session', this.state.sessionKey)
        this.setupWeb()
      }
      this.setState(nextState)
    })
  }

  setupWeb(data = null) {
    if (this.isRunningSetup) {
      return false
    }
    this.isRunningSetup = true
    if (!data || !data.brandSlug) {
      if (!data) {
        data = {}
      }
      let thisBrandSlug = this.getBrandSlug()
      data.brandSlug = thisBrandSlug
      data.customDomain = window.location.hostname
    }
    return Proxies.platformSetup(data).then(
      responseJSON => {
        if (responseJSON.error) {
          this.setState({ unknownClientId: true })
        } else {
          this.setState({ unknownClientId: false })
        }

        let brand = {
          BrandID: responseJSON.platform.BrandID,
          brandName: responseJSON.platform.brandName,
          brandLogo: responseJSON.platform.brandLogo,
          brandSlug: responseJSON.platform.brandSlug,
          contactEmail: responseJSON.platform.contactEmail,
          contactPhone: responseJSON.platform.contactPhone
        }
        if (!brand.BrandID) {
          this.setState({ brandData: 'NOBRAND' })
        } else {
          localStorage.setItem('BrandID', brand.BrandID)
          document.title = brand.brandName
          this.setState({ BrandID: brand.BrandID, brandData: brand })
        }
        if (responseJSON && responseJSON.platform && responseJSON.platform.faviconImageURL) {
          let favicon = document.getElementById('favicon')
          favicon.href = responseJSON.platform.faviconImageURL
        }
        this.setState({ settings: responseJSON.platform })
        this.validate()
        LoginActions.setupWeb.completed(responseJSON.platform)
        this.isRunningSetup = false
        return responseJSON.platform
      },
      () => {
        let brandSlug = this.getBrandSlug()
        setTimeout(() => {
          this.setupWeb({ brandSlug })
        }, 500)
        this.isRunningSetup = false
      }
    )
  }

  getUserCPR() {
    Proxies.getUserExistsCPR().then(responseJSON => {
      if (responseJSON && !responseJSON.error) {
        const cprExists = responseJSON.exists ? false : true
        this.setState({ showCPRModal: cprExists })
      }
    })
  }

  saveCPRNumber() {
    Proxies.saveCPR({ data: this.state.CPRNumber, consent: this.state.consent }).then(responseJSON => {
      if (responseJSON && !responseJSON.error) {
        this.setState({ showCPRModal: false })
        this.getUserCPR()
      }
    })
  }

  updateCPRNumber(e) {
    this.setState({ cprValidationFailed: false })
    let value = e.target.value
    if (value.match(/^[0-9-]+$/)) {
      value = !value.includes('-') ? value.match(/.{1,6}/g).join('-') : value
      if (value.length === 11) {
        let cprNumber = value.split('-')
        let dateOfBirth = cprNumber[0]
        let sequenceNumber = cprNumber[1]
        if (!dateOfBirth.match(/^(0[1-9]|1[0-9]|2[0-9]|3[01])(0[1-9]|1[012])[0-9]{2}$/)) {
          this.setState({ cprValidationFailed: true })
        }
        if (sequenceNumber.match(/^0+$/)) {
          this.setState({ cprValidationFailed: true })
        }
      }
      this.setState({ CPRNumber: value })
    } else {
      this.setState({ CPRNumber: value })
      value !== '' && this.setState({ cprValidationFailed: true })
    }
  }

  closeCPRModal() {
    this.setState({ showCPRModal: false })
  }

  updateConsent(e) {
    this.setState({ consent: e.target.checked })
  }
}

LoginStore.id = 'LoginStore'

export { LoginStore, LoginActions }
