import React from 'react'
import { Component } from 'reflux'
import { Redirect } from 'react-router-dom'
import { MenuStore } from 'Stores/menuStore'

export default class FrontPageLoader extends Component {
  constructor(props) {
    super(props)
    this.store = MenuStore
  }
  render() {
    if (!this.state.menu) {
      return null
    }
    if (this.state.menu && this.state.menu.length > 0) {
      let firstMenu = this.state.menu[0].poolName.toLowerCase()
      return (
        <div className='pt-5 pb-5 pr-5 pl-5'>
          <center>
            <Redirect to={'/' + firstMenu} />
          </center>
        </div>
      )
    }
  }
}
