import React from 'react'
import Reflux from 'reflux'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import { ProfileActions, ProfileStore } from 'Stores/profileStore'
import { getStatus, selectShop, getShops } from 'Reducers/shopsSlice.js'
import { fetchShops, SHOP_STATUS } from 'Reducers/sharedActions/shopActions.js'
import { getStatus as getProductStatus, PRODUCTS_STATUS, fetchProducts } from 'Reducers/productsSlice.js'
import { Route, Switch } from 'react-router-dom'
import _ from 'lodash'
import ShopStatus from 'Modules/shop/ShopStatus.js'
import ShopPage from 'Modules/shop/ShopPage.js'
import SectionMenu from 'Modules/layout/SectionMenu.js'
import ProductPage from 'Modules/shop/ProductPage.js'
import { BasketActions, BasketStore } from 'Stores/basketStore'
import Basket from 'Modules/shop/basket/Basket.js'
import { LoginStore } from 'Stores/loginStore'
import CategoryList from './shopPage/categoryList'

class ShopLoader extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [ProfileStore, BasketStore, LoginStore]
    this.storeKeys = ['profileData', 'settings']
  }

  componentDidMount() {
    this.props.fetchShops()
    ProfileActions.getProfile()
    if (!_.get(this.state.settings, 'features.shop.disableBasket')) BasketActions.getBasket()
    const activeShopId = _.get(this.props, 'activeShop.ShopID')
    activeShopId && this.props.fetchProducts(activeShopId)
  }

  componentDidUpdate(prevProps) {
    const activeShopId = _.get(this.props, 'activeShop.ShopID')
    if (activeShopId && activeShopId !== _.get(prevProps, 'activeShop.ShopID')) {
      // Fetch product when ShopID matches an accessible shop
      this.props.fetchProducts(activeShopId)
    }
  }

  isLoading = () => {
    return !this.state.profileData || this.props.shopsStatus === SHOP_STATUS.LOADING_DATA || this.props.productStatus === PRODUCTS_STATUS.LOADING_DATA
  }

  hasFailed = () => {
    return this.props.shopsStatus === SHOP_STATUS.LOADING_DATA_FAILURE || this.props.productStatus === PRODUCTS_STATUS.LOADING_DATA_FAILURE
  }

  generateShopLinks = () => {
    return this.props.shops.map(shop => ({
      name: shop.moduleName,
      url: `${
        this.props.location.pathname.includes('categories') &&
        this.state.settings &&
        this.state.settings.features &&
        this.state.settings.features.enableCategoriesInShop
          ? `/shop/${shop.ShopID}/categories`
          : `/shop/${shop.ShopID}`
      }`,
      moduleId: shop.ShopID,
      sectionName: 'shop'
    }))
  }

  render() {
    if (this.isLoading()) {
      return <ShopStatus loading />
    } else if (this.hasFailed()) {
      return <ShopStatus error />
    } else if (!this.props.shops.length) {
      return <ShopStatus noShops />
    } else if (!this.props.activeShop) {
      return <Redirect to={'/shop/' + this.props.shops[0].ShopID} />
    }

    return (
      <Container fluid={true} className='container-padding'>
        <SectionMenu links={this.generateShopLinks()} />
        <Switch>
          <Route path='/shop/:ShopID/categories/:categoryName/:ProductID' component={ProductPage} />
          <Route path='/shop/:ShopID/categories/:categoryName' component={ShopPage} />
          <Route path='/shop/:ShopID/categories' component={CategoryList} />
          <Route path='/shop/:ShopID/:ProductID' component={ProductPage} />
          <Route path='/shop/:ShopID' component={ShopPage} />
        </Switch>
        {!_.get(this.state.settings, 'features.shop.disableBasket') && (
          <div className='fixed-bottom' style={{ pointerEvents: 'none' }}>
            <div className='float-right'>
              <Basket ShopID={this.props && this.props.activeShop && this.props.activeShop.ShopID} />
            </div>
          </div>
        )}
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  shopsStatus: getStatus(state),
  productStatus: getProductStatus(state),
  activeShop: selectShop(state, ownProps.match.params.ShopID),
  shops: getShops(state)
})

const mapDispatchToProps = { fetchShops, fetchProducts }

export default connect(mapStateToProps, mapDispatchToProps)(ShopLoader)
