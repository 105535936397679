/* eslint-disable eqeqeq */
import React from 'react'
import Reflux from 'reflux'
import { Button, CardBody, Col, Container, Input, InputGroup, Row, Alert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoginActions, LoginStore } from 'Stores/loginStore'
import { Link } from 'react-router-dom'
import UnknownClubError from 'Modules/error-pages/UnknownClubError.js'
import { BrandSupportEmail } from 'Modules/SupportEmail.js'
import styled from 'styled-components'
import { BREAKPOINTS } from 'Components/mq.js'
import _ from 'lodash'

const StyledLoginCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    padding: 0;
  }
`

export default class Login extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.userIdentifier = null
    this.userValidation = null
  }

  componentWillUpdate(nextProps, nextState) {
    const { brandAccessTermsVerified, gdpr, sessionVerified } = nextState
    // if the user has session, and has signed terms and GDPR, redirect to front page
    if (sessionVerified == true && brandAccessTermsVerified == true && gdpr == true) nextProps.history.push('/')
  }

  componentDidUpdate() {
    if (this.userIdentifier && !this.userIdentifier.disabled) {
      this.userIdentifier.focus()
    }
    if (this.userValidation && !this.userValidation.disabled) {
      this.userValidation.focus()
    }
  }

  hasValidInput = () => {
    const phoneOrEmailRegex = /^[\w-+.]+@[\w-+]+\.[a-zA-Z]{2,4}|^(\+45|0045)?[ ]*[\d]{8}$/
    const validationCodeRegex = /[\d]{6}/
    const { showPhoneNumberInput, phoneNumber, token } = this.state
    return showPhoneNumberInput ? phoneOrEmailRegex.test(phoneNumber) : validationCodeRegex.test(token)
  }

  inputIsEmail = () => /^[\w-+.]+@[\w-+]+\.[a-zA-Z]{2,4}$/.test(this.state.phoneNumber)

  createErrorMessage = () => {
    const { tokenError, loginErrorNoUser, loginErrorUniqueUser } = this.state
    if (tokenError)
      return (
        <p>
          Vi kunne ikke finde engangskoden. <br />
          Er den indtastet korrekt, og er det den seneste kode du har modtaget? <br />
          Husk du har kun 3 forsøg
        </p>
      )

    if (loginErrorNoUser && this.inputIsEmail())
      return (
        <p>
          Den indtastede e-mail kunne ikke findes.
          <br />
          Er den blevet tastet korrekt? Eller er du evt. blevet oprettet med en anden e-mail adresse, f.eks. din arbejdsmail eller private mail?
        </p>
      )

    if (loginErrorNoUser) {
      return (
        <p>
          Det indtastede mobilnummer kunne ikke findes.
          <br />
          Er det blevet tastet korrekt? Eller er du evt. blevet oprettet med et andet mobilnummer?
        </p>
      )
    }

    if (loginErrorUniqueUser && this.inputIsEmail())
      return (
        <p>
          Det er ikke muligt at logge ind med den indtastede email.
          <br />
          Er den blevet tastet korrekt? Eller er du evt. blevet oprettet med en anden mail?
        </p>
      )

    if (loginErrorUniqueUser) {
      return (
        <p>
          Det er ikke muligt at logge ind med det indtastede mobilnummer.
          <br />
          Er det blevet tastet korrekt? Eller er du evt. blevet oprettet med et andet mobilnummer?
        </p>
      )
    }

    return ''
  }

  render() {
    if (this.state.unknownClientId) return <UnknownClubError />
    if (this.state.sessionKey && !(this.state.showVerifyTerms || this.state.showGDPR)) return null
    if (this.state.brandData == 'NOBRAND') {
      return <div>No brand associated</div>
    }
    if (!this.state.BrandID) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    let submitAction = () => {
      return false
    }
    let userIdentifierDisabled = true
    let userValidationDisabled = true
    let submitText = '...'

    if (this.state.showPhoneNumberInput) {
      submitAction = LoginActions.authenticationRequested
      userIdentifierDisabled = false
      userValidationDisabled = true
      submitText = this.hasValidInput() ? `Send engangskode på ${this.inputIsEmail() ? 'e-mail' : 'SMS'}` : 'Indtast dit mobilnummer eller din e-mail'
    }
    if (!this.state.showPhoneNumberInput && !this.state.sessionKey) {
      submitAction = LoginActions.tokenValidationRequested
      userIdentifierDisabled = true
      userValidationDisabled = false
      submitText = this.hasValidInput() ? 'Log ind' : `Indtast koden fra din ${this.inputIsEmail() ? 'e-mail' : 'SMS'}`
    }
    if (_.get(this.state.settings, 'features.isDownForMaintenance') && this.state.settings.features.isDownForMaintenance) {
      userIdentifierDisabled = true
    }
    let body = null
    if (this.state.showGDPR) {
      body = (
        <CardBody style={{ maxWidth: '500px' }} id='gdpr-tos'>
          <p className='login help'>
            <span id='gdpr-title1'>
              Jeg giver hermed samtykke til, at MS Branding A/S må behandle mine personoplysninger til brug for administration af min profil i
              kundeklubben.
            </span>
          </p>
          <p className='login help'>
            <span id='gdpr-title2'>
              I det omfang jeg eksplicit har accepteret dette, er MS Branding A/S endvidere berettiget til at benytte mine oplysninger i forbindelse
              med fremsendelse af særlige tilbud og events, som vedrører kundeklubben.
            </span>
          </p>
          <center>
            <Button
              id='gdpr-accept-button'
              style={{ maxWidth: '250px' }}
              className='px-4 profile-button mt-3 mb-4 standardBtn-black'
              onClick={LoginActions.acceptGDPR}
            >
              Godkend
            </Button>
          </center>
          <p className='login help'>
            <span id='gdpr-title3'>
              Du kan til enhver tid tilbagekalde dit samtykke ved at kontakte vores support på <BrandSupportEmail queryParams='?subject=GDPR' /> eller
              på telefon {this.state.brandData && this.state.brandData.contactPhone ? this.state.brandData.contactPhone : '+45 3115 8411'}.
            </span>
          </p>
          <p className='login help'>
            <span id='gdpr-title4'>
              Vælger du at tilbagekalde dit samtykke vil dette eventuelt kunne medføre, at du ikke længere kan anvende kundeklubben.
            </span>
          </p>
        </CardBody>
      )
    } else if (this.state.showVerifyTerms) {
      body = (
        <CardBody style={{ maxWidth: '500px' }}>
          <p className='text-center'>
            Du har ikke godkendt betingelserne.
            <br />
            Godkend ved at trykke på knappen.
          </p>
          <a href={this.state.brandAccessTermsURL} className='viewTerms' target='_blank' rel='noopener noreferrer'>
            Betingelser for brug af klubben
          </a>
          <br />
          <center>
            <Button style={{ width: '100%' }} className='px-4 profile-button standardBtn-black mt-4' onClick={LoginActions.termsAccepted}>
              Godkend
            </Button>
          </center>
        </CardBody>
      )
    } else {
      body = (
        <CardBody className='loginCard' style={{ maxWidth: '500px' }}>
          <h5 className='mb-4 text-center'>Velkommen til {this.state.brandData.brandName}</h5>
          <center>
            <div
              style={{
                width: '45px',
                height: '2px',
                backgroundColor: '#2e2e2e'
              }}
            />
          </center>
          <h6 style={{ lineHeight: '1.8em' }} className='text-center pt-3 pb-3'>
            Indtast dit mobilnummer eller din e-mail så sender vi en engangskode du kan logge ind med.
          </h6>

          <InputGroup className='mb-3 padding-off'>
            {this.state.showPhoneNumberInput ? (
              <Input
                className='loginInput'
                type='text'
                placeholder='Mobilnummer eller e-mail'
                innerRef={e => {
                  this.userIdentifier = e
                }}
                autoFocus={!userIdentifierDisabled}
                disabled={userIdentifierDisabled}
                onChange={e => {
                  LoginActions.phoneNumberUpdated(e.target.value)
                }}
                value={this.state.phoneNumber || ''}
                data-cy='login.user'
              />
            ) : (
              <Input
                className='loginInput'
                type='number'
                placeholder='Engangskode (f.eks. 192659)'
                innerRef={e => {
                  this.userValidation = e
                }}
                autoFocus={!userValidationDisabled}
                disabled={userValidationDisabled}
                onChange={e => {
                  LoginActions.tokenUpdated(e.target.value)
                }}
                value={this.state.token || ''}
                data-cy='login.pass'
              />
            )}
          </InputGroup>
          <div className='text-center' data-cy='login.errorMessage'>
            {this.createErrorMessage()}
          </div>
          <Row>
            <Col>
              <Button
                disabled={!this.hasValidInput() || this.state.loginErrorNoUser == true || this.state.loginErrorUniqueUser == true}
                style={{ width: '50%' }}
                className='px-4 standardBtn-black mb-3'
                data-cy='login.submit'
              >
                {submitText}
              </Button>
            </Col>
          </Row>
          <p className='text-center'>
            Oplever du problemer ved login eller har du brug for hjælp?
            <br />
            Kontrollér dit spamfilter, hvis du har bedt om koden på e-mail
            <br /> Kontakt support på <BrandSupportEmail />
            <br />
            eller på telefon {this.state.brandData && this.state.brandData.contactPhone ? this.state.brandData.contactPhone : '+45 31 15 84 11'}
          </p>
          {this.state.settings.features && this.state.settings.features.registerUser && (
            <Link to='/register' className='link' style={{ fontSize: '13px' }}>
              Opret ny bruger
            </Link>
          )}
        </CardBody>
      )
    }
    let logo = null
    if (this.state && this.state.brandData && this.state.brandData.brandLogo) {
      logo = <img src={this.state.brandData.brandLogo} className='loginLogo pb-3' alt={this.state.brandData.brandName} />
    }
    return (
      <React.Fragment>
        <form
          className='app loginbackground flex-row align-items-center'
          onSubmit={e => {
            submitAction()
            e.preventDefault()
          }}
        >
          <StyledLoginCol xs='12' sm='12' md='12' className='light-bg'>
            <Container>
              <div className='py-5 mt-5 mx-sm-5'>
                <center>
                  {this.state.settings && this.state.settings.features && this.state.settings.features.isDownForMaintenance && (
                    <div>
                      <Alert color='info' style={{ display: 'inline-block' }} className='maintenance-text'>
                        Klubben er midlertidigt lukket på grund af vedligeholdelse
                      </Alert>
                    </div>
                  )}
                  {logo}
                  {body}
                </center>
              </div>
            </Container>
          </StyledLoginCol>
        </form>
      </React.Fragment>
    )
  }
}
