/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import objectFitImages from 'object-fit-images'
import moment from 'moment'
import { Button, Col, Container, Input, Tooltip, Row, Modal, ModalBody, ModalHeader, Alert, Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import { ProfileActions, ProfileStore } from 'Stores/profileStore'
import OutsideClickListener from 'Components/OutsideClickListener'
import _ from 'lodash'
import ProfileMenu from 'Modules/profile/ProfileMenu.js'
import { OrdersSupportEmail } from 'Modules/SupportEmail.js'
import { LoginStore } from 'Stores/loginStore'
const itemWindow = 5

export default class Orders extends Component {
  constructor(props) {
    super(props)
    this.stores = [ProfileStore, LoginStore]
    this.storeKeys = ['profileData', 'orders', 'settings']
    this.state = {
      windowSize: itemWindow,
      hoveredProductHash: null,
      showIndberet: false
    }
  }

  componentDidMount() {
    ProfileActions.getProfile()
    ProfileActions.getOrders()
    objectFitImages()
  }
  componentDidUpdate() {
    objectFitImages()
  }

  handleLoadMoreClick() {
    const windowSize = this.state.windowSize + 5
    this.setState({ windowSize })
  }

  render() {
    let { orders, windowSize, hoveredProductHash, profileData } = this.state

    if (!orders || !profileData) return null

    const orderTimeline = (orders || [])
      .slice(0, windowSize)
      .map((x, i) => (
        <OrderTile
          key={i}
          OrderID={x.OrderID}
          plainOrderID={x.plainOrderID}
          orderType={x.orderType}
          timeInsert={x.timeInsert}
          orderFlowStatus={x.orderFlowStatus}
          shippingIDs={x.shippingids}
          products={x.products}
          orderPricePoints={x.products.reduce((sum, next) => sum + next.orderAmountPoints, 0)}
          toggleProductTooltip={(hoveredProductHash = null) => this.setState({ hoveredProductHash })}
          hoveredProductHash={hoveredProductHash}
          UserID={x.UserID}
          brandSlug={x.brandSlug}
          orderDropShipment={x.orderDropShipment}
          orderConsignee={x.orderConsignee}
          orderConsigneeName={x.orderConsigneeName}
          orderConsigneeAddress1={x.orderConsigneeAddress1}
          orderConsigneeZipcode={x.orderConsigneeZipcode}
          orderConsigneeCity={x.orderConsigneeCity}
          showPointsOnOrderTile={this.state.settings && !_.get(this.state.settings, 'features.isSeasonShop')}
          userOrderRemarks={x.userOrderRemarks}
        />
      ))

    return (
      <React.Fragment>
        <ProfileMenu />
        <Container>
          {this.state.settings && !_.get(this.state.settings, 'features.isSeasonShop') && !_.get(this.state.settings, 'features.hideTaxButton') && (
            <div className='clearfix'>
              <div className='float-right IndberetTilSkat'>
                <IndberetTilSkat text='Oversigt for indberetning til SKAT' defaultEmail={this.state.profileData.user.userEmail} />
              </div>
            </div>
          )}
          <div className='mt-4'>{orderTimeline}</div>
          {this.state.windowSize < (orders || []).length && (
            <div className='mt-4 text-center'>
              <a onClick={() => this.handleLoadMoreClick()}>
                <h5>
                  Vis flere <FontAwesomeIcon icon='arrow-down' />
                </h5>
              </a>
            </div>
          )}
        </Container>
      </React.Fragment>
    )
  }
}

class IndberetTilSkat extends Component {
  constructor(props) {
    super(props)
    this.store = ProfileStore
    this.storeKeys = ['userData', 'sendingTaxEmail', 'sendTaxResponse']
    this.state = {
      show: false,
      email: '',
      year: new Date().getUTCFullYear() - 1
    }
  }

  handleSubmit() {
    let { email, year } = this.state
    email = email !== '' ? email : this.props.defaultEmail
    ProfileActions.sendTaxEmail(email, year)
  }

  isValidEmail(email) {
    if (email == '') return true
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  toggle(e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState(state => ({ show: !state.show }))
  }

  closePopup() {
    return this.state.show ? this.setState({ show: false }) : null
  }

  render() {
    const classes = {
      display: this.state.show ? 'block' : 'none',
      position: 'absolute',
      backgroundColor: 'white',
      border: 'solid 0px gray',
      zIndex: 10,
      minWidth: '300px'
    }
    const thisYear = new Date().getUTCFullYear()
    const yearOptions = [thisYear - 1, thisYear - 2, thisYear - 3, thisYear - 4, thisYear - 5].map(x => (
      <option key={x} value={x}>
        {x}
      </option>
    ))
    const showErrorAlert = () => {
      if (this.state.sendTaxResponse && this.state.sendTaxResponse.error && this.state.sendTaxResponse.error.message) {
        this.errorMessage = this.state.sendTaxResponse.error.message
        return true
      } else {
        return false
      }
    }
    const showTaxSuccessAlert = () => {
      if (this.state.sendTaxResponse && !this.state.sendTaxResponse.error) {
        return true
      } else {
        return false
      }
    }
    return (
      <OutsideClickListener onOutsideClick={() => this.closePopup()} onInsideClick={_.noop}>
        <div className='btn-group dropdown open'>
          <Button className='standardBtn-black dropdown-toggle onboard-sendpoints' onClick={e => this.toggle(e)}>
            {this.props.text}
          </Button>
          <ul className='dropdown-menu dropdown-menu-right dropdown-animation shadow-2' style={classes}>
            <li className='pt-3 pb-2 pr-3 pl-3'>
              <p className='font-weight-bold'>Email til skattedetaljer</p>
              <p>Få tilsendt en samlet oversigt over dine præmiebestillinger med en vejledende skatteværdi.</p>
              <Alert color='danger' isOpen={showErrorAlert()}>
                <div className='clearfix'>
                  <div className='float-left'>{this.errorMessage}</div>
                  <div className='float-right'>
                    <a className='font-weight-bold' onClick={() => this.setState({ sendTaxResponse: null })}>
                      Luk <FontAwesomeIcon icon='times' />
                    </a>
                  </div>
                </div>
              </Alert>
              <Alert isOpen={showTaxSuccessAlert()}>
                Skattelisten er sendt pr mail til den indtastede adresse
                <Button
                  onClick={() => {
                    this.closePopup()
                    this.setState({ sendTaxResponse: null })
                  }}
                >
                  Luk
                </Button>
              </Alert>
              <input
                className='standard-input'
                type='email'
                placeholder={this.props.defaultEmail}
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
              />
              <Input type='select' className='mb-2 mt-2' onChange={e => this.setState({ year: e.target.value })}>
                {yearOptions}
              </Input>
              <Button className='standardBtn-black' disabled={!this.isValidEmail(this.state.email)} onClick={() => this.handleSubmit()}>
                {this.state.sendingTaxEmail ? 'Sender...' : 'Godkend'}
              </Button>
            </li>
          </ul>
        </div>
      </OutsideClickListener>
    )
  }
}
class OrderTile extends React.Component {
  //const OrderTile = (props) => {
  constructor(props) {
    super(props)
    this.state = {
      showDetail: false
    }
  }
  componentDidMount() {
    objectFitImages()
  }

  handleDetailViewClick() {
    this.setState({ showDetail: !this.state.showDetail })
  }

  render() {
    const {
      OrderID,
      plainOrderID,
      orderType,
      timeInsert,
      orderFlowStatus,
      products,
      orderPricePoints,
      toggleProductTooltip: toggle,
      hoveredProductHash,
      shippingIDs,
      brandSlug,
      userOrderRemarks
    } = this.props
    const hash = x => `${x.productId}-${OrderID}-${x.orderlineId}`
    const imagesTiles = products.map((x, i) => (
      <React.Fragment key={i}>
        <img
          id={'image-' + hash(x)}
          onMouseOver={() => toggle(hash(x))}
          onMouseOut={() => toggle()}
          className='orderImg'
          src={x.imageSrc}
          alt={x.imageName}
        />
        <Tooltip placement='top' isOpen={hash(x) === hoveredProductHash} target={'image-' + hash(x)}>
          <span style={{ fontSize: '0.8em' }}>{x.productTitle}</span>
        </Tooltip>
      </React.Fragment>
    ))
    const orderlines = products.map((x, i) => (
      <React.Fragment key={i}>
        <Row>
          <Col md='1'>
            <p>{x.productId}</p>
          </Col>
          <Col md='7'>
            <p>{x.productTitle}</p>
          </Col>
          <Col md='2'>
            <p>{x.orderAmountPoints}</p>
          </Col>
          <Col md='2'>
            <p>{brandSlug}</p>
          </Col>
        </Row>
      </React.Fragment>
    ))
    let supportClub = ''
    if (brandSlug) {
      supportClub = '+' + brandSlug.toLowerCase()
      if (this.props.UserID) {
        supportClub = supportClub + '-' + this.props.UserID
      }
    }

    let orderDetailView = null
    if (this.state.showDetail) {
      orderDetailView = (
        <OrderDetailView
          {...this.props}
          supportClub={supportClub}
          orderlines={orderlines}
          onHandleViewDetailClick={() => this.handleDetailViewClick()}
        />
      )
    }
    let orderFlag = null
    switch (orderFlowStatus) {
      case 'PREORDER':
        orderFlag = 'Preorder'
        break
      case 'DECLINED':
        orderFlag = 'Afvist'
        break
      case 'REFUNDED':
        orderFlag = 'Refunderet'
        break
      case 'NEW':
        orderFlag = 'Ny'
        break
      case 'TENTATIVE':
        orderFlag = 'Afventer'
        break
      case 'BACKORDER':
        orderFlag = 'Afventer leverandør'
        break
      case 'ORDERED':
        orderFlag = 'Bestilt hos leverandør'
        break
      case 'PACKED':
        orderFlag = 'Pakket'
        break
      case 'SHIPPED':
        orderFlag = 'Sendt'
        break
      case 'RECEIVED':
        orderFlag = 'Modtaget'
        break
      case 'COMPLAINT':
        orderFlag = 'Returneret'
        break
      case 'FAILED':
        orderFlag = 'Fejlet'
        break
      case 'TO_ORDER':
        orderFlag = 'Skal bestilles'
        break
      default:
        orderFlag = 'Ukendt status'
    }

    return (
      <div className='container-fluid'>
        <div className='mb-5 pb-2'>
          <p className='dateStamp'>
            <FontAwesomeIcon icon='clock' /> {moment(timeInsert).format('DD/MM/YYYY HH:mm')}
          </p>
          <div className='gray-bg'>
            <div className='pt-4 pb-4 pl-4 pr-4'>
              <Row>
                <Col md='6'>
                  <h6 onClick={() => this.handleDetailViewClick()} className='show-pointer'>
                    Order: <u>{OrderID}</u>
                  </h6>
                </Col>
                {this.props.showPointsOnOrderTile && (
                  <Col md='6'>
                    <h6 className='text-right'>
                      <b>{orderPricePoints} point</b>
                    </h6>
                  </Col>
                )}
              </Row>
              <Row>
                <Col md='12'>
                  <p>Vi har registreret din ordre med ordrenummeret {OrderID}. Hold øje med din email for afsenderinformationer om din ordre.</p>
                  <div>{imagesTiles}</div>
                  {orderType.toLowerCase() === 'season' &&
                    (orderFlowStatus.toLowerCase() === 'new' || orderFlowStatus.toLowerCase() === 'tentative') && (
                      <React.Fragment>
                        <Badge className='cursor-pointer place-right-top' color='danger' onClick={() => ProfileActions.deleteOrder(plainOrderID)}>
                          {'Slet ordre'}
                        </Badge>
                      </React.Fragment>
                    )}
                  <p style={{ marginBottom: '0px' }} className='text-right'>
                    Status: <span className='orderStatus'>{orderFlag}</span>
                  </p>
                  <p style={{ marginBottom: '0px' }} className='text-right'>
                    Note: <span className='orderStatus'>{userOrderRemarks}</span>
                  </p>
                  {shippingIDs.length > 0 && (
                    <p className='text-right'>
                      Track ordre:
                      {shippingIDs.map((sid, i) => {
                        return (
                          <Link
                            to={'//gls-group.eu/DK/da/find-pakke?match=' + sid}
                            key={i}
                            target='_BLANK'
                            style={{ display: 'inline-block' }}
                            className='orderStatus'
                          >
                            &nbsp;{sid}
                          </Link>
                        )
                      })}
                    </p>
                  )}
                </Col>
              </Row>
              {orderDetailView}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const OrderDetailView = props => (
  <React.Fragment>
    <Modal isOpen toggle={props.onHandleViewDetailClick} size='lg' className='modal-large'>
      <ModalHeader toggle={props.onHandleViewDetailClick}>Ordredetaljer</ModalHeader>
      <ModalBody>
        <Row className='pl-5 pr-5'>
          <Col>
            <h5 className='mb-2'>Faktura</h5>
            <p className='mb-0'>Fakturanummer: {props.OrderID}</p>
            <p className='mb-0'>Faktura dato: {moment(props.timeInsert).format('DD/MM/YYYY HH:mm')}</p>
            <p className='mb-0'>Kundenummer: {props.UserID}</p>
          </Col>
        </Row>
        <Row className='pl-5 pr-5'>
          <Col>
            <div className='pt-4 pb-4 pl-4 pr-4'>
              <Row>
                <Col md='1'>
                  <p>Vare Nr.</p>
                </Col>
                <Col md='7'>
                  <p>Produkt</p>
                </Col>
                <Col md='2'>
                  <p>Point pr. styk</p>
                </Col>
                <Col md='2'>
                  <p>Afsender</p>
                </Col>
              </Row>
              {props.orderlines}
            </div>
          </Col>
        </Row>
        <Row className='text-right'>
          <Col md='8'></Col>
          <Col md='4'>
            <div className='pt-4 pb-4 pl-4 pr-4'>
              <Row>
                <Col>
                  <p className='mb-0'>Subtotal</p>
                </Col>
                <Col>
                  <p className='mb-0'>{props.orderPricePoints}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='mb-0'>Fragt</p>
                </Col>
                <Col>
                  <p className='mb-0'>{props.orderDropShipment}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='mb-0'>At betale</p>
                </Col>
                <Col>
                  <p className='mb-0'>{props.orderPricePoints}</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className='text-right pr-4'>
          <Col>
            <h5>Leveringsinformation</h5>
            <p className='mb-0'>{props.orderConsignee}</p>
            <p className='mb-0'>{props.orderConsigneeName}</p>
            <p className='mb-0'>{props.orderConsigneeAddress1}</p>
            <p className='mb-4'>
              {props.orderConsigneeZipcode} {props.orderConsigneeCity}
            </p>
          </Col>
        </Row>
        <Row className='pl-5 pr-5'>
          <Col>
            <p>
              Hvis du har spørgsmål til din ordre er du velkommen til at kontakte os{' '}
              <OrdersSupportEmail queryParams={'?subject=Ordre%20' + props.OrderID} />
            </p>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  </React.Fragment>
)
