import { configureStore } from '@reduxjs/toolkit'
import shopsReducer from 'Reducers/shopsSlice'
import productsReducer from 'Reducers/productsSlice'
import personalShopperReducer from 'Reducers/personalShopperSlice'
import basketReducer from 'Reducers/basketSlice.js'

export default configureStore({
  reducer: {
    shops: shopsReducer,
    products: productsReducer,
    personalShopper: personalShopperReducer,
    basket: basketReducer
  }
})
