import React from 'react'
import Reflux from 'reflux'
import { Switch, Route } from 'react-router-dom'
import { LoginStore } from 'Stores/loginStore'
import ShopFeature from 'Modules/layout/feature-element/ShopFeature'
import ProfileFeature from 'Modules/layout/feature-element/ProfileFeature'
import HofFeature from 'Modules/layout/feature-element/HofFeature'
import BlogFeature from 'Modules/layout/feature-element/BlogFeature'
import TextAndRightImageShopFeature from 'Modules/layout/feature-element/TextAndRightImageShopFeature'
import _ from 'lodash'

export default class FeatureElement extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }
  render() {
    const { features } = this.state.settings

    return (
      <Switch>
        <Route path='/shop'>
          {!!_.get(features, 'shopFeature') && <ShopFeature />}
          {!!_.get(features, 'textAndRightImageShopFeature') && <Route path='/shop/:id' component={TextAndRightImageShopFeature} />}
        </Route>
        <Route path='/profile'>{!!_.get(features, 'profileFeature') && <ProfileFeature />}</Route>
        <Route path={['/competition', '/halloffame']}>{!!_.get(features, 'hofFeature') && <HofFeature history={this.props.history} />}</Route>
        <Route path='/blog'>{!!_.get(features, 'blogFeature') && <BlogFeature />}</Route>
      </Switch>
    )
  }
}
