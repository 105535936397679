/* eslint-disable eqeqeq */
import React from 'react'
import Reflux from 'reflux'
import { Link } from 'react-router-dom'
import { Button, Carousel, CarouselItem, CarouselIndicators, Container, Col, Row } from 'reactstrap'
import moment from 'moment'
import { FeatureStore, FeatureActions } from 'Stores/featureStore'
import { CompetitionActions } from 'Stores/competitionStore'

export default class HofFeature extends Reflux.Component {
  constructor(props) {
    super(props)
    this.store = FeatureStore
    this.storeKeys = ['halloffame']
    this.state = { activeIndex: 0 }
    CompetitionActions.acceptChallenge.completed.listen(CompetitionID => {
      FeatureActions.getHalloffame(CompetitionID)
      props.history.push(`/competition/${CompetitionID}`)
    })
    CompetitionActions.rejectChallenge.completed.listen(() => FeatureActions.getHalloffame())
  }
  componentDidMount() {
    FeatureActions.getHalloffame()
  }
  onExiting = () => {
    this.animating = true
  }
  onExited = () => {
    this.animating = false
  }
  next = () => {
    let items = this.state.halloffame.competitionData
    if (this.animating) return
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }
  previous = () => {
    let items = this.state.halloffame.competitionData
    if (this.animating) return
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }
  goToIndex = newIndex => {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }
  dummy = () => {
    return false
  }
  render() {
    let hofs = []
    if (this.state.halloffame && this.state.halloffame.competitionData) {
      hofs = this.state.halloffame.competitionData
    }
    if (!Array.isArray(hofs)) {
      hofs = [hofs]
    }
    const { activeIndex } = this.state
    const slides = hofs.map((e, i) => {
      return (
        <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={i + 'x'} className={'competitionBadge'}>
          <div
            className='pt-4 pb-4 pl-4 pr-4'
            style={{
              borderRadius: '15px',
              backgroundColor: 'white',
              maxWidth: '100%',
              wordBreak: 'normal'
            }}
          >
            <p>
              Du er blevet inviteret til konkurrencen{' '}
              <Link to={'/competition/' + e.id} className='font-weight-bold' style={{ color: 'inherit' }}>
                {e.moduleName}
              </Link>
              , det koster <span className='font-weight-bold'>{e.wager} point</span> at deltage, konkurrencen slutter{' '}
              <span className='font-weight-bold'>{moment(e.timeEnd).format('DD/MM/YYYY')}</span>.
            </p>
            <Row>
              <Col>
                <Button
                  className='competitionBtn-reject'
                  style={{ width: '100%' }}
                  onClick={() => {
                    CompetitionActions.rejectChallenge(e.challenge)
                  }}
                >
                  Afvis
                </Button>
              </Col>
              <Col>
                <Button
                  className='competitionBtn-accept'
                  style={{ width: '100%' }}
                  onClick={() => {
                    CompetitionActions.acceptChallenge(e.challenge)
                  }}
                >
                  Accepter
                </Button>
              </Col>
            </Row>
          </div>
        </CarouselItem>
      )
    })
    return (
      <div className='santander-feature'>
        <div className='feature-overlay' />
        <div className='pt-5 pb-5'>
          <Container>
            <Col md='12'>
              <Row>
                <Col md='8'>
                  <h1 className='featureTitle'>Konkurrencer</h1>
                  <p>
                    Her kan du konkurrere mod dine kollegaer. Du kan oprette en konkurrence ved at klikke på “opret konkurrence”-knappen til højre.
                  </p>
                  <p>
                    Hvis en af dine kollegaer allerede har udfordret dig til at deltage i deres konkurrence, kan du se denne ved at klikke på
                    fanebladet med konkurrencens navn nedenfor.
                  </p>
                  <h6>
                    Din nuværende saldo er:{' '}
                    <span className='font-weight-bold'>{String(this.state.halloffame.totalWallet).replace(/(.)(?=(\d{3})+$)/g, '$1.')} point</span>
                  </h6>
                </Col>
                <Col md='4'>
                  {hofs.length > 0 && (
                    <Carousel activeIndex={activeIndex} next={this.dummy} previous={this.dummy}>
                      <CarouselIndicators className={'competitionBadge'} items={slides} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                      {slides}
                      {/*
												<CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
												<CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
												*/}
                    </Carousel>
                  )}
                  {hofs.length == 0 && (
                    <div
                      className='pt-4 pb-4 pl-4 pr-4'
                      style={{
                        borderRadius: '15px',
                        backgroundColor: 'white',
                        maxWidth: '100%',
                        wordBreak: 'normal'
                      }}
                    >
                      <p>
                        Der er ingen konkurrencer du ikke har svaret på - klik på &quot;Opret konkurrence&quot; nedenfor, og udfordr dine kollegaer
                      </p>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </div>
    )
  }
}
