import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function RegisterError(props) {
  return (
    <div className='register-form__error'>
      <FontAwesomeIcon icon='exclamation-triangle' color='#ff3838' size='5x' style={{ display: 'block', margin: 'auto' }} />
      <h4 className='mt-5 mb-3 text-center'>{props.errorMessage}</h4>
      {props.subMessage && <h6 className='text-center' dangerouslySetInnerHTML={{ __html: props.subMessage }}></h6>}
    </div>
  )
}
