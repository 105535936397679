import React from 'react'
import Reflux from 'reflux'
import { LoginStore } from 'Stores/loginStore'
import _ from 'lodash'

export class BrandSupportEmail extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['brandData']
  }

  render() {
    const brandSlug = _.get(this.state, 'brandData.brandSlug')
    const contactEmail = _.get(this.state, 'brandData.contactEmail')
    const queryParams = this.props.queryParams || ''

    const email = contactEmail ? contactEmail : brandSlug ? `support+${brandSlug}@upgrader.club` : `support@upgrader.club`

    return (
      <a className={this.props.className} href={`mailto:${email}${queryParams}`}>
        {this.props.children || email}
      </a>
    )
  }
}

export class OrdersSupportEmail extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['brandData', 'settings']
  }

  render() {
    const brandSlug = _.get(this.state, 'brandData.brandSlug')
    const contactEmail = _.get(this.state, 'brandData.contactEmail')
    const queryParams = this.props.queryParams || ''

    const email = contactEmail
      ? contactEmail
      : brandSlug
      ? `support+${brandSlug}-${this.state.settings.UserID}@upgrader.club`
      : `support@upgrader.club`

    return (
      <a className={this.props.className} href={`mailto:${email}${queryParams}`}>
        {this.props.children || email}
      </a>
    )
  }
}

export function PlainSupportEmail({ className, children }) {
  const email = 'support@upgrader.club'

  return (
    <a className={className} href={`mailto:${email}`}>
      {children || email}
    </a>
  )
}
