import Reflux from 'reflux'
import Proxies from 'Components/proxies'

const ShopActions = Reflux.createActions([
  { refreshShops: { children: ['completed', 'rejected'] } },
  'getShops',
  'getProducts',
  'applyFilter',
  'getChristmasItem',
  'christmasSelect',
  'getBasket',
  'filterBrands',
  'filterCategories',
  'resetFilters',
  'search',
  'sort',
  'toggleLikedProduct'
])

class ShopStore extends Reflux.Store {
  constructor() {
    super()
    this.listenables = ShopActions
    this.startState = {
      shops: null,
      noShops: false,
      currentShopID: 0,
      currentShop: null,
      filteredShop: [],
      filter: '',
      relatedID: null,
      loading: false,
      christmas: null,
      noChristmas: false,
      basket: null,
      categories: [],
      brands: [],
      filterBrand: '',
      filterCategory: '',
      searchTerm: '',
      filters: {
        brandName: null,
        category: null,
        searchTerm: null
      },
      sorting: { fieldName: 'name', direction: 'desc' }
    }
    this.state = this.startState
  }

  refreshShops() {
    Proxies.shopPool().then(responseJSON => {
      if (responseJSON) {
        responseJSON = responseJSON.sort((a, b) => {
          return a['poolOrder'] - b['poolOrder']
        })
        this.setState({ shops: responseJSON })
        ShopActions.refreshShops.completed(responseJSON)
      } else {
        this.setState({ noShops: true })
        ShopActions.refreshShops.rejected(responseJSON)
      }
    })
  }

  naturalCompare(a, b) {
    let ax = [],
      bx = []
    a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) {
      ax.push([$1 || Infinity, $2 || ''])
    })
    b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) {
      bx.push([$1 || Infinity, $2 || ''])
    })
    while (ax.length && bx.length) {
      let an = ax.shift()
      let bn = bx.shift()
      let nn = an[0] - bn[0] || an[1].localeCompare(bn[1])
      if (nn) return nn
    }
    return ax.length - bx.length
  }
  getShops() {
    let nextState = { shops: null, noShops: false }
    Proxies.shopPool().then(responseJSON => {
      this.getBasket()
      if (responseJSON && responseJSON.length) {
        responseJSON = responseJSON.sort((a, b) => {
          return a['poolOrder'] - b['poolOrder']
        })
        nextState.shops = responseJSON
      } else {
        nextState.noShops = true
      }
      this.setState(nextState)
    })
  }
  getProducts(ShopID) {
    this.setState({ currentShopID: ShopID, currentShop: null, loading: true })
    Proxies.shopElements(ShopID).then(responseJSON => {
      if (!responseJSON) return this.setState({ loading: false })

      responseJSON = responseJSON.filter(line => {
        return line.attachments
      })
      let brands = {}
      let categories = {}

      const manufactorersOnly = x => x.categoryTaxonomy.toLowerCase() === 'manufactorer'
      const productsOnly = x => x.categoryTaxonomy.toLowerCase() === 'product'
      const addTo = target => x => (target[x.categoryName] = null)

      responseJSON.forEach(e => {
        const productCategories = e.categories || []
        productCategories.filter(manufactorersOnly).forEach(addTo(brands))
        productCategories.filter(productsOnly).forEach(addTo(categories))

        const reactions = e.reactions || []
        const wishlistReaction = reactions.filter(x => x.reactionType.toLowerCase() === 'wishlist').pop()
        if (wishlistReaction) {
          e.likedReactionId = wishlistReaction.ReactionID
        }
      })

      brands = Object.keys(brands).sort(this.naturalCompare)
      categories = Object.keys(categories).sort(this.naturalCompare)

      let filteredShop = responseJSON
      if (this.state.filterCategory) filteredShop = this.performFilterCategories(this.state.filterCategory, responseJSON)

      this.setState({
        currentShop: responseJSON,
        filteredShop,
        filter: '',
        loading: false,
        categories,
        brands
      })
    })
  }

  applyFilter(filterText) {
    this.setState({ filter: filterText, filterBrand: '', filterCategory: '' })
  }
  resetFilters() {
    this.setState({
      filteredShop: this.state.currentShop,
      filterCategory: null,
      filterBrand: null,
      searchTerm: ''
    })
  }
  filterBrands(brandName) {
    this.setState({ filterBrand: brandName, filter: '', filterCategory: '' })
  }

  performFilterCategories(categoryName, products) {
    if (!products || !categoryName) return

    return products.filter(x => x.categories.map(c => c.categoryName.toLowerCase()).includes(categoryName.toLowerCase()))
  }

  filterCategories(filterCategory) {
    if (filterCategory === this.state.filterCategory) return

    const filteredShop = this.performFilterCategories(filterCategory, this.state.currentShop)
    this.setState({
      filterCategory,
      filter: '',
      filterBrand: '',
      filteredShop,
      searchTerm: ''
    })
  }

  search(searchTerm) {
    const { currentShop, filterCategory } = this.state

    if (searchTerm === '') this.filterCategories(filterCategory)

    const isInCategory = x => x.categories.map(x => x.categoryName.toLowerCase()).includes(filterCategory.toLowerCase())
    const matchesTerm = x =>
      x.elementTitle.toLowerCase().includes(searchTerm.toLowerCase()) || x.elementContent.toLowerCase().includes(searchTerm.toLowerCase())
    let filteredShop = filterCategory ? currentShop.filter(isInCategory).filter(matchesTerm) : currentShop.filter(matchesTerm)

    this.setState({ filteredShop, searchTerm })
  }

  sort(fieldName, direction) {
    const sortAsc = (a, b) => {
      return a > b ? 1 : b > a ? -1 : 0
    }
    const sortDesc = (a, b) => {
      return b > a ? 1 : a > b ? -1 : 0
    }
    const performSort = (a, b) => (direction === 'asc' ? sortAsc(a, b) : sortDesc(a, b))

    const filteredShop = this.state.filteredShop.sort((a, b) => performSort(a[fieldName], b[fieldName]))

    this.setState({ filteredShop, sorting: { fieldName, direction } })
  }

  /* basket stuff */
  getBasket() {
    let nextState = { basket: null }
    Proxies.getBasket().then(responseJSON => {
      if (responseJSON) {
        nextState.basket = responseJSON
      }
      this.setState(nextState)
    })
  }

  /* cristmas stuff */
  getChristmasItem() {
    let nextState = { noChristmas: true, christmas: null }
    Proxies.shopElements('christmas').then(responseJSON => {
      if (responseJSON) {
        if (Array.isArray(responseJSON) && responseJSON.length > 0) {
          nextState.christmas = responseJSON[0]
          nextState.noChristmas = false
        }
      }
      this.setState(nextState)
    })
  }
  christmasSelect(VariationID) {
    let data = { UserID: 0, BrandID: 0, description: 'x' }
    let user = JSON.parse(sessionStorage.user)
    data.UserID = user.UserID
    data.BrandID = user.BrandID
    let reaction = null
    if (this.state.christmas) {
      reaction = this.state.christmas.foundBy
    }
    if (reaction) {
      let tmpData = Object.assign({}, data)
      Proxies.reactionRemove('Variation', reaction.ReactionTargetID, 'christmas', reaction.ReactionID, tmpData).then(() => {
        this.doChristmasSelect(data, VariationID)
      })
    } else {
      this.doChristmasSelect(data, VariationID)
    }
  }
  doChristmasSelect(data, VariationID) {
    Object.assign(data, { description: 'User wants this for christmas' })
    Proxies.reaction('Variation', VariationID, 'christmas', data).then(() => {
      this.getChristmasItem()
    })
  }

  onToggleLikedProduct(product) {
    const { UserID, BrandID } = Reflux.GlobalState.LoginStore.settings
    const ShopID = this.state.currentShopID

    const likeProduct = product => {
      Proxies.reaction('product', product.ProductID, 'wishlist', {
        UserID,
        BrandID,
        reactionData: JSON.stringify({ ShopID }),
        description: 'Fra web2'
      }).then(responseJSON => {
        const tmpArray = [...this.state.currentShop]
        tmpArray.find(x => x.ProductID === product.ProductID).likedReactionId = responseJSON.ReactionID
        this.setState({
          currentShop: tmpArray
        })
      })
    }

    const unLikeProduct = product => {
      const { ProductID, likedReactionId } = product
      Proxies.reactionRemove('product', ProductID, 'wishlist', likedReactionId, {
        removed: 1,
        UserID,
        BrandID,
        description: 'Fra web2'
      }).then(() => {
        let tmpArray = [...this.state.currentShop]
        tmpArray.find(x => x.ProductID === ProductID).likedReactionId = null

        if (ShopID === 'wishlist') {
          tmpArray = tmpArray.filter(x => x.ProductID !== ProductID)
        }

        this.setState({
          currentShop: tmpArray
        })
      })
    }

    return !product.likedReactionId ? likeProduct(product) : unLikeProduct(product)
  }
}

export { ShopActions, ShopStore }
