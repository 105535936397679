import React from 'react'
import useBrandFilters from 'Modules/shop/shopPage/useBrandFilters.js'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, CardBody, CustomInput } from 'reactstrap'

export default function BrandFilters() {
  const [brands, selectedBrands, handleBrandChange] = useBrandFilters()

  return (
    <UncontrolledDropdown style={{ width: '100%' }}>
      <DropdownToggle direction='down' style={{ marginBottom: '1rem', width: '100%' }} className='standardBtn-black'>
        Brands
      </DropdownToggle>
      <DropdownMenu style={{ width: '100%' }}>
        <CardBody style={{ maxHeight: '450px', overflowY: 'auto' }}>
          {brands.map(brand => (
            <div key={brand}>
              <CustomInput
                id={brand}
                checked={selectedBrands.includes(brand)}
                onChange={() => handleBrandChange(brand)}
                type='checkbox'
                label={brand}
              />
            </div>
          ))}
        </CardBody>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
