/* eslint-disable eqeqeq */
export const basketHasRoomForMoreProducts = (basket, shop) => {
  // IMPORTANT: As part of the redux migration this should be part of a redux slice and not a util file
  // button should only show if the basket allows for more products (shop specific)
  if (!basket || !shop) return false

  const productShopSelectionLimit = shop.shopSelectionLimit
  if (!productShopSelectionLimit || productShopSelectionLimit == 0) return true // investigate if limit is always a string before adding ===

  const basketShop = basket.filter(basketItem => basketItem.ShopID === shop.ShopID)
  if (basketShop && basketShop.length >= productShopSelectionLimit) {
    return false
  }
  return true
}
