/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BlogStore, BlogActions } from 'Stores/blogStore'
import { LoginStore } from 'Stores/loginStore'
import ChristmasBlog from './christmasBlog'
import MasonryBlog from './masonryBlog'
import SectionMenu from 'Modules/layout/SectionMenu.js'

export default class Blog extends Component {
  constructor(props) {
    super(props)
    this.stores = [BlogStore, LoginStore]
    this.storeKeys = ['settings', 'blogs', 'noBlogs']
  }
  componentDidMount() {
    if (!this.state.blogs) {
      BlogActions.getBlogs()
    }
  }
  render() {
    if (this.state.noBlogs || (this.state.blogs && !this.state.blogs.length)) {
      return <div className='noBlogs'>Der er ingen blogs</div>
    }
    if (!this.state.blogs) {
      return (
        <center>
          <FontAwesomeIcon icon='circle-notch' className='mt-5' spin />
        </center>
      )
    }
    if (!this.props.match.params.BlogID && !this.state.noBlogs) {
      if (this.state.blogs && Array.isArray(this.state.blogs)) {
        return <Redirect to={'/blog/' + this.state.blogs[0].BlogID} />
      }
    }
    if (
      !this.state.blogs.find(e => {
        return e.BlogID == this.props.match.params.BlogID
      })
    ) {
      return <Redirect to={'/blog/' + this.state.blogs[0].BlogID} />
    }
    if (this.state.settings.BrandID == '11') {
      return (
        <React.Fragment>
          <SectionMenu
            links={(this.state.blogs || []).map(blog => ({
              name: blog.moduleName,
              url: `/blog/${blog.BlogID}`,
              moduleId: blog.BlogID,
              sectionName: 'blog'
            }))}
          />
          <MasonryBlog {...this.props} />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <SectionMenu
          links={(this.state.blogs || []).map(blog => ({
            name: blog.moduleName,
            url: `/blog/${blog.BlogID}`,
            moduleId: blog.BlogID,
            sectionName: 'blog'
          }))}
        />
        {!this.state.settings.christmasShop && <MasonryBlog {...this.props} />}
        {this.state.settings.christmasShop && <ChristmasBlog {...this.props} />}
      </React.Fragment>
    )
  }
}
