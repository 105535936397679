/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dropzone from 'react-dropzone'
import ProfileMenu from 'Modules/profile/ProfileMenu.js'
import { Button, Container, Input, Label, Row, Col } from 'reactstrap'
import { ProfileActions, ProfileStore } from 'Stores/profileStore'

export default class Profile extends Component {
  constructor(props) {
    super(props)
    this.store = ProfileStore
    this.storeKeys = ['profileData']
  }

  componentDidMount() {
    ProfileActions.getProfile()
  }

  handleSaveClick() {
    if (confirm('Ønsker du at gemme dine ændringer?')) ProfileActions.saveProfile()
  }

  render() {
    if (!this.state.profileData) return <FontAwesomeIcon icon='circle-notch' spin />

    let profileData = null
    if (this.state.profileData.user) {
      profileData = []
      let profileFields = this.state.profileData.settings.concat(this.state.profileData.display)

      for (let key in this.state.profileData.user) {
        if (typeof this.state.profileData.user[key] == 'string' && profileFields.indexOf(key) != -1) {
          if (key == 'userNotificationType') {
            profileData.push(
              <React.Fragment key={key}>
                <Label className='mt-3' htmlFor={key}>
                  {translations[key]}
                </Label>
                <Input
                  type='select'
                  disabled={this.state.profileData.settings.indexOf(key) == -1}
                  name={key}
                  id={key}
                  value={this.state.profileData.user[key]}
                  onChange={ProfileActions.updateProfileField}
                >
                  <option>MOBILE</option>
                  <option>EMAIL</option>
                </Input>
              </React.Fragment>
            )
          } else {
            profileData.push(
              <React.Fragment key={key}>
                <div className='profile-form'>
                  <Label htmlFor={key}>{translations[key]}</Label>
                  <Input
                    className='profile-button mb-3'
                    disabled={this.state.profileData.settings.indexOf(key) == -1}
                    name={key}
                    id={key}
                    value={this.state.profileData.user[key]}
                    onChange={ProfileActions.updateProfileField}
                  />
                </div>
              </React.Fragment>
            )
          }
        }
      }
    }
    let userImageURL = 'https://static.upgrader.club/img/userthumb.png'
    if (this.state.profileData.user && this.state.profileData.user.attachments && this.state.profileData.user.attachments.length > 0) {
      userImageURL = this.state.profileData.user.attachments[0].imageOptimizedSource
    }
    return (
      <React.Fragment>
        <ProfileMenu />
        <Container>
          <Row>
            <Col md='8'>
              {profileData}
              <center>
                <Button className='standardBtn-black mt-3' style={{ maxWidth: '250px' }} onClick={this.handleSaveClick}>
                  Gem profil
                </Button>
              </center>
            </Col>
            <Col md='4'>
              <h6>Profilbillede</h6>
              <Dropzone
                style={{ maxWidth: '250px' }}
                accept='image/*'
                onDrop={ProfileActions.updateProfileImage}
                className='standardBtn-black mt-3'
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <center>
                      <img style={{ maxWidth: '100%' }} src={userImageURL} />
                      <input {...getInputProps()} />
                      <Button className='standardBtn-black mt-3' style={{ maxWidth: '250px' }}>
                        Skift profilbillede
                      </Button>
                    </center>
                  </div>
                )}
              </Dropzone>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

const translations = {
  userName: 'Navn',
  userTitle: 'Titel',
  userDepartment: 'Afdeling',
  userCompany: 'Firma',
  userAddress: 'Adresse',
  userZipcode: 'Postnummer',
  userCity: 'By',
  userPhone: 'Telefon',
  userEmail: 'Email',
  userPointsComputed: 'Point',
  userNotificationType: 'Notifikationer via'
}
