import React from 'react'
import Reflux from 'reflux'
import { Button, Col, Row, Modal, ModalHeader, ModalFooter, ModalBody, FormGroup, Input, Label } from 'reactstrap'
import { LoginStore, LoginActions } from 'Stores/loginStore'

export default class CPRModal extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings', 'showCPRModal', 'CPRNumber', 'cprValidationFailed', 'consent']
  }

  render() {
    return (
      <div>
        <Modal isOpen={true}>
          <ModalHeader>CPR nr.</ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for='cpr'>Indtast CPR nr.:</Label>
                  <Input
                    type='text'
                    name='CPRNumber'
                    id='CPRNumber'
                    placeholder='xxxxxx-xxxx'
                    value={this.state.CPRNumber}
                    onChange={LoginActions.updateCPRNumber}
                    required
                    maxLength='11'
                  />
                </FormGroup>
                {this.state.cprValidationFailed && <p style={{ color: 'red', marginLeft: '3px' }}>Venligst indtast korrekt CPR nummer</p>}
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontSize: '15px',
                  marginLeft: '20px',
                  fontWeight: 'bold',
                  marginTop: '10px'
                }}
              >
                CPR nr. skal indtastes for bestilling af præmier. Værdi af point oplyses til SKAT, se mere i{' '}
                <a
                  rel='noopener noreferrer'
                  style={{ color: '#0056b3' }}
                  href='https://drive.google.com/file/d/1ug6LZTtOPZ6AvlFnahxnJ4GGnpxSAmn2/view?usp=sharing'
                  target='_blank'
                >
                  betingelser.
                </a>
              </p>
            </Row>
            <Row>
              <p
                style={{
                  fontSize: '15px',
                  marginLeft: '20px',
                  fontWeight: 'bold',
                  marginTop: '10px'
                }}
              >
                <input type='checkbox' value={this.state.consent} onChange={LoginActions.updateConsent} style={{ marginRight: '12px' }} />
                Jeg godkender hermed, at værdien af mine point oplyses til SKAT.
              </p>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ backgroundColor: '#111111', borderRadius: '5px' }}
              onClick={LoginActions.saveCPRNumber}
              disabled={!this.state.CPRNumber || this.state.cprValidationFailed || this.state.CPRNumber.length !== 11 || !this.state.consent}
            >
              Godkend
            </Button>
            <Button
              style={{ backgroundColor: '#111111', borderRadius: '5px' }}
              onClick={() => {
                LoginActions.closeCPRModal()
                this.props.history.push('/logout')
              }}
            >
              Afvis
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
