import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Status({ loading, error, noShops }) {
  let message
  if (loading) {
    message = <FontAwesomeIcon className='d-block m-auto mt-6' icon='spinner' size='2x' spin />
  }
  if (error) {
    message = (
      <>
        <FontAwesomeIcon icon='exclamation-triangle' color='#ff3838' size='4x' />
        <p className='mt-2'>Der skete desværre en ukendt fejl</p>
      </>
    )
  }
  if (noShops) {
    message = <div>Der er ingen shops</div>
  }

  return <div className='d-block mx-auto mt-6 text-center'>{message}</div>
}
