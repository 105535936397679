import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Product from 'Modules/shop/shared/Product.js'
import { getProducts } from 'Reducers/productsSlice.js'
import Reflux from 'reflux'
import { LoginStore } from 'Stores/loginStore'
import _ from 'lodash'

export default class RefluxWrap extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }

  render() {
    return <OthersHaveAlsoSeen {...this.props} settings={this.state.settings} />
  }
}

function pickRandomProductIds(products) {
  return products
    .map(product => ({ random: Math.random(), product: product }))
    .sort((a, b) => a.random - b.random)
    .slice(0, 3)
    .map(sortingObject => sortingObject.product.productId)
}

function OthersHaveAlsoSeen({ settings }) {
  if (_.get(settings, 'features.shop.disableOthersHaveSeen')) return null
  const products = useSelector(getProducts)
  const { ProductID, ShopID } = useParams()
  const [randomProductIds, setRandomProductIds] = useState([])
  const randomProducts = products.filter(product => randomProductIds.includes(product.productId))

  useEffect(() => {
    setRandomProductIds(pickRandomProductIds(products))
  }, [ShopID, ProductID])

  if (isNaN(ShopID)) {
    // don't show on 'wishlist', not sure if there's other cases so just migrated as is
    return null
  }

  return (
    <div className='gray-bg pt-5 mt-2'>
      <h6 className='header pb-4 text-center'>Andre har også set på</h6>
      <Col xs='12' sm='12' md='12'>
        <Row>
          <Col xs='1' sm='1' md='1'></Col>
          <Col xs='10' sm='10' md='10'>
            <Row>
              {randomProducts.map(product => (
                <Product key={product.productId} product={product} shopId={ShopID} />
              ))}
            </Row>
          </Col>
          <Col xs='1' sm='1' md='1'></Col>
        </Row>
      </Col>
    </div>
  )
}
