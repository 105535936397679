import React from 'react'
import Reflux from 'reflux'
import { Container, Col, Row } from 'reactstrap'
import { FeatureStore, FeatureActions } from 'Stores/featureStore'

export default class ProfileFeature extends Reflux.Component {
  constructor(props) {
    super(props)
    this.store = FeatureStore
    this.storeKeys = ['profile']
  }
  componentDidMount() {
    FeatureActions.getProfile()
  }
  render() {
    if (!Object.keys(this.state.profile).length) {
      return null
    }
    let { userName, orders, wallet, challenges, totalWallet } = this.state.profile
    return (
      <div className='santander-feature'>
        <div className='feature-overlay' />
        <div className='pt-5 pb-5'>
          <Container>
            <Col md='12'>
              <Row>
                <Col xs='hidden' sm='4' md='4' lg='4' className='text-center'>
                  <table style={{ minHeight: '100%', minWidth: '100%' }}>
                    <tbody>
                      <tr>
                        <td valign='middle'>
                          <center>
                            <h6>SALDO</h6>
                            <h4>{String(totalWallet).replace(/(.)(?=(\d{3})+$)/g, '$1.')} point</h4>
                          </center>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md='8'>
                  <h1 className='featureTitle'>Her er dit overblik, {userName}</h1>
                  <p>
                    Brug dashboardet til at skabe dig et hurtigt overblik over dine aktiviteter. For at opsummere hurtigt, så har du i denne måned
                    placeret{' '}
                    <span className='font-weight-bold'>
                      {orders} ordre{orders !== 1 && 'r'}
                    </span>
                    , modtaget <span className='font-weight-bold'>{String(wallet).replace(/(.)(?=(\d{3})+$)/g, '$1.')} point</span> og deltaget i{' '}
                    <span className='font-weight-bold'>
                      {challenges} konkurrence{challenges !== 1 && 'r'}
                    </span>
                    .
                  </p>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </div>
    )
  }
}
