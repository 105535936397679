import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'
import { getShops } from 'Reducers/shopsSlice.js'
import { selectCurrentProduct } from 'Reducers/productsSlice.js'
import OthersHaveAlsoSeen from 'Modules/shop/productPage/OthersHaveAlsoSeen.js'
import ProductPresentation from 'Modules/shop/productPage/ProductPresentation'
import { animateScroll } from 'react-scroll'

export default function ProductPage() {
  const scrollTarget = useRef(null)
  const { ProductID, ShopID } = useParams()
  const shops = useSelector(getShops)
  const currentProduct = useSelector(state => {
    return selectCurrentProduct(state, parseInt(ProductID, 10))
  })

  useEffect(() => {
    if (scrollTarget.current) {
      animateScroll.scrollMore(scrollTarget.current.getBoundingClientRect().y - 100)
    }
  })

  if (!currentProduct) {
    return <Redirect to={'/shop/' + shops[0].ShopID} />
  }

  return (
    <>
      <span ref={scrollTarget} />
      <ProductPresentation ShopID={ShopID} currentProduct={currentProduct} />
      <OthersHaveAlsoSeen />
    </>
  )
}
