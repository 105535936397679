/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import Moment from 'react-moment'
import { Container, Table, Button, Row, Col, Spinner } from 'reactstrap'
import { ProfileActions, ProfileStore } from 'Stores/profileStore'
import ProfileMenu from 'Modules/profile/ProfileMenu.js'
import { slideDown, slideUp } from './anim'
export default class Campaigns extends Component {
  constructor(props) {
    super(props)
    this.store = ProfileStore
    this.storeKeys = ['walletTransactions', 'pointsSummary', 'allCampaigns', 'archivedCampaigns', 'activeCampaigns', 'loading']
    this.columns = [
      { title: 'Name', field: 'moduleName' },
      { title: 'Period', field: 'timeStart' },
      { title: 'Status', field: 'status' }
    ]
    this.showDetail = []
  }

  componentDidMount() {
    ProfileActions.getCampaigns()
  }

  render() {
    return (
      <React.Fragment>
        <ProfileMenu />
        <Container>
          <Table>
            <thead>
              <tr>
                <th>Kampagne navn</th>
                <th>Periode</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.allCampaigns &&
                this.state.allCampaigns.map((campaign, index) => <CampaignDetail campaign={campaign} key={index} index={index + 1}></CampaignDetail>)}
            </tbody>
          </Table>
          <Button
            className='standardBtn-black col-xl-4 col-md-4'
            style={{ backgroundColor: 'black', minHeight: '50px' }}
            onClick={() => ProfileActions.getCampaigns('all')}
          >
            Se inaktive kampagner {this.state.loading && <Spinner className='square-20 button-loading' color='light'></Spinner>}
          </Button>
        </Container>
      </React.Fragment>
    )
  }
}

class CampaignDetail extends Component {
  constructor(props) {
    super(props)
    this.store = ProfileStore
    this.storeKeys = ['loadedCampaigns', 'expanded']
    this.campaignDetail = null
  }

  toggleExpander(id) {
    if (!this.state.expanded) {
      ProfileActions.getCampaignDetail(id)
      this.setState({ expanded: true }, () => {
        if (this.expanderBody) {
          slideDown(this.expanderBody)
        }
      })
    } else {
      slideUp(this.expanderBody, {
        onComplete: () => {
          this.setState({ expanded: false })
        }
      })
    }
  }

  filterActionResults(item) {
    // check if min & max values exist
    if (this.campaignDetail.Campaign.maxSales && this.campaignDetail.Campaign.minSales) {
      // campaign that had min & max criteria set
      if (item.status && !item.status.greaterThanMaximum) {
        return true
      }
    } else {
      // campaign that doesn't have min & max limits
      return true
    }
  }

  displayRewardText() {
    const rewardType = this.campaignDetail.Campaign.rewardType ? this.campaignDetail.Campaign.rewardType.toLowerCase() : null
    const reward = this.campaignDetail.Campaign.reward ? this.campaignDetail.Campaign.reward : ''
    let text = null
    if (rewardType) {
      switch (rewardType) {
        case 'fratræk': // deduct or subtract points
        case 'dividere': // divide the points to a fraction
          text = <p>Du får ekstra point for hvert salg i denne kampagne</p>
          break
        case 'gange': // multiply point by a value
          text = (
            <p>
              Du får <strong>{reward}</strong> gange ekstra point for hvert salg i denne kampagne
            </p>
          )
          break
        case 'dobbelt': // double the points
          text = (
            <p>
              Du får <strong>dobbelt</strong> point i denne kampagne
            </p>
          )
          break
        case 'tilføj': // add specific number of points
        case 'point': // reward specific points
          text = (
            <p>
              Du får <strong>{reward}</strong> ekstra point for hvert salg i denne kampagne
            </p>
          )
          break
        default:
          break
      }
    } else {
      text = reward ? (
        <p>
          Du får <strong>{reward}</strong> ekstra point for hvert salg i denne kampagne
        </p>
      ) : (
        ''
      )
    }
    return text
  }

  statusText(text) {
    switch (text.toUpperCase()) {
      case 'STARTED':
        text = 'Startet'
        break
      case 'ACCEPTED':
        text = 'Accepteret'
        break
      case 'CLOSED':
        text = 'Lukket'
        break
      default:
        break
    }
    return text
  }

  render() {
    let { campaign } = this.props
    if (this.state.loadedCampaigns && this.state.loadedCampaigns.length > 0) {
      this.campaignDetail = this.state.loadedCampaigns.filter(item => {
        return item.Campaign.CampaignID === campaign.CampaignID
      })
      if (this.campaignDetail.length > 0) {
        this.campaignDetail = this.campaignDetail[0]
      }
    }

    return [
      <tr className='cursor-pointer hover-highlight' onClick={() => this.toggleExpander(campaign.CampaignID)} key={campaign.CampaignID}>
        <td className='width-50'>{campaign.moduleName}</td>
        <td className='width-25'>
          <Moment format='DD.MM.YYYY'>{campaign.timeStart}</Moment> - <Moment format='DD.MM.YYYY'>{campaign.timeEnd}</Moment>
        </td>
        <td className='width-20'>{this.statusText(campaign.status)}</td>
        <td className='width-5'>
          {this.state.expanded &&
            !(this.campaignDetail && this.campaignDetail.Campaign && this.campaignDetail.Campaign.CampaignID === campaign.CampaignID) && (
              <Spinner color='dark' className='square-20' />
            )}
        </td>
      </tr>,
      this.state.expanded && this.campaignDetail && this.campaignDetail.Campaign && this.campaignDetail.Campaign.CampaignID === campaign.CampaignID && (
        <tr className='expandable' key='tr-expander'>
          <td className='uk-background-muted' colSpan={6}>
            <div ref={elem => (this.expanderBody = elem)} className='inner uk-grid'>
              {this.campaignDetail && this.campaignDetail.Campaign && (
                <Container>
                  <Row>
                    <Col className='border-right-thin'>
                      <div>
                        <h6>
                          <strong>Produkter inkluderet i kampagne</strong>
                        </h6>
                        {this.campaignDetail.Campaign.products.map(productName => (
                          <p key={productName} className='no-margin'>
                            {productName}
                          </p>
                        ))}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        {this.campaignDetail.Campaign && (
                          <Container>
                            <h6>
                              <strong>Betingelser</strong>
                            </h6>
                            {this.campaignDetail.Campaign && this.campaignDetail.Campaign.rewardType && (
                              <div className='margin-bottom-10'>
                                <p className='no-margin'>Point:</p>
                                <p className='no-margin'>{this.displayRewardText()}</p>
                              </div>
                            )}
                            {this.campaignDetail.Campaign && this.campaignDetail.Campaign.minSales && (
                              <div className='margin-bottom-10'>
                                <p className='no-margin'>Minimum:</p>
                                <p className='no-margin'>
                                  Du skal minimum sælge <strong>{this.campaignDetail.Campaign.minSales}</strong> produkter,som lever op til
                                  kampagnebetingelserne
                                </p>
                              </div>
                            )}
                            {this.campaignDetail.Campaign && this.campaignDetail.Campaign.maxSales && (
                              <div className='margin-bottom-10'>
                                <p className='no-margin'>Maximum:</p>
                                <p className='no-margin'>
                                  Du vil maximalt få inkluderet <strong>{this.campaignDetail.Campaign.maxSales}</strong> salg i denne kampagne
                                </p>
                              </div>
                            )}
                          </Container>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Table size='sm' className='margin-top-35 wallet-table'>
                      <thead>
                        <tr>
                          <th>Opjente point</th>
                          <th>Reference</th>
                          <th>Dato</th>
                          <th>Indsat</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.campaignDetail.CampaignExtra &&
                          this.campaignDetail.CampaignExtra.actionResults &&
                          this.campaignDetail.CampaignExtra.actionResults.length > 0 &&
                          this.campaignDetail.CampaignExtra.actionResults
                            .filter(elem => this.filterActionResults(elem))
                            .map((item, index) => {
                              return [
                                <tr key={index}>
                                  <td>{item.walletAmountPoints}</td>
                                  <td>{item.walletNote}</td>
                                  <td>
                                    <Moment format='DD.MM.YYYY'>{item.DateReferenceTimeStamp}</Moment>
                                  </td>
                                  <td>
                                    {item.status && !item.status.lesserThanMinimum && 'Indsat'}
                                    {item.status && item.status.lesserThanMinimum && 'Afventer'}
                                  </td>
                                </tr>
                              ]
                            })}
                      </tbody>
                    </Table>
                  </Row>
                </Container>
              )}
            </div>
          </td>
        </tr>
      )
    ]
  }
}
