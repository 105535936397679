import React from 'react'
import { ModalBody, ModalHeader } from 'reactstrap'

export default function ShoppingCartConfirmation(props) {
  return (
    <React.Fragment>
      <ModalHeader
        toggle={() => {
          props.toggle()
        }}
        data-cy='shoppingCartConfirmation.header'
      >
        {props.orderId !== '' ? 'Tak for din ordre' : 'Din ordre blev ikke gennemført'}
      </ModalHeader>
      <ModalBody>
        <p>
          {props.orderId !== ''
            ? 'Dit ordrenummer er ' + props.orderId + ' og vi har sendt en bekræftelse til din mail eller mobil.'
            : 'Der opstod følgende problem: ' + props.error}
        </p>
        <p>
          Venlig hilsen
          <br />
          MS Branding A/S
        </p>
      </ModalBody>
    </React.Fragment>
  )
}
