/* eslint-disable eqeqeq */
import Reflux from 'reflux'
import Proxies from 'Components/proxies'
import moment from 'moment'
import 'moment/locale/da'

const CompetitionActions = Reflux.createActions([
  'getCompetition',
  'getCompetitionElements',
  { getCompetitions: { children: ['completed'] } },
  'getAdversaries',
  { rejectChallenge: { children: ['completed'] } },
  { acceptChallenge: { children: ['completed'] } },
  'modifyDirtyCompetition',
  'toggleAdversaries',
  'competitionIdChanged',
  'resetDirtyCompetition',
  { deleteCompetition: { children: ['completed'] } },
  { saveCompetition: { children: ['completed', 'rejected'] } },
  { updateCompetition: { children: ['completed', 'rejected'] } }
])

class CompetitionStore extends Reflux.Store {
  constructor() {
    super()
    this.listenables = CompetitionActions
    this.defaultCompetition = {
      moduleName: '',
      moduleDescription: '',
      timeStart: moment().add(1, 'day'),
      timeEnd: moment().add(2, 'day'),
      wager: '',
      type: 'regular'
    }

    this.state = {
      currentCompetitionID: null,
      competitions: null,
      dirtyCompetition: this.defaultCompetition,
      adversaries: null,
      dirtyAdversaries: [],
      competitionProcess: null,
      isAwaitingResponse: false
    }

    CompetitionActions.saveCompetition.completed.listen(CompetitionID => this._sendChallenges(CompetitionID, this.state.dirtyAdversaries))
    CompetitionActions.updateCompetition.completed.listen(CompetitionID => this._sendChallenges(CompetitionID, this.state.dirtyAdversaries))
  }

  onCompetitionIdChanged = (currentCompetitionID = null) => {
    if (currentCompetitionID == this.state.currentCompetitionID) return

    if (currentCompetitionID == null) {
      return this.setState({
        currentCompetitionID: null,
        dirtyCompetition: this.defaultCompetition,
        dirtyAdversaries: null
      })
    }
    CompetitionActions.getCompetition(currentCompetitionID)
    CompetitionActions.getCompetitions()
    CompetitionActions.getCompetitionElements(currentCompetitionID)
    CompetitionActions.getAdversaries(currentCompetitionID)
    this.setState({ currentCompetitionID })
  }

  onResetDirtyCompetition = (isEditMode = true) => {
    const dirtyCompetition = isEditMode ? this.state.currentCompetition : this.defaultCompetition
    this.setState({
      dirtyCompetition,
      dirtyAdversaries: [],
      adversaries: null
    })
  }

  onModifyDirtyCompetition = props => {
    if (!props) return this.setState({ dirtyCompetition: this.defaultCompetition })

    this.setState(state => ({
      dirtyCompetition: Object.assign({}, state.dirtyCompetition, { ...props })
    }))
  }

  onToggleAdversaries = (adversaries = []) => {
    const { dirtyAdversaries } = this.state

    // toggleAdversaries accepts both an array to be taken literally and a single adversary to be toggled on/off
    if (Array.isArray(adversaries)) return this.setState({ dirtyAdversaries: adversaries })

    // if this adversary is toggled on, remove him/her from the list
    if (dirtyAdversaries.includes(adversaries))
      return this.setState({
        dirtyAdversaries: dirtyAdversaries.filter(x => x !== adversaries)
      })

    // since the adversary is not added, add it to the dirty list
    dirtyAdversaries.push(this.state.adversaries.find(x => x.UserID === adversaries).UserID)

    this.setState({ dirtyAdversaries })
  }

  onGetCompetition = (competitionId = null) => {
    Proxies.competitionFetch(competitionId || this.state.currentCompetitionID).then(responseJSON => {
      const dirtyCompetition = responseJSON
      dirtyCompetition.timeStart = moment(dirtyCompetition.timeStart)
      dirtyCompetition.timeEnd = moment(dirtyCompetition.timeEnd)
      this.setState({ dirtyCompetition, currentCompetition: dirtyCompetition })
    })
  }

  getCompetitions() {
    if (
      Reflux.GlobalState.LoginStore.settings &&
      Reflux.GlobalState.LoginStore.settings.features &&
      Reflux.GlobalState.LoginStore.settings.features.halloffame &&
      Reflux.GlobalState.LoginStore.settings.features.halloffame.indexOf('cvr') == -1
    ) {
      return
    }

    if (this.state.competitions !== null) return

    Proxies.competitionMine().then(responseJSON1 => {
      CompetitionActions.getCompetitions.completed(responseJSON1)
      const competitions = responseJSON1 || []
      return this.setState({ competitions })
    })
  }

  getCompetitionElements(CompetitionID) {
    this.setState({
      currentCompetitionID: CompetitionID,
      competitionProcess: null,
      challenge: null,
      competitionData: null
    })
    this.fetchChallenge(CompetitionID)
    let nextState = {
      competitionProcess: [],
      competitionData: null,
      wagerPool: 0
    }
    Proxies.competitionProcess(CompetitionID).then(responseJSON => {
      if (!responseJSON) return null
      let JSONpackage = responseJSON
      if (!Array.isArray(JSONpackage)) {
        JSONpackage = [JSONpackage]
      }
      JSONpackage = JSONpackage.map(elm => {
        if (elm.UserID == Reflux.GlobalState.LoginStore.settings.UserID) {
          nextState.competitionData = elm
        }

        return {
          rank: elm.rank,
          elementTitle: elm.elementTitle,
          elementImageURL: elm.elementImageURL,
          ruleComputedValue: elm.ruleComputedValue,
          UserID: elm.UserID,
          status: elm.status
        }
      })
      nextState.wagerPool = JSONpackage.length * parseInt(this.state.currentCompetition.wager)
      nextState.competitionProcess = JSONpackage
      this.setState(nextState)
    })
  }

  fetchChallenge(CompetitionID = null) {
    if (!CompetitionID) return

    Proxies.challengeMe(CompetitionID).then(responseJSON => {
      return this.setState({ challenge: responseJSON })
    })
  }

  onGetAdversaries = (CompetitionID = null) => {
    Proxies.competitionAdversaries(CompetitionID).then(responseJSON => {
      if (!responseJSON || responseJSON.exception) return

      let adversaries = responseJSON
        .map(x => Object.assign(x, { UserID: parseInt(x.UserID) }))
        .filter(x => x.UserID !== Reflux.GlobalState.LoginStore.settings.UserID)
      this.setState({ adversaries })
    })
  }

  onSaveCompetition = competition => {
    const dirtyCompetition = competition
    const data = {
      moduleName: dirtyCompetition.moduleName,
      moduleDescription: dirtyCompetition.moduleDescription,
      timeStart: dirtyCompetition.timeStart,
      timeEnd: dirtyCompetition.timeEnd,
      wager: parseInt(dirtyCompetition.wager)
    }

    // in edit mode the dirty competition still has its original competition id
    if (dirtyCompetition.CompetitionID) data.CompetitionID = dirtyCompetition.CompetitionID

    Proxies.competitionCreate(data).then(responseJSON => {
      if (responseJSON.exception || responseJSON.error || !responseJSON.CompetitionID)
        return CompetitionActions.saveCompetition.rejected(responseJSON)

      CompetitionActions.saveCompetition.completed(responseJSON.CompetitionID)
      responseJSON.timeStart = moment(responseJSON.timeStart)
      responseJSON.timeEnd = moment(responseJSON.timeEnd)
      this.setState({
        currentCompetitionID: responseJSON.CompetitionID,
        currentCompetition: responseJSON,
        dirtyCompetition: responseJSON,
        competitions: this.state.competitions.concat(responseJSON)
      })
    })
  }

  onUpdateCompetition = competition => {
    const dirtyCompetition = competition
    const data = {
      CompetitionID: dirtyCompetition.CompetitionID,
      moduleName: dirtyCompetition.moduleName,
      moduleDescription: dirtyCompetition.moduleDescription,
      timeStart: dirtyCompetition.timeStart.format('YYYY-MM-DD HH:MM:SS'),
      timeEnd: dirtyCompetition.timeEnd.format('YYYY-MM-DD HH:MM:SS'),
      wager: parseInt(dirtyCompetition.wager)
    }

    Proxies.competitionUpdate(data).then(responseJSON => {
      if (responseJSON.exception || responseJSON.error) return CompetitionActions.updateCompetition.rejected(responseJSON)

      CompetitionActions.updateCompetition.completed(responseJSON.CompetitionID)

      const indexOfCurrent = this.state.competitions.findIndex(x => parseInt(x.CompetitionID) === parseInt(competition.CompetitionID))
      const newCompetitions = this.state.competitions
      newCompetitions[indexOfCurrent] = responseJSON

      this._sendChallenges(this.state.dirtyAdversaries)
      responseJSON.timeStart = moment(responseJSON.timeStart)
      responseJSON.timeEnd = moment(responseJSON.timeEnd)
      this.setState({
        currentCompetitionID: responseJSON.CompetitionID,
        currentCompetition: responseJSON,
        dirtyCompetition: responseJSON,
        competitions: newCompetitions
      })
    })
  }

  _sendChallenges = (CompetitionID, dirtyAdversaries = []) => {
    dirtyAdversaries.forEach(userId => {
      Proxies.challengeCreate(CompetitionID, userId)
    })
  }

  onDeleteCompetition = CompetitionID => {
    if (!isNaN(CompetitionID)) {
      CompetitionID = parseInt(CompetitionID)
    }
    Proxies.competitionRemove(CompetitionID).then(responseJSON => {
      this.setState({
        currentCompetition: null,
        competitions: this.state.competitions.filter(x => parseInt(x.CompetitionID) !== parseInt(CompetitionID))
      })
      CompetitionActions.deleteCompetition.completed(responseJSON)
    })
  }

  acceptChallenge(challenge) {
    const { ChallengeID, CompetitionID } = challenge

    if (!ChallengeID) {
      return false
    }
    Proxies.challengeAccept(ChallengeID).then(() => {
      CompetitionActions.acceptChallenge.completed(CompetitionID)
      this.fetchChallenge(CompetitionID)
    })
    if (this.state.currentCompetition) {
      const newState = {
        wagerPool: this.state.wagerPool + this.state.currentCompetition.wager
      }
      this.setState(newState)
    }
  }

  rejectChallenge(challenge) {
    const { ChallengeID, CompetitionID } = challenge
    if (!ChallengeID) {
      return false
    }

    Proxies.challengeReject(ChallengeID).then(() => {
      CompetitionActions.rejectChallenge.completed()
      this.fetchChallenge(CompetitionID)
    })
    const newState = {
      wagerPool: this.state.wagerPool - this.state.currentCompetition.wager
    }
    this.setState(newState)
  }
}

export { CompetitionActions, CompetitionStore }
