import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectCategories, setCategory, getCategoryFilter } from 'Reducers/productsSlice.js'
import { Nav, Row, NavItem, NavLink } from 'reactstrap'
import { TrackableFunction } from 'Components/trackableComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

export default function CategoryFilters() {
  const categories = useSelector(selectCategories)
  const selectedCategory = useSelector(getCategoryFilter)
  const dispatch = useDispatch()
  const history = useHistory()

  const setSelectedCategory = (category = '') => {
    const search = category !== '' ? '?c=' + encodeURI(category) : ''
    dispatch(setCategory(category))
    history.push({ search })
    TrackableFunction({
      activityType: 'filter',
      activityName: 'shop',
      activityArgument: category
    })
  }

  return (
    <>
      <Row>
        <div className='sidebar-header mt-4 mb-2'>Kategorier</div>
      </Row>
      <Row>
        <div className='filterScroll'>
          <Nav className='sidebar-menu' vertical>
            {categories.map(category => {
              const isCurrentCategoryActive = category === selectedCategory

              const resetBtn = isCurrentCategoryActive && (
                <div className='resetBtn-wrapper' onClick={() => setSelectedCategory()}>
                  <center>
                    <FontAwesomeIcon icon='times' className='resetBtn' />
                  </center>
                </div>
              )

              return (
                <NavItem key={category}>
                  <NavLink
                    className='float-left'
                    href='#'
                    active={isCurrentCategoryActive}
                    onClick={e => {
                      e.preventDefault()
                      setSelectedCategory(category)
                    }}
                  >
                    {category}
                  </NavLink>
                  {resetBtn}
                </NavItem>
              )
            })}
          </Nav>
        </div>
      </Row>
    </>
  )
}
