import React from 'react'
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { GoudaClaimPointsActions } from 'Stores/goudaClaimPointsStore'
const defaultState = {
  bookingNumber: '',
  numberOfInsurances: '',
  insuranceType: ''
}

export default class GoudaClaimPointsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    GoudaClaimPointsActions.submitForm({
      bookingNumber: this.state.bookingNumber,
      numberOfInsurances: window.parseInt(this.state.numberOfInsurances, 10),
      insuranceType: this.state.insuranceType
    })
    this.setState(defaultState)
  }

  isFormValid = () => {
    const { bookingNumber, numberOfInsurances, insuranceType } = this.state
    return bookingNumber.length && numberOfInsurances.length && insuranceType.length
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for='numberOfInsurances'>Antal forsikringer:</Label>
              <Input
                type='number'
                name='numberOfInsurances'
                id='numberOfInsurances'
                placeholder='Antal solgte forsikringer'
                value={this.state.numberOfInsurances}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for='insuranceType'>Forsikringstype:</Label>
              <Input type='select' name='insuranceType' id='insuranceType' value={this.state.insuranceType} onChange={this.handleInputChange}>
                <option value='' disabled>
                  Vælg forsikringstype
                </option>
                <option value='Rejseforsikring'>Rejseforsikring</option>
                <option value='Afbestillingsforsikring'>Afbestillingsforsikring</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for='bookingNumber'>Reservationsnummer:</Label>
          <Input
            type='text'
            name='bookingNumber'
            id='bookingNumber'
            placeholder='Reservationsnummer'
            value={this.state.bookingNumber}
            onChange={this.handleInputChange}
            autoComplete='off'
          />
        </FormGroup>
        <FormGroup row className='justify-content-center'>
          <Button disabled={!this.isFormValid()} className='standardBtn-black' style={{ maxWidth: 250, fontWeight: 'bold' }}>
            Send
          </Button>
        </FormGroup>
      </Form>
    )
  }
}
