import Reflux from 'reflux'
import Proxies from 'Components/proxies'
const { profileFeature, shopFeature, blogFeature, hofFeature } = Proxies
const FeatureActions = Reflux.createActions(['getProfile', 'getHalloffame', 'getBlog', 'getShop'])

class FeatureStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = FeatureActions
    this.state = {
      profile: {},
      halloffame: {},
      blog: {},
      shop: {}
    }
  }
  getProfile() {
    profileFeature().then(
      profile => {
        this.setState({ profile })
      },
      () => {
        this.setState({ profile: {} })
      }
    )
  }
  getShop() {
    shopFeature().then(
      shop => {
        this.setState({ shop })
      },
      () => {
        this.setState({ shop: {} })
      }
    )
  }
  getBlog() {
    blogFeature().then(
      blog => {
        this.setState({ blog })
      },
      () => {
        this.setState({ blog: {} })
      }
    )
  }
  getHalloffame() {
    hofFeature().then(
      halloffame => {
        this.setState({ halloffame })
      },
      () => {
        this.setState({ halloffame: {} })
      }
    )
  }
}

export { FeatureActions, FeatureStore }
