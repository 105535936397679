import React from 'react'
import Reflux from 'reflux'
import { Container, Row, Col } from 'reactstrap'
import { LoginStore } from 'Stores/loginStore'
import RegisterForm from 'Modules/register/RegisterForm.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RegisterError from 'Modules/register/RegisterError.js'
import RegisterSuccess from 'Modules/register/RegisterSuccess.js'
import keyMirror from 'keymirror'
import Proxies from 'Components/proxies.js'
import { Link } from 'react-router-dom'
const STATUS = keyMirror({
  TAKING_INPUT: null,
  PROCESSING: null,
  SUCCESS: null,
  ERROR: null,
  ERROR_USER_EXISTS: null
})

export default class Register extends Reflux.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: STATUS.TAKING_INPUT
    }
    this.stores = [LoginStore]
    this.storeKeys = ['brandData', 'settings']
  }

  handleFormSubmit = data => {
    this.setState({ status: STATUS.PROCESSING })
    data.brandSlug = this.state.settings.brandSlug
    Proxies.registerUser(data).then(response => {
      if (response.error) {
        if (response.error.statusCode === 409) {
          this.setState({ status: STATUS.ERROR_USER_EXISTS })
        } else {
          this.setState({ status: STATUS.ERROR })
        }
      } else {
        this.setState({ status: STATUS.SUCCESS })
      }
    })
  }

  render() {
    if (!(this.state.brandData && this.state.settings.features.registerUser)) {
      return <FontAwesomeIcon className='register-form__loading-spinner' icon='spinner' size='2x' spin />
    }

    let logo = this.state.brandData.brandLogo ? (
      <Link to='/' className='d-inline-block'>
        <img src={this.state.brandData.brandLogo} className='pb-3 register-form__logo m-auto d-block' alt={this.state.brandData.brandName} />
      </Link>
    ) : null

    return (
      <div className='min-vh-100 bg-white register-form'>
        <Container style={{ padding: '96px 0' }}>
          <Row>
            <Col className='d-flex justify-content-center'>{logo}</Col>
          </Row>
          <Row>
            <Col>
              {this.state.status === STATUS.TAKING_INPUT && <RegisterForm handleSubmit={this.handleFormSubmit} />}
              {this.state.status === STATUS.PROCESSING && (
                <FontAwesomeIcon className='register-form__loading-spinner' icon='spinner' size='4x' spin />
              )}
              {this.state.status === STATUS.SUCCESS && <RegisterSuccess />}
              {this.state.status === STATUS.ERROR && <RegisterError errorMessage='Der skete desværre en ukendt fejl' />}
              {this.state.status === STATUS.ERROR_USER_EXISTS && (
                <RegisterError
                  errorMessage='Du er allerede oprettet i klubben.'
                  subMessage={`Venligst log ind på <a class='link' href='http://${this.state.settings.brandSlug}.upgrader.club'>${this.state.settings.brandSlug}.upgrader.club</a>`}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
