import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoginActions } from 'Stores/loginStore'

export default function Logout() {
  const [isLogoutDone, setLogoutDone] = useState(false)

  useEffect(() => {
    LoginActions.signOut()
    const unsubscribe = LoginActions.signOut.completed.listen(() => {
      setLogoutDone(true)
    })

    return function cleanup() {
      unsubscribe()
    }
  }, [])

  if (isLogoutDone) {
    location.href = '/login'
  }
  return <FontAwesomeIcon icon='spinner' spin />
}
