import React, { useState } from 'react'
import Reflux from 'reflux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import nl2br from 'Components/nl2br'
import { Col, Row, Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap'
import _ from 'lodash'
import { LoginStore } from 'Stores/loginStore'
import SelectProductButton from 'Modules/shop/productPage/SelectProductButton.js'
import SelectProductVariation from 'Modules/shop/productPage/SelectProductVariation.js'
import { ProfileActions, ProfileStore } from 'Stores/profileStore'
import moment from 'moment'

export default class RefluxWrap extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore, ProfileStore]
    this.storeKeys = ['settings', 'isExitsUserSeasonOrder']
  }
  componentDidMount() {
    if (this.state.settings && _.get(this.state.settings, 'features.isSeasonShop')) {
      ProfileActions.getUserSeasonOrders(this.props.ShopID)
    }
  }

  render() {
    return <ProductPresentation {...this.props} settings={this.state.settings} isUserSeasonOrderExists={this.state.isExitsUserSeasonOrder} />
  }
}

function ProductPresentation({ currentProduct, settings, isUserSeasonOrderExists }) {
  const history = useHistory()
  const [selectedVariationId, setSelectedVariationId] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)
  const [autoScroll, setAutoScroll] = useState(true)

  const next = value => {
    if (!value && !autoScroll) return
    if (animating) return
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = value => {
    if (!value && !autoScroll) return
    if (animating) return
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  let images = currentProduct.images.map(image => {
    let src = image.imageSrc
    if (image.imageLarge) {
      src = image.imageLarge
    }
    return { src, altText: image.imageName, caption: '' }
  })

  let selectedVariation = null
  if (selectedVariationId) {
    selectedVariation = currentProduct.variations.find(variation => variation.VariationID === selectedVariationId)
  } else if (currentProduct.variations.length === 1) {
    selectedVariation = currentProduct.variations[0]
  }
  const showImageNavigation = images.length > 1
  const customizedClassName = () => {
    if (_.get(settings, 'features.customShopImageSize') || _.get(settings, 'features.screensizeBasedColumns')) {
      return ' product-image-caraousel '
    }
    return ' w-100 '
  }
  const slides = images.map(item => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.src}>
        <img className={'d-block ' + customizedClassName()} src={item.src} alt={item.altText} />
        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    )
  })
  return (
    <div className='product-tile'>
      <Col md='12'>
        <a
          onClick={() => {
            history.go(-1)
          }}
          className='goBack'
        >
          <FontAwesomeIcon icon='arrow-left' style={{ marginRight: '15px' }} />
          Gå tilbage
        </a>
        <Row>
          <Col md='6' sm='12' xs='12' className='mt-2'>
            <Carousel
              interval={!autoScroll ? false : 5000}
              autoPlay={!autoScroll ? false : true}
              activeIndex={activeIndex}
              next={next}
              previous={previous}
            >
              {showImageNavigation && <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={goToIndex} />}
              {slides}
              {showImageNavigation && <CarouselControl direction='prev' directionText='Previous' onClickHandler={() => previous(true)} />}
              {showImageNavigation && <CarouselControl direction='next' directionText='Next' onClickHandler={() => next(true)} />}
            </Carousel>
            {/* <UncontrolledCarousel pause={false} items={images} controls={showImageNavigation} indicators={showImageNavigation} /> */}
          </Col>
          <Col md='6' sm='12' xs='12'>
            {!_.get(settings, 'features.shop.onlyShowProductTitle') && <h6>{currentProduct.brand}</h6>}
            <div className='title pb-3' style={currentProduct.enableDiscount && { display: 'inline' }}>
              {currentProduct.title}
              {!!currentProduct.isOutOfStock && _.get(settings, 'features.productOutOfStock') && (
                <div
                  className='product-tag'
                  style={{
                    background: '#ff6666',
                    color: 'black',
                    fontSize: '12px',
                    borderRadius: '10px',
                    fontWeight: 'bold',
                    display: 'inline-block',
                    marginLeft: '5px',
                    padding: '5px'
                  }}
                >
                  <span>UDSOLGT</span>
                </div>
              )}
            </div>
            {currentProduct.enableDiscount && (
              <div
                className='product-tag'
                style={{
                  background: '#F3C13A',
                  color: 'black',
                  fontSize: '12px',
                  borderRadius: '4px',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  marginLeft: '10px',
                  paddingRight: '4px',
                  paddingLeft: '4px'
                }}
              >
                <span>TILBUD</span>
              </div>
            )}
            {currentProduct.stockAvailableDate && (
              <div
                className='product-tag'
                style={{
                  background: 'rgb(15, 69, 59)',
                  color: 'white',
                  fontSize: '12px',
                  borderRadius: '4px',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  marginLeft: '10px',
                  paddingRight: '4px',
                  paddingLeft: '4px'
                }}
              >
                <span>På lager den {moment(currentProduct.stockAvailableDate).format('DD.MM')}</span>
              </div>
            )}
            {currentProduct.variations && currentProduct.variations.filter(v => v.allowPreorder).length ? (
              <div
                className='product-tag'
                style={{
                  background: 'yellow',
                  color: 'black',
                  fontSize: '12px',
                  borderRadius: '4px',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  marginLeft: '10px',
                  paddingRight: '4px',
                  paddingLeft: '4px'
                }}
              >
                <span>Preorder</span>
              </div>
            ) : null}
            {!_.get(settings, 'features.shop.onlyShowProductTitle') &&
              !(_.get(settings, 'features.isSeasonShop') && !_.get(settings, 'features.showProdcutPrice')) && (
                <div className='point pb-3'>
                  <span className={currentProduct.enableDiscount && 'discountedPrice'}>{currentProduct.pricePoints} Point </span>
                  {currentProduct.enableDiscount && <span>{currentProduct.discountPrice} Point</span>}
                </div>
              )}
            <SelectProductVariation
              setVariationId={setSelectedVariationId}
              setActiveIndex={setActiveIndex}
              setAutoScroll={setAutoScroll}
              selectedVariationId={selectedVariationId}
              selectedVariation={selectedVariation}
              currentProduct={currentProduct}
              settings={settings}
            />
            <SelectProductButton
              selectedVariation={selectedVariation}
              currentProduct={currentProduct}
              isUserSeasonOrderExists={isUserSeasonOrderExists}
            />
            <div className='description'>
              <p>{nl2br(currentProduct.description)}</p>
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  )
}
