import React from 'react'
import { Component } from 'reflux'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col } from 'reactstrap'
import ProfileMenu from 'Modules/profile/ProfileMenu.js'
import { LoadingContent } from 'Modules/layout/loading'
import { ProfileActions, ProfileStore } from 'Stores/profileStore'

export default class Inbox extends Component {
  constructor(props) {
    super(props)
    this.store = ProfileStore
    this.storeKeys = ['notifications']

    this.state = {
      isAwaitingResponse: false
    }

    ProfileActions.getNotifications.preEmit = () => this.setState({ isAwaitingResponse: true })
    ProfileActions.getNotifications.completed.listen(() => this.setState({ isAwaitingResponse: false }))
  }

  componentDidMount() {
    ProfileActions.getNotifications()
  }

  render() {
    const { notifications } = this.state
    const list = notifications.map((x, i) => <Notification key={i} notification={x} />)

    return (
      <>
        <ProfileMenu />
        <Container className='container-safe-height'>
          <div className='mt-4'>
            <LoadingContent target={!this.state.isAwaitingResponse}>
              <div>{list.length > 0 ? list : <h1>Din indbakke er tom</h1>}</div>
            </LoadingContent>
          </div>
        </Container>
      </>
    )
  }
}

const Notification = props => {
  const {
    communicationChannel,
    communicationRecipient,
    communicationSender,
    timeInsert,
    communicationSubject,
    communicationMessagePlainText
  } = props.notification

  return (
    <div className='container-fluid'>
      <div className='mb-5 pb-2'>
        <p className='dateStamp'>
          <FontAwesomeIcon icon='clock' /> <Moment format='D-MM-YYYY'>{timeInsert}</Moment>
        </p>
        <div className='gray-bg'>
          <div className='pt-4 pb-4 pl-4 pr-4'>
            <Row>
              <Col md='6'>
                <h6>{communicationSubject}</h6>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <p>{communicationMessagePlainText}</p>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <p>
                  Modtager: {communicationRecipient} ({communicationChannel})
                </p>
                <p style={{ margin: '0px' }}>Afsender: {communicationSender}</p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}
