import React from 'react'
import Reflux from 'reflux'
import { LoginStore } from 'Stores/loginStore'
import { ProfileStore } from 'Stores/profileStore'
import { Row } from 'reactstrap'
import ProductSearch from 'Modules/shop/shopPage/ProductSearch.js'
import _ from 'lodash'
import CategoryFilters from 'Modules/shop/shopPage/leftMenu/CategoryFilters.js'
import BrandFilters from 'Modules/shop/shopPage/leftMenu/BrandFilters.js'
import { PersonalShopperButton } from 'Modules/shop/shopPage/PersonalShopper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class LeftMenu extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore, ProfileStore]
    this.storeKeys = ['settings', 'profileData']
  }

  render() {
    let userPoints = 0
    if (_.get(this.state.profileData, 'user.userPointsComputed')) {
      userPoints = this.state.profileData.user.userPointsComputed
    }

    return (
      <div className='sticky-top pt-3'>
        {this.state.settings && this.state.settings.features && this.state.settings.features.enableCategoriesInShop && (
          <a
            style={{
              bottom: '50px',
              position: 'relative',
              right: '16px',
              fontSize: '20px'
            }}
            onClick={() => {
              this.props.history.push(`/shop/${this.props.match.params.ShopID}/categories`)
            }}
            className='goBack'
          >
            <FontAwesomeIcon icon='arrow-left' style={{ marginRight: '15px' }} />
            Gå tilbage
          </a>
        )}
        {this.state.settings.BrandID !== 11 && this.state.settings.features && !this.state.settings.features.isSeasonShop && (
          <React.Fragment>
            <Row>
              <div className='sidebar-header'>Din nuværende saldo</div>
            </Row>
            <center>{<h6 className='font-weight-bold mb-5 pt-3'>{userPoints} point</h6>}</center>
          </React.Fragment>
        )}

        {(!this.state.settings || !this.state.settings.features || !this.state.settings.features.hideSearch) && (
          <>
            <Row>
              <div className='sidebar-header'>SØG</div>
            </Row>
            <Row>
              <ProductSearch />
            </Row>
          </>
        )}
        <PersonalShopperButton />
        {(!this.state.settings || !this.state.settings.features || !this.state.settings.features.hideFilters) && (
          <>
            <CategoryFilters />
            <BrandFilters />
          </>
        )}
      </div>
    )
  }
}
