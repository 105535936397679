/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import Moment from 'react-moment'
import { Line } from 'react-chartjs-2'
import { Container, Row, Col, Button, Table } from 'reactstrap'
import isIE from 'Components/isIE'
import { TrackableClick } from 'Components/trackableComponents'
import { ProfileActions, ProfileStore } from 'Stores/profileStore'
import { LoginStore } from 'Stores/loginStore'
import PointsExpirationSpinner from './PointsExpirationSpinner'

import Dashboard2 from './dashboard2'
import ProfileMenu from 'Modules/profile/ProfileMenu.js'
export default class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.store = LoginStore
    this.storeKeys = ['settings']
  }
  render() {
    if (this.state.settings && this.state.settings.features && this.state.settings.features.isSeasonShop) {
      return <Redirect to={'/profile/orders'} />
    }
    if (isIE()) {
      return <Redirect to={'/profile/settings'} />
    }
    let dashboard = <Dashboard1 />
    if (this.state.settings && this.state.settings.modules && this.state.settings.modules.dashboard) {
      if (this.state.settings.modules.dashboard === 'layout2') {
        dashboard = <Dashboard2 />
      }
    }
    return (
      <>
        <ProfileMenu />
        {dashboard}
      </>
    )
  }
}

class Dashboard1 extends Component {
  constructor(props) {
    super(props)
    this.stores = [ProfileStore, LoginStore]
    this.storeKeys = [
      'timeline',
      'walletTimelineGraphData',
      'pointsSummary',
      'loans',
      'settings',
      'userPointsComputed',
      'allCampaigns',
      'loading',
      'apiResponse'
    ]
  }
  componentDidMount() {
    ProfileActions.getCampaigns()
    ProfileActions.getTimeline()
    ProfileActions.getPoints()
    ProfileActions.getWalletTransactions()
    ProfileActions.getLoans()
  }

  statusText(text) {
    switch (text.toUpperCase()) {
      case 'STARTED':
        text = 'Startet'
        break
      case 'ACCEPTED':
        text = 'Accepteret'
        break
      case 'CLOSED':
        text = 'Lukket'
        break
      default:
        break
    }
    return text
  }

  render() {
    const { timeline, walletTimelineGraphData } = this.state
    const { running_out, running_out_3m, running_out_6m, running_out_beyond_6m } = this.state.pointsSummary
    const timelineItems = (timeline || []).map((x, i) => <TimelineItem key={i} {...x} />)
    let userPoints = 0
    if (this.state.userPointsComputed) {
      userPoints = this.state.userPointsComputed
    }
    return (
      <React.Fragment>
        <Container>
          <div className='ProfileSection'>
            <div className='userCampaignDashboard'>
              {this.state.settings.features && this.state.settings.features.userCampaignDashboard && (
                <Row className='margin-bottom-50 pt-3 pb-3 pl-3 pr-3'>
                  <h6 className='align-center w-100 pb-2'>Du deltager i følgende kampagner:</h6>
                  {!this.state.allCampaigns && this.state.apiResponse && !this.state.loading && (
                    <React.Fragment>
                      <Table size='sm' className='dashboard-campaign'>
                        <thead>
                          <tr>
                            <th>Du har ingen aktive kampagner</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </Table>
                    </React.Fragment>
                  )}
                  {this.state.allCampaigns && this.state.allCampaigns.length > 0 && (
                    <React.Fragment>
                      <Table size='sm' className='dashboard-campaign'>
                        <thead>
                          <tr>
                            <th>Kampagne navn</th>
                            <th>Periode</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.allCampaigns &&
                            this.state.allCampaigns.map(campaign => (
                              <tr key={campaign.CampaignID}>
                                <td className='width-50'>{campaign.moduleName}</td>
                                <td className='width-25'>
                                  <Moment format='DD.MM.YYYY'>{campaign.timeStart}</Moment> - <Moment format='DD.MM.YYYY'>{campaign.timeEnd}</Moment>
                                </td>
                                <td className='width-25'>{this.statusText(campaign.status)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <Link className='w-100 align-center' to='/profile/campaigns'>
                        <Button className='standardBtn-black'>Gå til oversigt</Button>
                      </Link>
                    </React.Fragment>
                  )}
                </Row>
              )}
              <Row>{timelineItems}</Row>
            </div>
            <div className='pointStatus'>
              <div data-cy='dashboard1.pointBalance'>
                <div className='pt-3 pb-3 pl-3 pr-3'>
                  <center>
                    <h6 className='pb-2'>Din nuværende saldo:</h6>
                    <h6 className='font-weight-bold'>{userPoints} point</h6>
                  </center>
                </div>
              </div>
              <PointGraph days={7} graphData={walletTimelineGraphData} />
              {this.state.settings && this.state.settings.features && this.state.settings.features.PointExpiration && (
                <React.Fragment>
                  <h6>PointStatus</h6>

                  <div className='row'>
                    <div className='col-12'>
                      <PointsExpirationSpinner color='#4f9e5e' description='Udløber om mere end 6 måneder' points={running_out_beyond_6m} />
                    </div>
                    <div className='col-12'>
                      <PointsExpirationSpinner color='#efe07b' description='Udløber om 6 måneder' points={running_out_6m} />
                    </div>
                    <div className='col-12'>
                      <PointsExpirationSpinner color='#d13d3d' description='Udløber om 3 måneder' points={running_out_3m} />
                    </div>
                    <div className='col-12'>
                      <PointsExpirationSpinner color='#070707' description='Udløber i denne måned' points={running_out} />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

const TimelineItem = props => {
  const { elementTitle, note, timeUpdate } = props

  return (
    <div className='container-fluid'>
      <div className='mb-5 pb-2'>
        <p className='dateStamp'>
          <FontAwesomeIcon icon='calendar-alt' /> {moment(timeUpdate).format('DD/MM/YYYY HH:mm:ss')}
        </p>
        <div className='gray-bg'>
          <div className='pt-4 pb-4 pl-4 pr-4'>
            <Row>
              <Col md='6'>
                <h6>{elementTitle}</h6>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <p style={{ marginBottom: '0px' }}>{note}</p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

const PointGraph = props => {
  let { graphData, days } = props

  if (!graphData || graphData.length == 0) return null

  let balanceArr = graphData.slice(0, days)
  const data = {
    labels: balanceArr.map(x => x.timeUpdate).reverse(),
    datasets: [
      {
        fill: false,
        data: balanceArr.map(x => x.balance).reverse()
      }
    ]
  }

  const options = {
    animation: {
      duration: 0
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            suggestedMin: 0
          }
        }
      ]
    }
  }

  return (
    <React.Fragment>
      <div className='pointoverview'>
        <h6>Pointoversigt</h6>
        <div className='mt-4 mb-3'>
          <Line data={data} options={options} />
        </div>
        <TrackableClick activityName='Gå til pointoversigt'>
          <Link to='/profile/points'>
            <Button className='standardBtn-black'>Gå til pointoversigt</Button>
          </Link>
        </TrackableClick>
      </div>
    </React.Fragment>
  )
}
