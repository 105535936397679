import React from 'react'
import Reflux from 'reflux'
import { LoginStore } from 'Stores/loginStore'
import { connect } from 'react-redux'
import { togglePersonalShopperOpen, getIsOpen } from 'Reducers/personalShopperSlice.js'
import ReactMarkdown from 'react-markdown'
import _ from 'lodash'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Row } from 'reactstrap'

// ---- Button component ----
class PersonalShopperButtonComponent extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }

  render() {
    let button = null
    if (_.get(this.state.settings, 'features.personalshopper')) {
      button = (
        <Row>
          <Button onClick={() => this.props.togglePersonalShopperOpen()} className='PersonalShopperBtn mt-2'>
            {_.get(this.state.settings, 'FeatureTexts.PersonalShopper.Button') || 'Personal Shopper'}
          </Button>
        </Row>
      )
    }
    return button
  }
}
export const PersonalShopperButton = connect(null, { togglePersonalShopperOpen })(PersonalShopperButtonComponent)

// ---- Banner component ----
class PersonalShopperBannerComponent extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }

  render() {
    let banner = null
    if (_.get(this.state.settings, 'features.personalshopper')) {
      const bannerText =
        _.get(this.state.settings, 'FeatureTexts.PersonalShopper.Banner') || 'Fandt du ikke hvad du ledte efter?\nPrøv vores Personal Shopper'
      banner = (
        <div className='personalShopperBanner mx-2 text-center' onClick={() => this.props.togglePersonalShopperOpen()}>
          {bannerText.split('\n').map((line, i) => (
            <p className='mt-3' key={i}>
              {line}
            </p>
          ))}
        </div>
      )
    }
    return banner
  }
}
export const PersonalShopperBanner = connect(null, { togglePersonalShopperOpen })(PersonalShopperBannerComponent)

// ---- Modal component ----
class PersonalShopperModalComponent extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }

  onModalCloseClick = () => {
    this.props.togglePersonalShopperOpen()
  }

  render() {
    if (!_.get(this.state.settings, 'features.personalshopper')) return null

    let title = 'Personal Shopper'
    let markdown = `Personal shopper text`
    if (this.state.settings.FeatureTexts && this.state.settings.FeatureTexts.PersonalShopper) {
      if (this.state.settings.FeatureTexts.PersonalShopper.Modal) {
        markdown = this.state.settings.FeatureTexts.PersonalShopper.Modal
      }
      if (this.state.settings.FeatureTexts.PersonalShopper.Button) {
        title = this.state.settings.FeatureTexts.PersonalShopper.Button
      }
    }

    for (let key of Object.keys(this.state.settings)) {
      if (markdown.indexOf('{{' + key + '}}') !== -1) {
        let re = new RegExp('{{' + key + '}}', 'g')
        markdown = markdown.replace(re, this.state.settings[key])
      }
    }

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.onModalCloseClick}>
        <ModalHeader toggle={this.onModalCloseClick}>{title}</ModalHeader>
        <ModalBody>
          <ReactMarkdown>{markdown}</ReactMarkdown>
        </ModalBody>
        <ModalFooter>
          <Button className='PersonalShopperBtn' onClick={this.onModalCloseClick}>
            Tilbage til shop
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export const PersonalShopperModal = connect(
  state => ({
    isOpen: getIsOpen(state)
  }),
  { togglePersonalShopperOpen }
)(PersonalShopperModalComponent)
