import React from 'react'
import Reflux from 'reflux'
import { ModalBody, ModalHeader, Container, Row, Col, FormGroup, Label, Button, Input } from 'reactstrap'
import { TrackableClick } from 'Components/trackableComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BasketActions, BasketStore } from 'Stores/basketStore'
import styled from 'styled-components'
import ShoppingCartItem from 'Modules/shop/basket/ShoppingCartItem.js'
import _ from 'lodash'
import { LoginStore } from 'Stores/loginStore'

const StyledErrorMessage = styled.p`
  color: red;
`

export default class ShoppingCartItemViewRefluxWrap extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [BasketStore, LoginStore]
    this.storeKeys = ['basket', 'basketIsLoading', 'settings']
  }

  render() {
    return <ShoppingCartItemView {...this.props} basket={this.state.basket} settings={this.state.settings} />
  }
}

function ShoppingCartItemView(props) {
  const toggleTermsAccepted = () => {
    BasketActions.setAcceptTerms(!props.termsAccepted)
  }
  let addresses = props.addresses
  if (props.settings && props.settings.features && props.settings.features.isSeasonShop) {
    addresses = addresses.filter(address => {
      Object.keys(address).forEach(k => !address[k] && delete address[k])
      if (Object.keys(address).length) {
        return address
      }
    })
  }
  return (
    <React.Fragment>
      <ModalHeader toggle={props.toggle}>Kurv</ModalHeader>
      <ModalBody className='pb-4 px-1 px-sm-5 mb-3'>
        <Container>
          {props.basket.map(basketProduct => {
            return <ShoppingCartItem settings={props.settings} basketProduct={basketProduct} basket={props.basket} key={basketProduct.ProductID} />
          })}
          {!(props.settings && props.settings.features && props.settings.features.isSeasonShop) && (
            <Row>
              <Col xs='6' className='basket-price mt-3 font-weight-bold'>
                Total
              </Col>
              <Col xs='6' className='mt-3 basket-price text-right font-weight-bold'>
                {props.basketTotal} point
              </Col>
            </Row>
          )}
          <Row className='mb-4'>
            <Col>{props.containsUnavailableProducts && <span>Du har et udgået produkt i kurven</span>}</Col>
          </Row>
          {addresses && addresses.length > 0 && (
            <Row>
              <Col>
                <label>
                  <span className='font-weight-bold'>Leveringsadresse</span>
                </label>
              </Col>
            </Row>
          )}
          {addresses && addresses.length > 0 && (
            <Row className='mb-4'>
              <Col md='6'>
                <FormGroup>
                  {addresses && addresses.length === 1 ? (
                    <Label htmlFor='exampleSelect'>
                      {addresses[0].Dealer_Navn} ({addresses[0].Dealer_adresse}, {addresses[0].Dealer_zip} {addresses[0].Dealer_by})
                    </Label>
                  ) : (
                    <Input type='select' name='select' id='exampleSelect' onChange={e => props.onAddressChange(parseInt(e.target.value))}>
                      {(addresses || []).map((x, i) => (
                        <option key={i} value={i}>
                          {x.Dealer_Navn} ({x.Dealer_adresse}, {x.Dealer_zip} {x.Dealer_by})
                        </option>
                      ))}
                    </Input>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}
          {!_.get(props.settings, 'features.isSeasonShop') && !!props.userMissingFields.length && (
            <div>
              <StyledErrorMessage>
                {props.userMissingFields} information mangler i din profil. Disse felter skal udfyldes før din ordre kan gennemføres
              </StyledErrorMessage>
            </div>
          )}
          {!_.get(props.settings, 'features.isSeasonShop') && (
            <Row className='mb-2'>
              <Col>
                <div className='checkbox'>
                  <input onChange={toggleTermsAccepted} checked={props.termsAccepted} type='checkbox' id='checkbox_1' />
                  <label htmlFor='checkbox_1' data-cy='shoppingCartItemView.terms'>
                    Jeg har læst{' '}
                    <a
                      href={
                        props.TermsConditionsURL
                          ? props.TermsConditionsURL
                          : 'https://docs.google.com/document/u/1/d/1gP7gBFm5WqepbH2GSnYcoREe2XKQHcdGDzB3K33HyCE/pub'
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                      alt='terms and conditions'
                    >
                      <span className='font-weight-bold' style={{ textDecoration: 'underline' }}>
                        Handelsbetingelserne
                      </span>
                    </a>
                  </label>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <center>
                <TrackableClick activityName='ConfirmOrder'>
                  <Button
                    disabled={props.submitButtonDisabled}
                    onClick={props.handleOrderClick}
                    className='standardBtn-black'
                    style={{ maxWidth: '250px' }}
                    data-cy='shoppingCartItemView.orderButton'
                  >
                    {props.placingOrder ? (
                      <FontAwesomeIcon icon='circle-notch' spin />
                    ) : props.hasInsufficientPoints ? (
                      'Du har ikke nok point'
                    ) : (
                      'Bestil'
                    )}
                  </Button>
                </TrackableClick>
              </center>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </React.Fragment>
  )
}
