import React from 'react'
import { Row, Col } from 'reactstrap'
import LeftMenu from 'Modules/shop/shopPage/leftMenu/LeftMenu'
import ProductList from 'Modules/shop/shopPage/ProductList.js'
import { PersonalShopperModal } from 'Modules/shop/shopPage/PersonalShopper'
import { LoginStore } from 'Stores/loginStore'

export default function ThreeByNineLayout({ props }) {
  const hideFilters =
    LoginStore.state && LoginStore.state.settings && LoginStore.state.settings.features && LoginStore.state.settings.features.hideFilters
  const hideSearch =
    LoginStore.state && LoginStore.state.settings && LoginStore.state.settings.features && LoginStore.state.settings.features.hideSearch
  const isSeasonShop =
    LoginStore.state && LoginStore.state.settings && LoginStore.state.settings.features && LoginStore.state.settings.features.isSeasonShop

  const hideSideMenu = hideFilters && hideSearch && isSeasonShop

  return (
    <>
      <Row>
        {!hideSideMenu && (
          <Col md={3}>
            <LeftMenu {...props} />
          </Col>
        )}
        <Col md={!hideSideMenu ? '9' : '12'}>
          <ProductList {...props} />
        </Col>
      </Row>
      <PersonalShopperModal />
    </>
  )
}
