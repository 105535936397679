import React, { useState } from 'react'
import { ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu, Col, Row } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setSorting, getSortingSettings } from 'Reducers/productsSlice.js'

const StyledCol = styled(Col)`
  max-width: 115px;
`

export default function ProductSort() {
  const [isOpen, setOpen] = useState(false)
  const dispatch = useDispatch()
  const sortSettings = useSelector(getSortingSettings)

  const checkIsActive = (field, direction) => {
    return sortSettings.sortingFieldName === field && sortSettings.sortingDirection === direction
  }

  return (
    <Row className='justify-content-Start'>
      <StyledCol>
        <ButtonDropdown isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
          <DropdownToggle className='transparent-btn pb-3' caret>
            Sorter
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: checkIsActive('pricePoints', 'desc') ? '800' : '400'
                }}
                onClick={() => dispatch(setSorting({ sortingFieldName: 'pricePoints', sortingDirection: 'desc' }))}
              >
                Point høj-lav
              </div>
            </DropdownItem>
            <DropdownItem header>
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: checkIsActive('pricePoints', 'asc') ? '800' : '400'
                }}
                onClick={() => dispatch(setSorting({ sortingFieldName: 'pricePoints', sortingDirection: 'asc' }))}
              >
                Point lav-høj
              </div>
            </DropdownItem>
            <DropdownItem header>
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: checkIsActive('title', 'asc') ? '800' : '400'
                }}
                onClick={() => dispatch(setSorting({ sortingFieldName: 'title', sortingDirection: 'asc' }))}
              >
                Alfabetisk A-Z
              </div>
            </DropdownItem>
            <DropdownItem header>
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: checkIsActive('title', 'desc') ? '800' : '400'
                }}
                onClick={() => dispatch(setSorting({ sortingFieldName: 'title', sortingDirection: 'desc' }))}
              >
                Alfabetisk Z-A
              </div>
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </StyledCol>
    </Row>
  )
}
