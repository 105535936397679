import React from 'react'
import Reflux from 'reflux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Col } from 'reactstrap'
import cx from 'classnames'
import { TrackableClick } from 'Components/trackableComponents'
import objectFitImages from 'object-fit-images'
import _ from 'lodash'
import { LoginStore } from 'Stores/loginStore'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { likeProduct, unLikeProduct } from 'Reducers/productsSlice.js'
import moment from 'moment'

class Product extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }

  componentDidMount() {
    objectFitImages()
  }
  componentDidUpdate() {
    objectFitImages()
  }

  handleWishlistClick = () => {
    if (this.props.product.likedReactionId) {
      this.props.unLikeProduct(
        this.props.product.productId,
        this.state.settings.UserID,
        this.state.settings.BrandID,
        this.props.product.likedReactionId
      )
    } else {
      this.props.likeProduct(this.props.product.productId, this.state.settings.UserID, this.state.settings.BrandID, this.props.shopId)
    }
  }

  render() {
    let productFlag = null
    switch (this.props.product.flag) {
      case 'LATEST':
        productFlag = (
          <div className='product-tag'>
            <span>Nyhed</span>
          </div>
        )
        break
      case 'SOCIAL':
        productFlag = (
          <div className='product-tag'>
            <span>Populær</span>
          </div>
        )
        break
      case 'SALE':
        productFlag = (
          <div className='product-tag' style={{ background: '#F3C13A', color: 'black', fontWeight: 'bold' }}>
            <span>TILBUD</span>
          </div>
        )
        break

      default:
        productFlag = null
    }
    if (this.props.product.isOutOfStock && this.state.settings && this.state.settings.features && this.state.settings.features.productOutOfStock) {
      productFlag = (
        <div className='product-tag' style={{ background: '#ff6666', color: 'black', fontWeight: 'bold' }}>
          <span>Udsolgt</span>
        </div>
      )
    }
    if (this.props.product && this.props.product.stockAvailableDate) {
      productFlag = (
        <div className='product-tag' style={{ background: 'rgb(15, 69, 59)', color: 'white', fontWeight: 'bold' }}>
          <span>På lager den {moment(this.props.product.stockAvailableDate).format('DD.MM')}</span>
        </div>
      )
    }
    if (this.props.product && this.props.product.variations) {
      let preOrderVar = this.props.product.variations.filter(variation => variation.allowPreorder)
      if (preOrderVar.length) {
        productFlag = (
          <div className='product-tag' style={{ background: 'yellow', color: 'black', fontWeight: 'bold' }}>
            <span>Preorder</span>
          </div>
        )
      }
    }
    const { images, pricePoints, title, likedReactionId, productId, discountPrice } = this.props.product
    let { imageName, imageSrc, ...image } = images && images.length ? images[0] : { imageName: null, imageSrc: null }
    if (image.imageMedium) {
      imageSrc = image.imageMedium
    } else if (image.imageLarge) {
      imageSrc = image.imageLarge
    }

    let wishlist = null
    if (_.get(this.state.settings, 'features.shop.wishlist')) {
      const solidOrOutline = likedReactionId !== null ? 'fas' : 'far'
      wishlist = (
        <span
          style={{
            position: 'absolute',
            zIndex: '999',
            right: '10px',
            top: '10px'
          }}
        >
          <span className={'shopWishlistHeart'}>
            <div>
              <TrackableClick activityName={likedReactionId === null ? 'like' : 'unlike'} activityArgumentID={productId}>
                <FontAwesomeIcon
                  onClick={this.handleWishlistClick}
                  icon={[solidOrOutline, 'heart']}
                  style={{ fontSize: '18px', cursor: 'pointer' }}
                  className={'pulsate-bck'}
                  data-cy='shop.addToWishList'
                />
              </TrackableClick>
              <i />
            </div>
          </span>
        </span>
      )
    }

    const handleClick = e => {
      if (this.props.product.productStatus === 'UNAVAILABLE') {
        e.preventDefault()
      }
    }

    const customizedClassName = () => {
      if (_.get(this.state.settings, 'features.customShopImageGrid')) {
        return ' productList '
      }
      if (_.get(this.state.settings, 'features.screensizeBasedColumns')) {
        return ' normalShopProductList '
      }
      return ''
    }

    return (
      <Col
        className={customizedClassName()}
        sm='6'
        md={
          _.get(this.state.settings, 'features.customShopImageGrid') ? '3' : _.get(this.state.settings, 'features.screensizeBasedColumns') ? '4' : '6'
        }
        lg={
          _.get(this.state.settings, 'features.customShopImageGrid') ? '3' : _.get(this.state.settings, 'features.screensizeBasedColumns') ? '4' : '4'
        }
        xl={
          _.get(this.state.settings, 'features.customShopImageGrid') ? '2' : _.get(this.state.settings, 'features.screensizeBasedColumns') ? '3' : '4'
        }
      >
        <div className={cx(['plain-product-tile mb-2 ', this.props.product.productStatus.toLowerCase()])} data-cy='shop.product'>
          {wishlist}
          <div className='image-wrapper'>
            <TrackableClick activityName='shop' activityArgumentID={this.props.product.productId}>
              <Link
                to={
                  _.get(this.state.settings, 'features.enableCategoriesInShop')
                    ? '/shop/' + this.props.shopId + `/categories/${this.props.product.categoryName}/` + this.props.product.productId
                    : '/shop/' + this.props.shopId + '/' + this.props.product.productId
                }
                onClick={handleClick}
              >
                <img src={imageSrc} alt={imageName} className='product-image' data-cy='shop.productImage' />
              </Link>
            </TrackableClick>
            {productFlag}
          </div>
          <div className='plain-body'>
            <div className='plain-title'>
              {!_.get(this.state.settings, 'features.shop.onlyShowProductTitle') &&
                !(_.get(this.state.settings, 'features.isSeasonShop') && !_.get(this.state.settings, 'features.showProdcutPrice')) && (
                  <>
                    <div>
                      <h6 className='productPrice'>
                        <span data-cy='shop.productPrice' className={this.props.product.flag === 'SALE' && 'discountedPrice'}>
                          {pricePoints} Point
                        </span>
                        {this.props.product.flag === 'SALE' && <span> {discountPrice} Point</span>}
                      </h6>
                      <h6 className='productBrand'>{this.props.product.brand}</h6>
                    </div>
                    <div className='clearfix' />
                  </>
                )}
              <div>
                <TrackableClick activityName='shop' activityArgumentID={this.props.product.productId}>
                  <Link
                    to={
                      _.get(this.state.settings, 'features.enableCategoriesInShop')
                        ? '/shop/' + this.props.shopId + `/categories/${this.props.product.categoryName}/` + this.props.product.productId
                        : '/shop/' + this.props.shopId + '/' + this.props.product.productId
                    }
                    onClick={handleClick}
                  >
                    <p style={{ marginBottom: '0px' }}>{title}</p>
                  </Link>
                </TrackableClick>
                {this.props.product.productStatus === 'UNAVAILABLE' && <p className='productUnavailable'>Produkt er udgået</p>}
              </div>
            </div>
          </div>
        </div>
      </Col>
    )
  }
}

Product.propTypes = {
  product: PropTypes.object.isRequired,
  likeProduct: PropTypes.func.isRequired,
  unLikeProduct: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired
}

export default connect(null, { likeProduct, unLikeProduct })(Product)
