import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { BREAKPOINTS } from 'Components/mq.js'
import _ from 'lodash'
import { BasketActions } from 'Stores/basketStore'
import { basketHasRoomForMoreProducts } from 'Modules/shop/shared/util.js'
import { TrackableClick } from 'Components/trackableComponents'
import { selectShop } from 'Reducers/shopsSlice.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StyledBasketItemRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const StyledBasketItemImage = styled.img`
  width: 50px;
  height: 50px;
`

const StyledBasketItemContentArea = styled.div`
  flex-grow: 1;
  position: relative;

  @media (min-width: ${BREAKPOINTS.md}) {
    display: flex;
  }
`

const StyledBasketItemTitle = styled.div`
  @media (min-width: ${BREAKPOINTS.md}) {
    width: 250px;
  }
`

const StyledBasketItem = styled.div`
  position: relative;
  padding-right: 28px; // Making space for trash icon
  display: flex;
  flex-grow: 1;
`

const StyledItemCount = styled.div`
  display: inline-block;
  cursor: pointer;
`

const StyledPointValue = styled.div`
  display: inline-block;
  flex-grow: 1;
`

const StyledDeleteItem = styled.div`
  position: absolute;
  right: 8px;
  top: 0;
  cursor: pointer;
`

export default function ShoppingCartItem({ basketProduct, basket, settings }) {
  const activeShop = useSelector(state => selectShop(state, basketProduct.ShopID))
  let price = basketProduct.product.enableDiscount ? basketProduct.product.discountPointComputed : basketProduct.product.productPointComputed
  let thisProductTotal = basketProduct.amount * price
  let variationTitle = null
  if (_.get(basketProduct.variation, 'variationTitle')) {
    variationTitle = (
      <React.Fragment>
        <br />
        <span className='variationTitle'>{basketProduct.variation.variationTitle}</span>
      </React.Fragment>
    )
  }
  const outOfStockMessage = basketProduct.product.productStatus !== 'AVAILABLE' ? '(Midlertidligt udsolgt)' : null

  const hasRoomForMore = basketHasRoomForMoreProducts(basket, activeShop)
  const plusProps = {
    color: hasRoomForMore ? 'black' : 'gray',
    onClick: hasRoomForMore
      ? () => BasketActions.incrementBasketItemCount(basketProduct.ShopID, basketProduct.ProductID, basketProduct.VariationID)
      : _.noop
  }

  return (
    <Row className='wg-row justify-content-end'>
      <Col>
        <StyledBasketItemRow>
          <StyledBasketItemImage
            src={
              _.get(basketProduct.variation, 'attachments') && basketProduct.variation.attachments.length
                ? basketProduct.variation.attachments[0].imageOptimizedSourceSmall
                : _.get(basketProduct.product, 'attachments[0].imageOptimizedSourceSmall')
            }
          />
          <StyledBasketItemContentArea className='p-2 pl-3'>
            <StyledBasketItemTitle>
              <Link to={'/shop/' + basketProduct.ShopID + '/' + basketProduct.ProductID}>
                {basketProduct.product.elementTitle}
                <span>
                  {variationTitle} {outOfStockMessage}
                </span>
              </Link>
            </StyledBasketItemTitle>
            <StyledBasketItem className='ml-md-4 mt-1 mt-md-0'>
              <StyledItemCount>
                {!!basketHasRoomForMoreProducts(basket, activeShop) && (
                  <TrackableClick activityName='decreasedProductCount'>
                    <FontAwesomeIcon
                      style={{ fontSize: '14px' }}
                      className='mr-2'
                      icon='minus'
                      onClick={() => BasketActions.decrementBaksetItemCount(basketProduct.ShopID, basketProduct.ProductID, basketProduct.VariationID)}
                    />
                  </TrackableClick>
                )}
                {basketProduct.amount}
                {!!basketHasRoomForMoreProducts(basket, activeShop) && (
                  <TrackableClick activityName='increasedProductCount'>
                    <FontAwesomeIcon
                      style={{
                        fontSize: '14px',
                        color: plusProps.color
                      }}
                      className='ml-2 mr-2'
                      icon='plus'
                      onClick={plusProps.onClick}
                    />
                  </TrackableClick>
                )}
              </StyledItemCount>
              {!(settings && settings.features && settings.features.isSeasonShop) && (
                <StyledPointValue className='text-center'>{thisProductTotal} point</StyledPointValue>
              )}
              <StyledDeleteItem>
                <TrackableClick activityName='removedProductFromBasket'>
                  <FontAwesomeIcon
                    style={{ fontSize: '14px' }}
                    icon='trash'
                    onClick={() => BasketActions.removeFromBasket(basketProduct.ShopID, basketProduct.ProductID, basketProduct.VariationID)}
                  />
                </TrackableClick>
              </StyledDeleteItem>
            </StyledBasketItem>
          </StyledBasketItemContentArea>
        </StyledBasketItemRow>
      </Col>
    </Row>
  )
}
