import React from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import _ from 'lodash'

export default function SelectProductVariation({
  setVariationId,
  setActiveIndex,
  setAutoScroll,
  selectedVariationId,
  selectedVariation,
  currentProduct,
  settings
}) {
  if (_.get(settings, 'features.shop.disableAddProduct')) return null

  const showVariationStock = () => {
    return !!_.get(selectedVariation, 'variationStockable')
  }

  const showPreorderStock = () => {
    return !!_.get(selectedVariation, 'allowPreorder') && _.get(selectedVariation, 'preorderStock') && _.get(selectedVariation, 'preorderStock') >= 0
  }

  return (
    <>
      {currentProduct.variations.length === 1 && showVariationStock() && <p>{selectedVariation.variationStock} stk. på lager</p>}
      {currentProduct.variations.length === 1 && showPreorderStock() && (
        <p>{selectedVariation.preorderStock === 0 ? 'Ikke muligt at bestille' : selectedVariation.preorderStock + ' stk. kommer på lager'}</p>
      )}
      {currentProduct.variations.length > 1 && !_.get(settings, 'features.shop.disableAddProduct') && (
        <Row>
          <Col md='12'>
            <div className='variation'>
              {showVariationStock() && <p>{selectedVariation.variationStock} stk. på lager</p>}
              {showPreorderStock() && <p>{selectedVariation.preorderStock} stk. på lager</p>}
              <UncontrolledDropdown>
                <DropdownToggle className={'select-variation-btn'}>
                  {selectedVariation ? selectedVariation.variationTitle : 'Vælg variation'}
                </DropdownToggle>
                <DropdownMenu>
                  {currentProduct.variations.map(variation => {
                    return (
                      <DropdownItem
                        key={variation.VariationID}
                        onClick={() => {
                          setVariationId(variation.VariationID)
                          if (variation && variation.attachments && variation.attachments.length && variation.attachments[0].activeIndex >= 0) {
                            setActiveIndex(variation.attachments[0].activeIndex)
                            setAutoScroll(false)
                          } else {
                            setAutoScroll(true)
                          }
                        }}
                        disabled={variation.VariationID === selectedVariationId || (!!variation.variationStockable && variation.variationStock <= 0)}
                      >
                        {variation.variationTitle} {variation.variationStockable && variation.variationStock <= 0 ? ' - Ikke på lager' : ''}
                        {variation.allowPreorder ? (
                          <span style={{ color: 'black', backgroundColor: 'yellow', padding: 4, fontSize: 10 }}>PREORDER</span>
                        ) : (
                          ''
                        )}
                        {variation.preorderStock === 0 ? ' - Ikke muligt at bestille' : ''}
                      </DropdownItem>
                    )
                  }, this)}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
        </Row>
      )}
    </>
  )
}
