/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import objectFitImages from 'object-fit-images'
import { Container, Row, Col } from 'reactstrap'
import { LoadingRow } from 'Modules/layout/loading'
import { HofActions, HofStore } from 'Stores/hofStore'
import { ProfileActions, ProfileStore } from 'Stores/profileStore'
import { LoginStore } from 'Stores/loginStore'
import { CompetitionButton } from 'Modules/halloffame/competition'
import SectionMenu from 'Modules/layout/SectionMenu.js'
import { CompetitionActions, CompetitionStore } from 'Stores/competitionStore'

const getHofIdFromQuery = location => location.pathname.split('/')[2]

export default class Halloffame extends Component {
  constructor(props) {
    super(props)
    this.stores = [CompetitionStore, HofStore, ProfileStore, LoginStore]
    this.storeKeys = ['hofs', 'competitions', 'currentHofId', 'currentHof', 'isLoading', 'settings']
  }

  componentDidMount() {
    CompetitionActions.getCompetitions()
    ProfileActions.getWalletTransactions()
    this.updateElements()
    objectFitImages()
  }

  componentDidUpdate() {
    this.updateElements()
  }

  updateElements = () => {
    const hofId = getHofIdFromQuery(this.props.location)

    if (hofId == this.state.currentHofId) return

    HofActions.getHofs()
    HofActions.getHofElements(hofId)
  }

  getLinks = () => {
    const hofs = (this.state.hofs || []).map(e => ({
      name: e.moduleName,
      url: `/halloffame/${e.HalloffameID}`,
      moduleId: e.HalloffameID,
      sectionName: 'halloffame'
    }))
    const comps = (this.state.competitions || []).map(e => ({
      name: e.moduleName,
      url: `/competition/${e.CompetitionID}`,
      moduleId: e.CompetitionID,
      sectionName: 'competition'
    }))
    return hofs.concat(comps)
  }

  render() {
    let currentHof = this.state.currentHof
    if (!Array.isArray(currentHof)) {
      currentHof = []
    }
    let hallOfFameElements = currentHof.map((x, i) => {
      const { rank, elementImageURL, elementTitle, ruleComputedValue, userCompany } = x
      return (
        <Row className='wg-row' key={i}>
          <Col md='1' className='text-right align-middle pt-3'>
            {rank}
          </Col>
          <Col md='2' className='text-center'>
            <img
              src={elementImageURL || 'https://static.upgrader.club/img/userthumb.png'}
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50px',
                objectFit: 'cover'
              }}
              className='userImage hofImage'
              alt={elementTitle}
            />
          </Col>
          <Col className='text-left pt-3'>{elementTitle}</Col>
          {this.state.settings.features && this.state.settings.features.hofCompanyName && <Col className='text-left pt-3'>{userCompany}</Col>}
          <Col md='2' className='text-right pt-3'>
            {ruleComputedValue}
          </Col>
        </Row>
      )
    })
    const enableCreateCompetition = this.state.settings.features && this.state.settings.features.CreateCompetition
    if (hallOfFameElements.length === 0) {
      hallOfFameElements = (
        <Row className='wg-row'>
          <Col className='text-center align-middle'>
            {enableCreateCompetition ? 'Du har ingen aktive konkurrencer' : 'Du har ingen aktive visninger'}
          </Col>
        </Row>
      )
    }
    return (
      <>
        <SectionMenu links={this.getLinks()} />
        <Container className='container-safe-height'>
          {enableCreateCompetition && (
            <div className='clearfix'>
              <div className='float-right'>
                <CompetitionButton>Opret konkurrence</CompetitionButton>
              </div>
            </div>
          )}
          <Row>
            <Col xs='12'>
              <LoadingRow target={!this.state.isLoading}>{hallOfFameElements}</LoadingRow>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
