import Reflux from 'reflux'
import Proxies from 'Components/proxies'
const { activity } = Proxies
const shajs = require('sha.js')
import moment from 'moment'

const ActivityActions = Reflux.createActions(['logActivity'])

class ActivityStore extends Reflux.Store {
  constructor() {
    super()
    this.listenables = ActivityActions
    this.state = {
      instanceKey: sessionStorage.instanceKey
    }
  }
  createInstanceKey() {
    let baseCalc = localStorage.session
    let random = shajs('sha256')
      .update(Math.random())
      .digest('hex')
    baseCalc = baseCalc + 'WEB' + random + Date.now()
    let instanceKey = shajs('sha256')
      .update(baseCalc)
      .digest('hex')
    sessionStorage.instanceKey = instanceKey
    return instanceKey
  }
  logActivity(activityType, activityName, activityArgumentID, activityArgument = '', data = {}) {
    let instanceKey = sessionStorage.instanceKey
    if (
      !moment(sessionStorage.lastActivity)
        .add(20, 'minutes')
        .isAfter()
    ) {
      instanceKey = this.createInstanceKey()
    }
    if (Reflux.GlobalState.MenuStore && Reflux.GlobalState.MenuStore.profile) {
      data = Object.assign(data, {
        UserID: Reflux.GlobalState.MenuStore.profile.UserID,
        BrandID: Reflux.GlobalState.MenuStore.profile.BrandID
      })
    }
    sessionStorage.lastActivity = moment().format()
    data = Object.assign(data, { instanceKey: instanceKey })
    activity(activityType, activityName, activityArgumentID, activityArgument, data)
  }
}

ActivityStore.id = 'ActivityStore'

export { ActivityActions, ActivityStore }
