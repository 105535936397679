import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'
import { GoudaClaimPointsActions } from 'Stores/goudaClaimPointsStore'

export default function GoudaClaimPointsSuccess() {
  return (
    <>
      <h4 className='mb-3 text-center'>Tak for din indberetning</h4>
      <FontAwesomeIcon icon='check-circle' color='green' size='5x' style={{ display: 'block', margin: 'auto' }} />
      <Button
        className='standardBtn-black mt-4'
        style={{
          maxWidth: 250,
          display: 'block',
          margin: 'auto',
          fontWeight: 'bold'
        }}
        onClick={GoudaClaimPointsActions.resetForm}
      >
        Indberet ny
      </Button>
    </>
  )
}
