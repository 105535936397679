import { createAction } from '@reduxjs/toolkit'
import Proxies from 'Components/proxies'
import keyMirror from 'keymirror'

export const SHOP_STATUS = keyMirror({
  LOADING_DATA: null,
  LOADING_DATA_SUCCESS: null,
  LOADING_DATA_FAILURE: null
})

// Simple actins
export const fetchShopsRequest = createAction('fetchShopsRequest')
export const fetchShopsSuccess = createAction('fetchShopsSuccess')
export const fetchShopsFailure = createAction('fetchShopsFailure')

// Thunks
export const fetchShops = (silent = false) => (dispatch, getState) => {
  const { status } = getState().shops
  if (status !== SHOP_STATUS.LOADING_DATA) {
    if (!silent) {
      dispatch(fetchShopsRequest())
    }
    Proxies.shopPool().then(responseJSON => {
      if (responseJSON.error) {
        dispatch(fetchShopsFailure())
      } else if (responseJSON.length) {
        dispatch(
          fetchShopsSuccess(
            responseJSON.sort((a, b) => {
              return a['poolOrder'] - b['poolOrder']
            })
          )
        )
      } else {
        dispatch(fetchShopsSuccess(responseJSON))
      }
    })
  }
}
