import React from 'react'
import PropTypes from 'prop-types'
import { TrackableClick } from 'Components/trackableComponents'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import cx from 'classnames'
import _ from 'lodash'

export default function SectionMenu({ links }) {
  const { pathname } = useLocation()

  if (links) {
    return (
      <div className='sectionMenu py-4 px-2 text-center'>
        {links.map((link, i) => {
          const onClick = link.onClick || _.noop
          return (
            <TrackableClick key={i} activityName={link.sectionName} activityArgumentID={link.moduleId} activityArgument={link.name}>
              <Link
                to={link.url}
                onClick={onClick}
                active={link.url === pathname ? ' active' : undefined}
                className={cx('custom-css-target-section-menu-link', {
                  active: link.url === pathname
                })}
              >
                {link.name}
              </Link>
            </TrackableClick>
          )
        })}
      </div>
    )
  } else {
    return null
  }
}

SectionMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      moduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onClick: PropTypes.func,
      sectionName: PropTypes.string.isRequired
    })
  )
}
