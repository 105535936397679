export function track(eventCategory, eventAction, eventLabel, eventValue, fieldsObject) {
  if (window.ga) {
    window.ga('gtm1.send', {
      hitType: 'event',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      fieldsObject
    })
  }
}

export function trackOutbound(eventCategory, eventAction, eventLabel, eventValue, fieldsObject) {
  track(eventCategory, eventAction, eventLabel, eventValue, {
    transport: 'beacon',
    ...fieldsObject
  })
}
