/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { Link } from 'react-router-dom'
import Color from 'color'
import { Col, Collapse, Modal, ModalBody, ModalHeader, Navbar, NavbarBrand, Nav, NavbarToggler, NavItem, NavLink, Row, Alert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TrackableClick } from 'Components/trackableComponents'
import { LoginStore } from 'Stores/loginStore'
import { MenuActions, MenuStore } from 'Stores/menuStore'

const messageType = {
  info: 'primary',
  warning: 'danger',
  system: 'warning'
}

export default class Menu extends Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore, MenuStore]
    this.storeKeys = ['settings', 'menu', 'tags', 'activeMenu', 'announcements']
    this.state = {
      getHelpModal: false,
      collapseMenu: false
    }
    this.getHelp = this.getHelp.bind(this)
    this.collapseMenu = this.collapseMenu.bind(this)
  }
  componentDidMount() {
    MenuActions.getMenu()
    MenuActions.getAnnouncements()
    if (this.state.settings && this.state.settings.features && this.state.settings.features.enableCategoryTag) {
      MenuActions.getTagCategories()
    }
    MenuActions.checkTeamMenuAccess(
      this.state.settings && this.state.settings.features ? this.state.settings.features.groupsHavingTeamMenuAccess : []
    )
  }
  getHelp() {
    this.setState({
      getHelpModal: !this.state.getHelpModal
    })
  }
  collapseMenu() {
    this.setState({
      collapseMenu: !this.state.collapseMenu
    })
  }
  render() {
    let curPath = this.props.location.pathname.split('/')
    if (curPath.length > 0) {
      curPath = curPath[1].toLowerCase()
    }
    if (Array.isArray(curPath)) {
      curPath = ''
    }

    let headerClasses = 'msb-nav'
    let navbarStyle = { backgroundColor: 'rgba(128,128,128,0.9)' }
    if (this.state.settings.brandColor) {
      navbarStyle.backgroundColor = '#' + this.state.settings.brandColor
      if (Color('#' + this.state.settings.brandColor).isLight()) {
        headerClasses += ' light'
      } else {
        headerClasses += ' dark'
      }
    }

    let brandName = ''
    if (this.state.settings) {
      if (this.state.settings.brandName) {
        brandName = this.state.settings.brandName
      }
      if (this.state.settings.brandLogo) {
        brandName = <img className='brandLogo' src={this.state.settings.brandLogo} alt={brandName} />
      }
    }

    let getHelpModal = null
    if (this.state.getHelpModal && this.state.settings.christmasShop) {
      getHelpModal = (
        <Modal isOpen={true} toggle={this.getHelp}>
          <ModalHeader className='text-center pt-5 pb-4 pr-5 pl-5' toggle={this.getHelp}>
            Har du brug for hjælp? Sådan bestiller du din julegave!
          </ModalHeader>
          <ModalBody>
            <Row className='pl-5 pr-5'>
              <Col md='4' xs='12'>
                <center>
                  <img style={{ width: '150px' }} src='https://i.imgur.com/itiED59.png' />
                  <h2 className='mb-2'>1.</h2>
                  <p style={{ lineHeight: '1.7em', fontSize: '14px' }}>
                    På forsiden har du en oversigt over alle de gaver du kan vælge imellem. For at læse om de forskellige gaver, skal du klikke på en
                    af dem.
                  </p>
                </center>
              </Col>
              <Col md='4' xs='12'>
                <center>
                  <img style={{ width: '150px' }} src='https://i.imgur.com/3LVeCQs.png' />
                  <h2 className='mb-2'>2.</h2>
                  <p style={{ lineHeight: '1.7em', fontSize: '14px' }}>
                    Når du har fundet den gave du vil have, skal du blot trykke på knappen &quot;Vælg denne julegave&quot; på produktsiden. Du kan
                    ændre dit valg så længe shoppen er åben.
                  </p>
                </center>
              </Col>
              <Col md='4' xs='12'>
                <center>
                  <img style={{ width: '150px' }} src='https://i.imgur.com/AbhmWSs.png' />
                  <h2 className='mb-2'>3.</h2>
                  <p style={{ lineHeight: '1.7em', fontSize: '14px' }}>
                    Den gave du har valgt på den sidste bestillingsdag registrere vi i vores system. Vi får pakket og sendt afsted til din
                    arbejdsplads på den aftalte dato.
                  </p>
                </center>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )
    }
    let renderMenu = null
    if (this.state.menu) {
      renderMenu = this.state.menu.map((e, i) => {
        const decideLink = e => {
          let url = `/${e.poolName.toLowerCase()}`
          if (!e.pool || e.pool.length === 0) return url

          const key = Object.keys(e.pool[0]).find(x => x.toLowerCase() === `${e.poolName}ID`.toLowerCase())
          let menuUrl =
            e.poolName.toLowerCase() === 'shop' &&
            this.state.settings &&
            this.state.settings.features &&
            this.state.settings.features.enableCategoriesInShop
              ? `${url}/${e.pool[0][key]}/categories`
              : `${url}/${e.pool[0][key]}`
          return menuUrl
        }

        return (
          <NavItem active={e.poolName.toLowerCase() == curPath} key={i}>
            <TrackableClick activityName='menu' activityArgumentID={e.MenuID} activityArgument={e.poolName}>
              <NavLink tag={Link} onClick={() => MenuActions.resetFilter(this.state.settings, e.poolName.toLowerCase())} to={decideLink(e)}>
                <span
                  style={
                    this.state.settings &&
                    this.state.settings.features &&
                    this.state.settings.features.enableCategoryTag &&
                    (this.state.activeMenu === e.poolName.toLowerCase() || (!this.state.activeMenu && e.poolName.toLowerCase() == curPath))
                      ? { fontWeight: 'bolder' }
                      : {}
                  }
                >
                  {e.menuTitle}
                </span>
              </NavLink>
            </TrackableClick>
          </NavItem>
        )
      })
    }
    let renderTags = null
    if (
      this.state.settings &&
      this.state.settings.features &&
      this.state.settings.features.enableCategoryTag &&
      this.state.tags &&
      !!this.state.tags.length
    ) {
      renderTags = this.state.tags.map((e, i) => {
        return (
          <NavItem active={e.categoryName.toLowerCase() == curPath} key={i}>
            <TrackableClick activityName='tag' activityArgumentID={e.CategoryID} activityArgument={e.categoryName}>
              <NavLink tag={Link} onClick={() => MenuActions.applyFilters(this.state.settings, e.categoryName.toLowerCase())} to='#'>
                <span
                  style={
                    this.state.settings &&
                    this.state.settings.features &&
                    this.state.settings.features.enableCategoryTag &&
                    this.state.activeMenu === e.categoryName.toLowerCase()
                      ? { fontWeight: 'bolder' }
                      : {}
                  }
                >
                  {e.categoryName}
                </span>
              </NavLink>
            </TrackableClick>
          </NavItem>
        )
      })
    }
    return (
      <>
        <Row md={12}>
          {this.state.announcements &&
            !!this.state.announcements.length &&
            this.state.announcements.map((announcement, index) => {
              return (
                <Alert
                  key={announcement.AnnouncementID}
                  isOpen={announcement.isOpen}
                  color={messageType[announcement.messageType.toLowerCase()]}
                  {...(announcement.messageType.toLowerCase() !== 'system' && {
                    toggle: () => {
                      MenuActions.closeAnnouncements(index)
                    }
                  })}
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    color: 'black',
                    backgroundColor: announcement.messageType.toLowerCase() == 'warning' ? 'red' : 'none',
                    ...(announcement.messageType.toLowerCase() === 'system' && { paddingRight: '4rem' })
                  }}
                >
                  {' '}
                  {announcement.message}
                </Alert>
              )
            })}
        </Row>
        <Navbar style={navbarStyle} className={headerClasses} expand='md' scrolling='true'>
          <div className='container-fluid container-padding'>
            <NavbarBrand tag={Link} to='/'>
              {brandName}
            </NavbarBrand>
            <NavbarToggler className='navbar-toggler-right' onClick={this.collapseMenu}>
              <FontAwesomeIcon fixedWidth icon={'bars'} />
            </NavbarToggler>
            <Collapse isOpen={this.state.collapseMenu} navbar>
              <Nav className='ml-auto' onClick={this.collapseMenu}>
                {renderTags}
                {renderMenu}
                {this.state.settings && this.state.settings.christmasShop && (
                  <>
                    {!this.state.settings.disableChristmasShopHelp && (
                      <NavItem>
                        <NavLink onClick={this.getHelp} id='helpText'>
                          Har du brug for hjælp?
                        </NavLink>
                        {getHelpModal}
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink tag='span'>
                        <Link to='/logout'>Log ud</Link>
                      </NavLink>
                    </NavItem>
                  </>
                )}
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </>
    )
  }
}
