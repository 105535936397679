import Reflux from 'reflux'
import Proxies from 'Components/proxies'
const defaultState = {
  loading: false,
  success: null,
  error: null
}

const GoudaClaimPointsActions = Reflux.createActions([{ submitForm: { children: ['completed', 'failed'] } }, 'resetForm'])

class GoudaClaimPointsStore extends Reflux.Store {
  constructor() {
    super()
    this.listenables = GoudaClaimPointsActions
    this.state = { ...defaultState }
  }

  resetForm() {
    this.setState({ ...defaultState })
  }

  submitForm(data) {
    this.setState({ loading: true })
    Proxies.goudaClaimPoints(data).then(responseJSON => {
      if (responseJSON.error) {
        GoudaClaimPointsActions.submitForm.failed()
      } else {
        GoudaClaimPointsActions.submitForm.completed()
      }
    })
  }

  onSubmitFormCompleted() {
    this.setState({ loading: false, success: true })
  }

  onSubmitFormFailed() {
    this.setState({ loading: false, error: true })
  }
}

export { GoudaClaimPointsActions, GoudaClaimPointsStore }
