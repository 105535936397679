/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Bar } from 'react-chartjs-2'
import { Container, Row, Col } from 'reactstrap'
import isIE from 'Components/isIE'
import { TrackableClick } from 'Components/trackableComponents'
import { ProfileActions, ProfileStore } from 'Stores/profileStore'
import { LoginStore } from 'Stores/loginStore'
import PointsExpirationSpinner from './PointsExpirationSpinner'

export default class Dashboard2 extends Component {
  constructor(props) {
    super(props)
    this.stores = [ProfileStore, LoginStore]
    this.storeKeys = ['timeline', 'walletTimelineGraphData', 'pointsSummary', 'loans', 'settings', 'userPointsComputed']
  }
  componentDidMount() {
    ProfileActions.getTimeline()
    ProfileActions.getPoints()
    ProfileActions.getWalletTransactions()
    ProfileActions.getLoans()
  }

  render() {
    if (isIE()) {
      return <Redirect to={'/profile/settings'} />
    }
    const { timeline, walletTimelineGraphData } = this.state
    const { running_out, running_out_3m, running_out_6m, running_out_beyond_6m } = this.state.pointsSummary
    const timelineItems = (timeline || []).map((x, i) => <TimelineItem key={i} {...x} />)
    let userPoints = 0
    if (this.state.userPointsComputed) {
      userPoints = this.state.userPointsComputed
    }
    return (
      <React.Fragment>
        <Container>
          <div className='mt-4'>
            <Col md='12'>
              <Row>
                <Col md='4'>
                  <Row>
                    <Col className='mb-4'>
                      <center>
                        <h6 className='pb-2'>Din nuværende saldo:</h6>
                        <h6 className='font-weight-bold'>{userPoints} point</h6>
                      </center>
                    </Col>
                    <Col>
                      <PointGraph days={7} graphData={walletTimelineGraphData} />
                      {this.state.settings && this.state.settings.features && this.state.settings.features.PointExpiration && (
                        <React.Fragment>
                          <h6>PointStatus</h6>
                          <Col>
                            <div className='mt-4'>
                              <div className='row'>
                                <div className='col-12'>
                                  <PointsExpirationSpinner
                                    color='#4f9e5e'
                                    description='Udløber om mere end 6 måneder'
                                    points={running_out_beyond_6m}
                                  />
                                </div>
                                <div className='col-12'>
                                  <PointsExpirationSpinner color='#efe07b' description='Udløber om 6 måneder' points={running_out_6m} />
                                </div>
                                <div className='col-12'>
                                  <PointsExpirationSpinner color='#d13d3d' description='Udløber om 3 måneder' points={running_out_3m} />
                                </div>
                                <div className='col-12'>
                                  <PointsExpirationSpinner color='#070707' description='Udløber i denne måned' points={running_out} />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>{timelineItems}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

const TimelineItem = props => {
  const { elementTitle, note, timeUpdate } = props

  return (
    <div className='container-fluid ml-4'>
      <div className='timeline-line mr-3' />
      <div className='ml-3 mb-5 pb-2'>
        <p className='dateStamp'>
          <FontAwesomeIcon icon='calendar-alt' /> {moment(timeUpdate).format('DD/MM/YYYY HH:mm:ss')}
        </p>
        <div>
          <div className='gray-bg'>
            <div className='pt-4 pb-4 pl-4 pr-4'>
              <Row>
                <Col md='6'>
                  <h6>{elementTitle}</h6>
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <p style={{ marginBottom: '0px' }}>{note}</p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PointGraph = props => {
  let { graphData, days } = props

  if (!graphData || graphData.length == 0) return null

  let balanceArr = graphData.slice(0, days)
  const data = {
    labels: balanceArr.map(x => x.timeUpdate).reverse(),
    datasets: [
      {
        fill: false,
        data: balanceArr.map(x => x.balance).reverse()
      }
    ]
  }

  const options = {
    animation: {
      duration: 0
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            suggestedMin: 0
          }
        }
      ]
    }
  }

  return (
    <React.Fragment>
      <h6 className='text-center mt-4'>Pointoversigt</h6>
      <div className='mt-4 mb-3'>
        <Bar data={data} options={options} />
        <h6 className='float-right font-weight-bold' style={{ fontSize: '12px', paddingTop: '5px' }}>
          <TrackableClick activityName='Gå til pointoversigt'>
            <Link to='/profile/points'>
              Gå til pointoversigt <FontAwesomeIcon icon='arrow-right' />
            </Link>
          </TrackableClick>
        </h6>
      </div>
    </React.Fragment>
  )
}
