/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Picker } from 'emoji-mart'
import { emojify } from 'react-emojione'
import { Button } from 'reactstrap'

import nl2br from 'Components/nl2br'

import { CommentStore, CommentActions } from 'Stores/commentStore'

class Comments extends Component {
  constructor(props) {
    super(props)
    this.store = CommentStore
  }
  componentDidMount() {
    if (this.props.id) {
      CommentActions.getComments(this.props)
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.id != prevProps.id || this.props.relation != prevProps.relation) {
      CommentActions.getComments(this.props)
    }
  }
  renderEmojis(message) {
    if (message == '') {
      return message
    }
    let emojifyOptions = {
      convertShortnames: true,
      convertUnicode: true,
      convertAscii: true,
      style: {
        height: 20
      },
      // this click handler will be set on every emoji
      onClick: event => alert(event.target.title)
    }
    let commentMessage = nl2br(message)
    for (let part in commentMessage) {
      if (typeof commentMessage[part] == 'string') {
        //commentMessage[part] = toArray(commentMessage[part], emojiSettings)
        commentMessage[part] = emojify(commentMessage[part], emojifyOptions)
      }
    }
    return commentMessage
  }
  render() {
    //return null
    let commentTrack = (
      <div className='firstComment'>
        <p>Vær den første til at kommentere på denne konkurrence.</p>
      </div>
    )
    if (this.state.commentTrack && Array.isArray(this.state.commentTrack) && this.state.commentTrack.length > 0) {
      commentTrack = this.state.commentTrack.map((e, i) => {
        let userImage = 'https://static.upgrader.club/img/userthumb.png'
        if (e.user.attachments && Array.isArray(e.user.attachments) && e.user.attachments.length > 0) {
          let attachment = e.user.attachments[0]
          if (attachment.imageOptimizedSourceSmall) {
            userImage = attachment.imageOptimizedSourceSmall
          } else if (attachment.imageOptimizedSourceMedium) {
            userImage = attachment.imageOptimizedSourceMedium
          } else if (attachment.imageOptimizedSource) {
            userImage = attachment.imageOptimizedSource
          } else if (attachment.imageOriginSource) {
            userImage = attachment.imageOriginSource
          }
        }
        let commentMessage = this.renderEmojis(e.commentMessage)
        return (
          <div key={i} className='col-md-12 margin-off messageSent pb-2'>
            <div className='col-md-12 margin-off padding-off'>
              <table width='100%' height='45px'>
                <tbody>
                  <tr width='100%'>
                    <td width='30px' style={{ verticalAlign: 'middle', objectFit: 'cover' }}>
                      <img
                        src={userImage}
                        className='image mr-1'
                        style={{
                          objectFit: 'cover',
                          height: '30px',
                          borderRadius: '50px'
                        }}
                      ></img>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <span className='userName'>{e.user.userName}</span>
                      <br />
                      <span
                        style={{
                          fontSize: '11px',
                          fontStyle: 'italic',
                          color: '#c0c0c0'
                        }}
                        className='date text-right'
                      >
                        {moment(e.timeInsert).format('DD/MM/YYYY')}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col-md-12 margin-off padding-off'>
              <div>
                <p>{commentMessage}</p>
              </div>
            </div>
          </div>
        )
      }, this)
    }
    return (
      <div>
        <div className='col-md-12 userChat padding-off'>
          <div className='row margin-off'>
            <div className='outer'>{commentTrack}</div>
            <div className='col-md-12 margin-off chatFooter'>
              <EmojiField
                onChange={CommentActions.updateComment}
                rawValue={this.state.comment}
                value={this.renderEmojis(this.state.comment)}
                placeholder='Skriv besked her...'
              />
              <Button className='emojiBtn standardBtn-black mb-2' onClick={CommentActions.toggleSmileyPicker}>
                Tilføj emojies
              </Button>
              <Button onClick={() => CommentActions.sendComment(this.props)} className='sendBtn standardBtn-black mb-2'>
                Send
              </Button>
              <div className={this.state.showSmileyPicker ? null : 'collapse'}>
                <div className='card card-body'>
                  <Picker
                    set='emojione'
                    onSelect={CommentActions.addEmoji}
                    title='Tilføj emoji...'
                    emoji='point_up'
                    emojiSize={18}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className='chatNote'>
          <i className='ion-ios-information'></i> Hverken Santander eller MSB står til ansvar for indlæg skrevet i dette forum. Vi opfordrer alle
          brugere til at holde en god tone overfor hinanden.
        </p>
      </div>
    )
  }
}

class EmojiField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldInFocus: false
    }
    this.emojiField = null
    this.toggleFocus = this.toggleFocus.bind(this)
  }
  toggleFocus(focusValue) {
    this.setState({ fieldInFocus: focusValue })
  }
  render() {
    let areaStyle = {
      height: '1px',
      width: '1px',
      opacity: '0'
    }
    let divStyle = {
      minWidth: '100%',
      padding: '10px',
      border: '1px solid rgba(0,0,0,0.05)',
      resize: 'none',
      borderRadius: '3px',
      color: 'black',
      backgroundColor: 'white',
      minHeight: '75px'
    }
    let blinker = {
      animation: 'blink 1s linear infinite',
      color: 'black'
    }
    let { onChange, placeholder, value, rawValue, ...props } = this.props
    if (!value) {
      divStyle.color = 'silver'
    }
    return (
      <div className='pt-25'>
        <div
          style={divStyle}
          onClick={() => {
            this.emojiField.focus()
            this.toggleFocus(true)
          }}
        >
          {value ? value : placeholder}
          {this.state.fieldInFocus ? <span style={blinker}>|</span> : ''}
        </div>
        <textarea
          style={areaStyle}
          onFocus={() => {
            this.toggleFocus(true)
          }}
          onBlur={() => {
            this.toggleFocus(false)
          }}
          id='EmojiField'
          name='EmojiField'
          {...props}
          ref={ref => {
            this.emojiField = ref
          }}
          onChange={onChange}
          value={rawValue}
        />
      </div>
    )
  }
}

Comments.propTypes = {
  relation: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
}

export default Comments
