import './styles/styles.sass'
import React from 'react'
import { Component } from 'reflux'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
require('./config')
import Blog from 'Modules/blog'
import ShopLoader from 'Modules/shop/ShopLoader'
import HOF from 'Modules/halloffame/halloffame'
import { Competition } from 'Modules/halloffame/competition'
import Profile from 'Modules/profile/profile'
import Orders from 'Modules/profile/orders'
import Points from 'Modules/profile/points'
import Campaigns from 'Modules/profile/campaigns'
import Inbox from 'Modules/profile/inbox'
import Dashboard from 'Modules/profile/dashboard'
import Layout from 'Modules/layout'
import Login from 'Modules/login'
import FrontPageLoader from 'Modules/frontPageLoader'
import FourOhFour from 'Modules/error-pages/404'
import SSO from 'Modules/_sso'
import Register from 'Modules/register/Register'
import { LoginStore, LoginActions } from 'Stores/loginStore'
import ErrorBoundary from 'Components/ErrorBoundary'
import Logout from 'Modules/Logout'
import { Provider } from 'react-redux'
import store from 'Reducers/store'
import { hot } from 'react-hot-loader'
import ClaimPoints from 'Modules/claim-points/ClaimPoints'
import Team from './modules/team/Team'

class Site extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.stores = [LoginStore]
    this.storeKeys = ['sessionVerified', 'sessionKey', 'user', 'settings']

    LoginActions.setupWeb.completed.listen(data => {
      data && this.updateStyleSheet(data.customCSS)
    })
  }

  updateStyleSheet(data) {
    if (!data) {
      if (document.getElementById('UpgraderDynamicCSS')) {
        let elm = document.getElementById('UpgraderDynamicCSS')
        elm.parentNode.removeChild(elm)
      }
      return false
    }
    if (!document.getElementById('UpgraderDynamicCSS')) {
      let $style = document.createElement('style')
      $style.id = 'UpgraderDynamicCSS'
      $style.type = 'text/css'
      document.head.appendChild($style)
    }
    document.getElementById('UpgraderDynamicCSS').innerHTML = data
  }

  render() {
    return (
      <BrowserRouter>
        {this.state.sessionVerified && (
          <Route
            component={props => (
              <Layout location={props.location} history={props.history}>
                <Switch>
                  {routes.map((route, i) => (
                    <RouteWithSubRoutes key={i} {...route} settings={this.state.settings} />
                  ))}
                  <Route component={FourOhFour} />
                </Switch>
              </Layout>
            )}
          />
        )}
        {!this.state.sessionVerified && (
          <Switch>
            <Route path='/sso' component={props => <SSO {...props} />} />
            {this.state.settings.features && this.state.settings.features.registerUser && (
              <Route path='/register' component={props => <Register {...props} />} />
            )}
            {/* /
              logout redirect route is needed due to current structure with 2 completely different component trees. 
              we should eventually consolidate we we have a better structure with top level routes 
              e.g. '/blog' '/shop' hosting the sub routes compared to now where all routes are a top level route.

              And layout should not be unique to authorized users but rather be in charge of which layout is presented.

              then we could get into something like:
                <Layout>
                  {!!isAuthenticated && ....Private routes...}
                  ....Public routes....
                </Layout>

              Then private routes are only available to authenticated users and <Layout> can change to layout based on authentication
              React should be able to tract children even if put into a new html structure (To be confirmed.)
            */}
            <Route path={['/logout', '/exit']} component={Logout} />
            <Route component={props => <Login {...props} />} />
          </Switch>
        )}
      </BrowserRouter>
    )
  }
}

const RouteWithSubRoutes = props => {
  if (!props.allowRouting || props.allowRouting(props.settings)) {
    return (
      <Route
        path={props.path}
        render={routeProps => (
          <ErrorBoundary>
            <props.component {...routeProps} routes={props.routes} />
          </ErrorBoundary>
        )}
      />
    )
  } else {
    return <Route path={props.path} component={FourOhFour} />
  }
}

const routes = [
  {
    path: '/',
    exact: true,
    component: FrontPageLoader
  },
  {
    path: '/blog',
    exact: true,
    component: Blog
  },
  {
    path: '/blog/:BlogID',
    component: Blog
  },
  {
    path: '/shop/:ShopID?',
    component: ShopLoader
  },
  {
    path: '/hof',
    component: HOF
  },
  {
    path: '/halloffame',
    exact: true,
    component: HOF
  },
  {
    path: '/halloffame/:HofID',
    exact: true,
    component: HOF
  },
  {
    path: '/competition',
    component: Competition,
    exact: true
  },
  {
    path: '/competition/:CompetitionID',
    component: Competition,
    exact: true
  },
  {
    path: '/indberetning',
    component: ClaimPoints,
    exact: true,
    allowRouting: settings => settings.features && settings.features.claimPoints
  },
  {
    path: '/profile',
    exact: true,
    component: Dashboard
  },
  {
    path: '/team',
    exact: true,
    component: Team
  },
  {
    path: '/profile/orders',
    exact: true,
    component: Orders
  },
  {
    path: '/profile/points',
    exact: true,
    component: Points
  },
  {
    path: '/profile/inbox',
    exact: true,
    component: Inbox
  },
  {
    path: '/profile/settings',
    exact: true,
    component: Profile
  },
  {
    path: '/profile/campaigns',
    exact: true,
    component: Campaigns
  },
  {
    path: '/profile/:ProfileID',
    component: Profile
  },
  {
    path: '/login',
    exact: true,
    component: props => <Login {...props} />
  },
  {
    path: ['/logout', '/exit'],
    component: Logout
  }
]

const HotReloadProvider = hot(module)(Provider)

ReactDOM.render(
  <HotReloadProvider store={store}>
    <Site />
  </HotReloadProvider>,
  document.getElementById('upgrader-app')
)
