import Reflux from 'reflux'
import Proxies from 'Components/proxies'

const BlogActions = Reflux.createActions(['getBlogs', 'getBlogElements', 'applyFilter', 'scrollDone', 'openPost', 'reactToPost', '_resetState'])

class BlogStore extends Reflux.Store {
  constructor() {
    super()
    this.listenables = BlogActions
    this.startState = {
      blogs: null,
      noBlogs: false,
      currentBlogID: 0,
      currentBlog: [],
      currentBlogEmpty: false,
      loading: false,
      filter: '',
      scrollDone: false
    }
    this.state = this.startState
  }
  _resetState(callback) {
    this.setState(this.startState, callback)
  }
  getBlogs() {
    let nextState = { blogs: null, noBlogs: false }
    Proxies.blogPool().then(responseJSON => {
      if (responseJSON && responseJSON.length) {
        responseJSON = responseJSON.sort((a, b) => {
          return a['poolOrder'] - b['poolOrder']
        })
        nextState.blogs = responseJSON
      } else {
        nextState.noBlogs = true
      }
      this.setState(nextState)
    })
  }
  getBlogElements(BlogID) {
    this.setState({ loading: true })
    let nextState = {
      currentBlogID: BlogID,
      currentBlog: [],
      currentBlogEmpty: false,
      scrollDone: false,
      filter: '',
      loading: false
    }
    Proxies.blogElements(BlogID).then(responseJSON => {
      if (responseJSON) {
        //responsePackage = responsePackage.filter((line) => {return line.attachments})
        nextState.currentBlog = responseJSON
      } else {
        nextState.currentBlogEmpty = true
      }
      this.setState(nextState)
    })
  }
  scrollDone() {
    this.setState({ scrollDone: true })
  }
  applyFilter(filterText) {
    this.setState({ filter: filterText })
  }
  openPost(BlogID, PostID) {
    Proxies.blogPost(BlogID, PostID)
  }

  reactToPost(PostID, reactionId = null) {
    let data = {
      UserID: Reflux.GlobalState.LoginStore.settings.UserID,
      BrandID: Reflux.GlobalState.LoginStore.BrandID,
      description: 'web2'
    }

    const like = () => {
      Proxies.reaction('post', PostID, 'like', data).then(() => {
        this.getBlogElements(this.state.currentBlogID)
      })
    }

    const unlike = () => {
      Proxies.reactionRemove('post', PostID, 'unlike', reactionId, data).then(() => {
        this.getBlogElements(this.state.currentBlogID)
      })
    }

    return reactionId ? unlike() : like()
  }
}

export { BlogActions, BlogStore }
