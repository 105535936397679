/* eslint-disable eqeqeq */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import Reflux from 'reflux'
import moment from 'moment'
import Proxies from 'Components/proxies'
import strip_tags from 'Components/striptags'

const ProfileActions = Reflux.createActions([
  'getProfile',
  'getOrders',
  'deleteOrder',
  'getPoints',
  'getWalletTransactions',
  'updateProfileField',
  'saveProfile',
  'sendTaxEmail',
  { getNotifications: { children: ['completed'] } },
  'getTimeline',
  'getLoans',
  'repayLoan',
  { sendPoints: { children: ['completed', 'rejected', 'failed'] } },
  'updateProfileImage',
  'getCampaigns',
  'getCampaignDetail',
  'getUserSeasonOrders',
  'toggleSendPointsModal',
  { transferPoints: { children: ['completed', 'rejected', 'failed'] } },
  'checkGroupAccess',
  'getAllSubordinateUsersOfBrand',
  'getBudgetPointsBalance',
  'checkProfile'
])

const pickOptimizedImageSrc = attachment => {
  if (!attachment) return 'https://i.imgur.com/1XkUZsQ.png'

  const { imageOptimizedSourceSmall, imageOptimizedSourceMedium, imageOptimizedSource, imageOriginSource } = attachment
  return imageOptimizedSourceSmall || imageOptimizedSourceMedium || imageOptimizedSource || imageOriginSource
}

class ProfileStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = [ProfileActions]
    this.state = {
      profileData: null,
      trophies: null,
      orders: null,
      messages: null,
      points: null,
      walletTransactions: [],
      pointsSummary: {},
      notifications: [],
      timeline: [],
      loans: [],
      walletTimelineGraphData: [],
      userPointsComputed: 0,
      sendingTaxEmail: false,
      sendTaxResponse: null,
      userMissingFields: [],
      Campaigns: null,
      loadedCampaigns: [],
      expanded: false,
      allCampaigns: null,
      archivedCampaigns: null,
      activeCampaigns: null,
      loading: false,
      apiResponse: false,
      isExitsUserSeasonOrder: false,
      isOpenSendPointsModal: false,
      sendPointsGroupAccess: false,
      teamMembers: [],
      budgetPointsBalance: 0,
      selectedUserForSendPoints: null
    }
  }
  getProfile() {
    Proxies.profileData().then(responseJSON => {
      if (responseJSON) {
        this.setState({ profileData: responseJSON })
        this.checkProfile()
      }
    })
  }

  toggleSendPointsModal() {
    this.setState({ isOpenSendPointsModal: !this.state.isOpenSendPointsModal })
  }

  getCampaigns(type = 'active') {
    // const allStatus = ['SAVED','PENDING','ACCEPTED','REJECTED','STARTED','CLOSED'];
    const activeStatus = ['STARTED', 'ACCEPTED']
    const archivedStatus = ['CLOSED']
    this.setState({ loading: true })
    this.setState({ apiResponse: false })
    this.setState({ expanded: false })
    this.setState({ allCampaigns: null })
    Proxies.profileCampaigns(type).then(responseJSON => {
      this.setState({ loading: false })
      this.setState({ apiResponse: true })
      let allCampaigns = responseJSON || []
      let activeCampaigns = []
      let archivedCampaigns = []
      if (allCampaigns.length > 0) {
        activeCampaigns = allCampaigns.filter(item => {
          return activeStatus.includes(item.status)
        })
        archivedCampaigns = allCampaigns.filter(item => {
          return archivedStatus.includes(item.status)
        })
        this.setState({ allCampaigns: allCampaigns }, { activeCampaigns: activeCampaigns }, { archivedCampaigns: archivedCampaigns })
      }
    })
  }

  getCampaignDetail(id = null) {
    if (id) {
      id = Number(id)
      Proxies.getUserCampaignDetails(id).then(responseJSON => {
        let loadedCampaigns = []
        if (this.state.loadedCampaigns.length > 0) {
          loadedCampaigns = this.state.loadedCampaigns
          if (responseJSON.Campaign && responseJSON.Campaign.CampaignID) {
            const existingCampaign = this.state.loadedCampaigns.filter(item => {
              return item.Campaign.CampaignID === responseJSON.Campaign.CampaignID
            })
            if (existingCampaign.length <= 0) {
              loadedCampaigns.push(responseJSON)
            }
          }
        } else {
          loadedCampaigns.push(responseJSON)
        }
        this.setState({
          loadedCampaigns: loadedCampaigns
        })
      })
    }
  }

  getUserSeasonOrders(ShopID) {
    Proxies.userSeasonOrders(Number(ShopID)).then(responseJSON => {
      if (!responseJSON.error) {
        this.setState({ isExitsUserSeasonOrder: responseJSON })
      }
    })
  }

  getOrders() {
    Proxies.profileOrders().then(responseJSON => {
      let allOrders = responseJSON || []

      const createHash = (productId, variationId) => `${productId}${+variationId}`

      const mapOrderlines = allOrderlines => orderline => {
        const product = orderline.product
        let attachments = (orderline.product && orderline.product.attachments) || []
        if (product && product.variations && product.variations.length) {
          const variation = orderline.product.variations.filter(variation => variation.VariationID === orderline.VariationID)
          if (variation && variation.length && variation[0].attachments && variation[0].attachments.length) {
            attachments = variation[0].attachments
          }
        }
        const hash = createHash(orderline.ProductID, orderline.VariationID)
        const itemCount = allOrderlines.filter(x => createHash(x.ProductID, x.VariationID) === hash).length

        if (!product) {
          return {
            orderlineId: orderline.OrderlineID,
            productId: orderline.ProductID,
            productTitle: orderline.orderlineTitle,
            itemCount: 1,
            orderAmountPoints: orderline.orderAmountPoints ? orderline.orderAmountPoints : 0,
            imageSrc: 'https://i.imgur.com/1XkUZsQ.png',
            imageName: orderline.orderlineTitle
          }
        }
        return {
          orderlineId: orderline.OrderlineID,
          productId: product.ProductID,
          productTitle: orderline.orderlineTitle,
          orderAmountPoints: orderline.orderAmountPoints,
          imageSrc: attachments.length ? attachments.map(pickOptimizedImageSrc)[0] : 'https://i.imgur.com/1XkUZsQ.png',
          imageName: attachments.length ? attachments.map(x => x.imageName)[0] : orderline.orderlineTitle,
          itemCount
        }
      }

      allOrders = allOrders.map(x => {
        const products = (x.orderlines || []).map(mapOrderlines(x.orderlines))
        return {
          OrderID: x.OrderID,
          plainOrderID: x.plainOrderID,
          orderType: x.orderType,
          timeInsert: x.timeInsert,
          orderFlowStatus: x.orderFlowStatus,
          orderAmountPoints: products
            .map(x => parseInt(x.orderAmountPoints))
            .reduce((acc, p) => {
              acc + p
            }, 0),
          products,
          shippingids: x.shippingids,
          UserID: x.UserID,
          brandSlug: x.origin ? x.origin.brandSlug : null,
          orderDropShipment: x.orderDropShipment,
          orderConsignee: x.orderConsignee,
          orderConsigneeAddress1: x.orderConsigneeAddress1,
          orderConsigneeZipcode: x.orderConsigneeZipcode,
          orderConsigneeCity: x.orderConsigneeCity,
          orderConsigneeName: x.orderConsigneeName,
          userOrderRemarks: x.userOrderRemarks
        }
      })

      this.setState({ orders: allOrders })
    })
  }

  deleteOrder(OrderID = null) {
    const confirmation = confirm('Er du sikker på, at du vil slette denne ordre?')
    if (confirmation && OrderID && Number(OrderID)) {
      OrderID = Number(OrderID)
      Proxies.deleteOrder({ OrderID }).then(responseJSON => {
        if (responseJSON && responseJSON.error && responseJSON.error.message) {
          alert(responseJSON.error.message)
        } else {
          this.getOrders()
        }
      })
    }
  }

  onGetPoints() {
    Proxies.profilePoints().then(responseJSON => {
      const {
        current_sum,
        running_out,
        running_out_beyond,
        running_out_3m,
        running_out_beyond_3m,
        running_out_6m,
        running_out_beyond_6m,
        running_out_9m,
        running_out_beyond_9m
      } = responseJSON
      const pointsSummary = Object.assign(
        {},
        {
          current_sum: parseInt(current_sum),
          running_out,
          running_out_beyond,
          running_out_3m,
          running_out_beyond_3m,
          running_out_6m,
          running_out_beyond_6m,
          running_out_9m,
          running_out_beyond_9m
        }
      )
      this.setState({ pointsSummary })
    })
  }

  // Function to get overview of points for each wallet transaction calculating sum of all the previous wallet transactions including current one
  calculateCurrentWalletSum = (x, i, list) => {
    return Object.assign({}, x, {
      currentWalletSum: list.slice(i).reduce((sum, next) => sum + next.walletAmountPoints, 0)
    })
  }

  onGetWalletTransactions() {
    Proxies.walletTransactions().then(responseJSON => {
      const mappedResponseJSON = responseJSON.map((item, ind) => this.calculateCurrentWalletSum(item, ind, responseJSON))
      const walletTransactions = mappedResponseJSON.map(x => ({
        walletAmountPoints: x.walletAmountPoints,
        walletCurrency: x.walletCurrency,
        walletNote: x.walletNote,
        timeUpdate: x.timeUpdate,
        timeInsert: x.timeInsert,
        currentWalletSum: x.currentWalletSum,
        receiverName: !!x.receiverUserID && !!x.receiver ? x.receiver.userName : '-',
        receiverUID: !!x.receiverUserID && !!x.receiver ? x.receiver.userUID : '-',
        receiverEmail: !!x.receiverUserID && !!x.receiver ? x.receiver.userEmail : '-'
      }))
      let userPointsComputed = walletTransactions.reduce((a, b) => parseInt(a) + parseInt(b['walletAmountPoints'] || 0), 0)
      const walletTimelineGraphData = this.createWalletTimelineGraphData(userPointsComputed, walletTransactions)
      this.setState({
        walletTransactions,
        walletTimelineGraphData,
        userPointsComputed
      })
    })
  }

  createWalletTimelineGraphData = (currentUserPoints, transactionsData) => {
    // Filter out the wallet transactions older than 1 year since the dates are formatted to day/month and avoid consolidating previous years points
    transactionsData = transactionsData.filter(x => moment(x.timeUpdate) > moment().subtract(11, 'months'))
    // Mocked for testing
    let transactions = (transactionsData || []).slice(0)

    // create a nicely formatted date on the format: day/month (used for display and consolidating same dates)
    const formatTimeUpdated = x =>
      Object.assign({}, x, {
        timeUpdate: `${new Date(x.timeUpdate).getDate()}/${new Date(x.timeUpdate).getMonth() + 1}`
      })

    // group together entries on the same dates, summarize the days total change and return only destinct days
    const consolidateSameDayEntries = transactions => {
      const result = {}
      transactions.forEach(x => {
        result[x.timeUpdate] = transactions
          .filter(t => x.timeUpdate === t.timeUpdate)
          .map(x => parseInt(x.walletAmountPoints))
          .reduce((sum, next) => sum + next)
      })
      return Object.keys(result).map(x => ({
        timeUpdate: x,
        walletAmountPoints: result[x]
      }))
    }

    // calculate the balance on each given day based on the ending wallet balance (the points available right now)
    const calculateDayBalance = (x, i, list) => {
      return Object.assign({}, x, {
        balance:
          list
            .slice(0, i + 1)
            .map(x => x.walletAmountPoints)
            .reduce((sum, next) => {
              return sum + next * -1
            }, currentUserPoints) + x.walletAmountPoints
      })
    }

    const fillGapDays = transactions => {
      const newTransactions = transactions.slice(0)
      if (newTransactions.length) {
        const lastEntryDate = moment(newTransactions[0].timeUpdate)
        const diff = moment().diff(lastEntryDate, 'days') + 1
        for (let i = 0; i < diff; i++) {
          newTransactions.unshift({
            timeUpdate: moment(newTransactions[0].timeUpdate)
              .add(1, 'days')
              .format('YYYY-MM-DD HH:MM:SS'), //"2019-03-11 14:57:09"
            walletAmountPoints: '0'
          })
        }
      }
      return newTransactions
    }

    transactions = fillGapDays(transactions).map(formatTimeUpdated)
    transactions = consolidateSameDayEntries(transactions).map(calculateDayBalance)
    return transactions
  }

  onSendTaxEmail(userEmail, year) {
    year = parseInt(year)
    this.setState({ sendingTaxEmail: true })
    Proxies.sendTaxEmail({ userEmail, year }).then(responseJSON => {
      this.setState({ sendTaxResponse: responseJSON, sendingTaxEmail: false })
    })
  }

  onGetNotifications() {
    Proxies.notifications().then(responseJSON => {
      ProfileActions.getNotifications.completed()
      this.setState({ notifications: responseJSON })
    })
  }

  onGetTimeline() {
    Proxies.timeline().then(responseJSON => {
      this.setState({ timeline: responseJSON })
    })
  }

  onGetLoans() {
    const loans = []
    this.setState({ loans })
  }

  onSendPoints(userEmail, amount, isLoan = false) {
    Proxies.sendPoints({ userEmail, amount, isLoan })
      .then(responseJSON => {
        if (responseJSON.exception !== 'SUCCESS') return ProfileActions.sendPoints.rejected(responseJSON)

        ProfileActions.sendPoints.completed()
      })
      .catch(ProfileActions.sendPoints.failed)
  }

  onRepayLoan(loanId) {
    Proxies.repayLoan({ loanId })
    const loans = this.state.loans.filter(x => x.loanId !== loanId)
    this.setState({ loans })
  }

  updateProfileField(e) {
    let fieldName = e.target.name
    let fieldData = strip_tags(e.target.value)
    if (this.state.profileData.settings.indexOf(fieldName) != -1) {
      let profileData = this.state.profileData
      profileData.user[fieldName] = fieldData
      this.setState({ profileData })
    }
  }

  saveProfile() {
    Proxies.profileUpdate(this.state.profileData.user).then(responseJSON => {
      if (responseJSON) {
        let profileData = this.state.profileData
        profileData.user = responseJSON
        this.setState({ profileData })
      }
    })
  }
  updateProfileImage(file) {
    if (Array.isArray(file)) {
      file = file[0]
    }
    if (!file) {
      return false
    }
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      let _file = {
        File: file,
        base64String: reader.result,
        // biteArray: biteArray,
        contentType: file.type,
        fileExtension: file.type.split('/')[1],
        originalName: file.originalName,
        info: {
          width: null,
          height: null,
          size: file.size,
          format: file.type.split('/')[1]
        }
      }
      Proxies.uploadImage({ $images: [_file] }).then(responseJSON => {
        //return false
        let profileData = this.state.profileData
        profileData.user.attachments = [responseJSON]
        this.setState({ profileData })
        ProfileActions.saveProfile()
      })
    }
    reader.onerror = function() {}
    return false
  }
  checkProfile(validatePhoneNumberExistance = false) {
    let userMissingFields = []
    const phoneNumber = this.state.profileData.user.userPhone || null
    !!validatePhoneNumberExistance && !phoneNumber && userMissingFields.push('Mobil')
    const address = this.state.profileData.user.addresses[0]
    !address.Dealer_Navn && userMissingFields.push('Virksomhed')
    !address.Dealer_adresse && userMissingFields.push('Adresse')
    !address.Dealer_zip && userMissingFields.push('Postnummer')
    !address.Dealer_by && userMissingFields.push('By')
    userMissingFields = userMissingFields.length ? userMissingFields.join(',') : []
    this.setState({ userMissingFields })
  }

  transferPoints(userEmail, amount, walletNote) {
    Proxies.transferBudgetPoints({ userEmail, amount, walletNote })
      .then(responseJSON => {
        if (responseJSON.exception !== 'SUCCESS') return ProfileActions.transferPoints.rejected(responseJSON)

        ProfileActions.transferPoints.completed()
      })
      .catch(ProfileActions.transferPoints.failed)
  }

  checkGroupAccess(groupName) {
    if (!groupName) {
      this.setState({ sendPointsGroupAccess: true })
    } else {
      let groupNames = groupName
        .replaceAll(', ', ',')
        .replaceAll(' ,', ',')
        .split(',')
      Proxies.checkGroupAccess({ groupNames })
        .then(access => {
          if (access) {
            this.setState({ sendPointsGroupAccess: true })
          } else {
            this.setState({ sendPointsGroupAccess: false })
          }
        })
        .catch()
    }
  }

  getAllSubordinateUsersOfBrand() {
    Proxies.getAllSubordinateUsersOfBrand()
      .then(response => {
        if (response && response.length > 0) {
          response = response.map(item => {
            item.label = item.userName
            item.value = item.userEmail
            return item
          })
          this.setState({ teamMembers: response })
        } else {
          this.setState({ teamMembers: [] })
        }
      })
      .catch(err => console.error(err))
  }

  getBudgetPointsBalance() {
    Proxies.getBudgetPointsBalance()
      .then(response => {
        this.setState({ budgetPointsBalance: response.budgetPointsBalance })
      })
      .catch(err => console.error(err))
  }
}

export { ProfileActions, ProfileStore }
