import React from 'react'
import Reflux from 'reflux'
import { Container, Col, Row } from 'reactstrap'
import { LoginStore } from 'Stores/loginStore'
import { FeatureStore } from 'Stores/featureStore'

export default class BlogFeature extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [FeatureStore, LoginStore]
    this.storeKeys = ['blog', 'settings']
  }
  render() {
    let { blogFeatureTitle, blogFeatureText } = this.state.settings
    if (!(blogFeatureText && blogFeatureTitle) && Object.keys(this.state.blog).length > 0) {
      let { elementTitle, elementContent } = this.state.blog
      blogFeatureText = elementContent
      blogFeatureTitle = elementTitle
    }
    return (
      <div className='santander-feature'>
        <div className='feature-overlay' />
        <div className='pt-5 pb-5'>
          <Container>
            <Col md='12'>
              <Row>
                <Col md='12'>
                  <h1 className='featureTitle'>{blogFeatureTitle}</h1>
                  <p>{blogFeatureText}</p>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </div>
    )
  }
}
