import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getProductCategories, setCategory } from 'Reducers/productsSlice.js'
import { Row, Col } from 'reactstrap'
import { TrackableFunction } from 'Components/trackableComponents'
import { useHistory } from 'react-router-dom'

export default function CategoryList() {
  const categories = useSelector(getProductCategories)
  const dispatch = useDispatch()
  const history = useHistory()

  const setSelectedCategory = (category = '') => {
    const search = category !== '' ? '/' + encodeURI(category) : ''
    dispatch(setCategory(category))
    history.push(`${history.location.pathname}${search}`)
    TrackableFunction({
      activityType: 'filter',
      activityName: 'shop',
      activityArgument: category
    })
  }
  return (
    <>
      <Row>
        {categories.map((category, index) => {
          return (
            <Col md={4} className='mb-28' key={index}>
              <div className='category'>
                <img
                  onClick={e => {
                    e.preventDefault()
                    setSelectedCategory(category.categoryName)
                  }}
                  style={{ opacity: '0.3', width: '100%', height: '300px', objectFit: 'cover', position: 'relative' }}
                  src={category.imageURL}
                  className='product-image'
                />
                <h6
                  style={{
                    transform: 'translate(-50%, -50%)',
                    color: 'black',
                    top: '50%',
                    position: 'absolute',
                    textAlign: 'center',
                    left: '50%',
                    fontSize: '22px'
                  }}
                >
                  {category.categoryName}
                </h6>
              </div>
            </Col>
          )
        })}
      </Row>
    </>
  )
}
