/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { Container, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ShopStore, ShopActions } from 'Stores/shopStore'
import { LoginStore } from 'Stores/loginStore'
import nl2br from '../../components/nl2br'
import Moment from 'react-moment'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  @media print {
    display: none;
  }
`

export default class ChristmasHeader extends Component {
  constructor(props) {
    super(props)
    this.stores = [ShopStore, LoginStore]
    this.storeKeys = ['christmas', 'noChristmas', 'settings']
  }
  componentDidMount() {
    ShopActions.getChristmasItem()
  }
  render() {
    let christmasData = <FontAwesomeIcon icon='circle-notch' spin />
    if (this.state.noChristmas) {
      christmasData = (
        <h4 className='christmasTitle christmasFont pb-2' id='noChristamsGift'>
          Du har ikke valgt en julegave
        </h4>
      )
    } else if (this.state.christmas) {
      let image = ''
      if (this.state.christmas.attachments && Array.isArray(this.state.christmas.attachments)) {
        let attachment = this.state.christmas.attachments[0]
        if (attachment.imageOptimizedSourceSmall) {
          image = attachment.imageOptimizedSourceSmall
        } else if (attachment.imageOptimizedSourceMedium) {
          image = attachment.imageOptimizedSourceMedium
        } else if (attachment.imageOptimizedSource) {
          image = attachment.imageOptimizedSource
        } else {
          image = attachment.imageOriginSource
        }
        image = image + '?' + Date.now()
      }
      let variation = ''
      let variationData = this.state.christmas.variations.find(e => {
        return e.VariationID == this.state.christmas.foundBy.ReactionTargetID
      })
      /* eslint-disable-next-line */
      if (variationData && variationData.hasOwnProperty('variationTitle') && variationData.variationTitle) {
        variation = ' (' + variationData.variationTitle + ')'
      }
      christmasData = (
        <React.Fragment>
          <h4 className='christmasTitle christmasFont pb-2' id='christmasHeaderTitle'>
            Du har valgt denne julegave
          </h4>
          <div className='selectedItem_container shadow-1'>
            <table>
              <tbody>
                <tr>
                  <td>
                    <img src={image} className='selectedItem_image' />
                  </td>
                  <td>
                    <h5>
                      {this.state.christmas.elementTitle}
                      {variation}
                    </h5>
                    <p>{this.state.christmas.elementContent.substr(0, 30) + '...'}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )
    }
    let classes = 'top-wrapper christmasbg'
    let styles = {}
    let christmasOverlay
    let deliveryAddress
    if (this.state.settings.christmasImage) {
      classes += ' backgroundImage'
      styles.backgroundImage = 'url(' + this.state.settings.christmasImage + ')'
      styles.backgroundSize = 'cover'
      christmasOverlay = <div className='catalogOverlay' />
    }
    if (this.state.settings.ClientName == 'g4s') {
      deliveryAddress = <p className='pt-1 change-date'>Din julegave leveres til adressen LEVERINGSADRESSE</p>
    }
    return (
      <StyledWrapper className={classes} style={styles}>
        {christmasOverlay}
        <div className='feature-overlay-christmas' />
        <Container className='pt-5 pb-5'>
          <Row>
            <Col md='7' sm='12' xs='12'>
              <h2 className='christmasTitle christmasFont pb-3' id='merryChristmasTitle'>
                Glædelig jul
              </h2>
              <p className='christmasText'>{nl2br(this.state.settings.christmasSelectText)}</p>
            </Col>
            <Col md='5' sm='12' xs='12'>
              {christmasData}
              <p className='pt-3 change-date' id='changeDateCss'>
                Husk at du kan ændre dit valg indtil d. <Moment format='DD/MM/YYYY'>{this.state.settings.christmasEndDate}</Moment>
              </p>
              {deliveryAddress}
            </Col>
          </Row>
        </Container>
      </StyledWrapper>
    )
  }
}
