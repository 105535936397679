import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'personalShopper',
  initialState: {
    isModalOpen: false
  },
  reducers: {
    togglePersonalShopperOpen: state => {
      state.isModalOpen = !state.isModalOpen
    }
  }
})

// Public simple actions
export const { togglePersonalShopperOpen } = slice.actions

// Export selectors
export const getIsOpen = state => state.personalShopper.isModalOpen

export default slice.reducer
